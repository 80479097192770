import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

function decrypt(data){
  if (data?.ak){
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  }else{
    return data
  }
}
function inParameter(data){
  if (data){
    return { sn: ed, jt: utils.secret(data, ak)}
  }else{
    return data
  }
}
//合集列表
export function compilaList(data) {
  return request({
    url: "/disk-service/file/shareGatherListV2",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//清空回收站
export function clearCompila(data) {
  return request({
    url: "/disk-service/file/clearGatherRecycle",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 收藏文件列表
export function fileCollList(data) {
  return request({
    url: "/disk-service/file/fileCollList",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
// 收藏合集列表
export function gathersCollList(data) {
  return request({
    url: "/disk-service/file/gathersCollList",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

// 更新最后查看时间
export function setCollectInfo(data) {
  return request({
    url: "/disk-service/file/collectIsRead",
    method: "post",
    data:inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
