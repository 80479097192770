<template>
  <div class="chatApp">
    <!-- 左边菜单 -->
    <ChatLeft />
    <!-- 右侧 -->
    <ChatRight />
  </div>
</template>

<script>
import ChatLeft from '@/components/ChatLeft'
import ChatRight from '@/components/ChatRight'

export default {
  components: {
    ChatLeft,
    ChatRight,
  },
  created() {
    document.title = 'Feemoo AI'
  },
  mounted() {
    // 监听 visibilitychange 事件
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    // 监听 beforeunload 事件
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  beforeDestroy() {
    document.title = this.$t('common.title');
    // 移除事件监听器，避免内存泄漏
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    handleVisibilityChange() {
      this.isPageVisible = !document.hidden;
    },
    handleBeforeUnload() {
      // 如果页面不可见，认为是浏览器关闭，删除缓存
      if (!this.isPageVisible) {
        // localStorage.removeItem('chat_id');
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
