<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="720px"
    :before-close="handleClose"
    custom-class="codeDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">举报</div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <!-- 姓名 -->
        <div class="reportBox">
          <div class="label"><span class="red">*</span>姓名</div>
          <el-input
            class="oneInput"
            v-model="form.name"
            placeholder="请输入真实姓名"
            clearable
          >
          </el-input>
        </div>

        <!-- 身份证号 -->
        <div class="reportBox">
          <div class="label"><span class="red">*</span>身份证号</div>
          <el-input
            class="oneInput"
            v-model="form.id_card"
            placeholder="请输入中国大陆居民身份证号码"
            clearable
            maxlength="18"
          >
          </el-input>
        </div>

        <!-- 地址 -->
        <div class="reportBox">
          <div class="label"><span class="red">*</span>地址</div>
          <el-cascader
            ref="cascader"
            class="oneInput"
            v-model="form.address"
            :options="options"
            placeholder="请选择省/市/县"
            clearable
            @change="handleChange"
          >
          </el-cascader>
          <el-input
            class="twoInput"
            v-model="form.detail_address"
            placeholder="请输入详细地址"
            clearable
            @blur="handleBlur"
            ref="inputRef"
          >
          </el-input>
        </div>

        <!-- 举报类型 -->
        <div class="reportBox">
          <div class="label"><span class="red">*</span>举报类型</div>
          <div class="typeBox">
            <div
              class="type"
              v-for="item in typeList"
              :key="item.id"
              @click="checkType(item)"
            >
              <svg-btn
                class="icon"
                :icon-class="
                  form.report_type == item.id ? 'icon-type-yes' : 'icon-type-no'
                "
              ></svg-btn>
              <span class="font">{{ item.name }}</span>
            </div>
          </div>
        </div>

        <!-- 举报原因 -->
        <div class="reportBox">
          <div class="label">
            <span class="red">*</span>举报原因（最少填写20字）
          </div>
          <el-input
            class="threeInput"
            v-model="form.report_reason"
            type="textarea"
            placeholder=""
            resize="none"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </div>
      </div>
      <div class="d-footer">
        <fm-button
          :disabled="isDisabled"
          @click="submitForm"
          type="primary"
          size="small"
          >提 交</fm-button
        >
        <fm-button @click="handleClose" type="info" plain size="small"
          >取 消</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import address from '@/utils/address'
import { subReport } from '@/utils/apiList/downpage'
export default {
  name: 'title',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    fshort: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        name: '', //姓名
        id_card: '', //身份证号
        address: [], //地址
        detail_address: '', //详细地址
        report_type: 1, //举报类型
        report_reason: '', //举报理由
      },
      typeList: [
        {
          id: 1,
          name: '侵犯版权',
        },
        {
          id: 2,
          name: '涉黄',
        },
        {
          id: 3,
          name: '暴恐音频',
        },
        {
          id: 4,
          name: '其他',
        },
      ],
      options: [],
    }
  },
  mounted() {
    this.options = this.transformData(address.children)
  },
  computed: {
    isDisabled() {
      // 检查所有字段是否都有非空且非全空格的内容
      const isFormValid = Object.entries(this.form).every(([key, value]) => {
        if (typeof value === 'string') {
          // 检查是否为空或全空格
          if (!value.trim()) return false

          // 特殊校验：report_reason 最少 20 个字符
          if (key === 'report_reason' && value.trim().length < 20) return false

          // 特殊校验：id_card 必须等于 18 个字符
          if (key === 'id_card' && value.trim().length !== 18) return false
          
        } else if (Array.isArray(value) && key === 'address') {
          // 特殊校验：address 必须是一个非空数组，且每个元素都是非空字符串
          if (value.length === 0) return false // 数组不能为空
          if (value.some(item => typeof item !== 'string' || !item.trim()))
            return false // 每个元素必须是非空字符串
        }
        return true
      })

      // 如果表单无效，则禁用按钮
      return !isFormValid
    },
  },
  methods: {
    handleChange(value) {
      if (value.length === 0) {
        this.form.address = [] // 清空选择
        this.$refs.cascader.$refs.panel.checkedValue = [] // 清空选中值
        this.$refs.cascader.$refs.panel.clearCheckedNodes() // 清空级联选择器选中状态
        this.$refs.cascader.$refs.panel.activePath = [] // 清除高亮
        this.$refs.cascader.$refs.panel.syncActivePath() // 初始化（只展示一级节点）
      }
    },
    transformData(data) {
      return data.map(item => {
        const node = {
          value: item.fullname || item.name,
          label: item.fullname || item.name,
        }
        if (item.children && item.children.length > 0) {
          node.children = this.transformData(item.children) // 递归处理子节点
        }
        return node
      })
    },
    //选择举报类型
    checkType(row) {
      this.form.report_type = row.id
    },
    //清除表单数据
    reset() {
      this.form = {
        name: '', //姓名
        id_card: '', //身份证号
        address: [], //地址
        detail_address: '', //详细地址
        report_type: 1, //举报类型
        report_reason: '', //举报理由
      }
    },
    // 失去焦点时，将内容滚动到最右边
    handleBlur() {
      this.$nextTick(() => {
        const input = this.$refs.inputRef.$refs.input
        setTimeout(() => {
          input.scrollLeft = input.scrollWidth
        }, 0) // 延迟 0ms，确保 DOM 更新完成
      })
    },
    //提交方法
    submitForm: _.debounce(
     async function () {
        if (this.isDisabled) {
          return
        }
        // 提交前去掉所有字段的前后空格
        let data = this.trimFormData(this.form)
        let formData = {
          ...data,
          fshort: this.fshort,
        }
        let res = await subReport(formData)
        if (res.status == 1) {
          this.$toast('举报内容提交成功', 1)
          this.handleClose()
        } else {
          this.$toast(res.msg, 2)
        }
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //去掉数据的前后空格
    trimFormData(form) {
      return Object.keys(form).reduce((acc, key) => {
        const value = form[key]
        // 特殊处理 address 字段
        if (key === 'address' && Array.isArray(value)) {
          acc[key] = value.join('')
        } else if (typeof value === 'string') {
          // 去掉字符串字段的前后空格
          acc[key] = value.trim()
        } else {
          // 其他字段保持不变
          acc[key] = value
        }
        return acc
      }, {})
    },
    handleClose() {
      this.reset()
      this.$emit('handleClose')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .codeDialog {
  .el-dialog__body {
    padding: 20px 32px 32px 32px;
  }
  .left {
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    @include useTheme {
      color: getVar('text-color2-2c2c2c');
    }
    line-height: 24px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
  .d-header {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .d-content {
    margin-top: 40px;
    @include useTheme {
      box-shadow: getVar('news-box-shadow3');
    }
    .reportBox {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;
      .label {
        display: flex;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        @include useTheme {
          color: getVar('text-color2-2c2c2c');
        }
        .red {
          color: #f53f3f;
        }
      }
      .oneInput {
        width: 328px;
      }
      .twoInput {
        width: 100%;
        margin-top: 12px;
      }
      .threeInput {
        width: 100%;
        height: 98px;
        .el-textarea__inner {
          height: 98px;
        }
        .el-input__count {
          font-weight: 400;
          font-size: 14px;
          color: #8a8b92;
          line-height: 22px;
        }
      }
      .typeBox {
        display: flex;
        white-space: nowrap;
        .type {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-right: 52px;
        }
        .icon {
          width: 16px;
          height: 16px;
        }
        .font {
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          @include useTheme {
            color: getVar('text-color2-2c2c2c');
          }
          margin-left: 8px;
        }
      }
    }
  }
  .d-footer {
    margin-top: 24px;
  }
}
</style>
