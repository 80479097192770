<template>
  <div
    id="homepage"
    ref="addScroll"
    @scroll="scrollBox"
    v-scroll-outer="scrollBox"
    style="position: relative;"
  >
    <div class="homepage">
      <!-- 头部 -->
      <div class="Navbar" :class="{ Navbar2: showNavbar2 }">
        <div class="top-menu">
          <svg-btn
            @click="goRouter(0)"
            class="logo pointer"
            style="padding-right: 10px"
            icon-class="icon-logo0-new"
          ></svg-btn>
          <svg-btn
            @click="goRouter(0)"
            class="logo1 pointer color1"
            :icon-class="$t('home.FEEMOO')"
          ></svg-btn>
          <span
            class="nav-name"
            :class="{ 'nav-name2': index == 1, 'nav-name3': index == 3}"
            v-for="(item, index) in navData"
            :key="item"
            @click="goRouter(index)"
            >{{ $t(item) }}</span
          >
          <div class="dropDownBox">
            <div class="titBox color1">
              <!-- <div class="tit">
                {{
                  $store.state.language == 'en'
                    ? 'English'
                    : $store.state.language == 'hk'
                    ? '繁體中文'
                    : '简体中文'
                }}
              </div>
              <svg-btn class="icon" icon-class="icon-up-close"></svg-btn> -->
              <svg-btn class="icon" icon-class="icon-top-lang"></svg-btn>
            </div>
            <div class="user2"></div>
            <div class="dropItem">
              <div class="one pointer" @click="changeLanguage(0)">
                <div class="ontit">English</div>
              </div>
              <div class="one one2 pointer" @click="changeLanguage(1)">
                <div class="ontit">简体中文</div>
              </div>
              <div class="one one2 pointer" @click="changeLanguage(2)">
                <div class="ontit">繁體中文</div>
              </div>
            </div>
          </div>
          <div class="nologin" v-if="!islogin">
            <span class="com-btn login-btn2" @click="goLogin()">{{
              $t('home.Login')
            }}</span>
            <span class="com-btn register-btn2" @click="goLogin('register')">{{
              $t('home.Signup')
            }}</span>
          </div>
          <HoverPop class="HoverPop" v-else>
            <div class="userBox">
              <img class="avater" :src="userInfo.logo" width="28" height="28" />
              <span>{{ userInfo.nickname }}</span>
            </div>
            <div slot="content" class="userOperateBox2">
              <div class="imgBox2">
                <div class="avater-box">
                  <img
                    class="avater"
                    :src="userInfo.logo"
                    alt=""
                  />
                  <div class="name">{{ userInfo.nickname }}</div>
                </div>
                <img
                  style="
                    width: auto;
                    margin-left: 8px;
                    height: 12px;
                    flex-grow: 0;
                  "
                  :src="VipInfo?.header?.new_tag_url"
                  alt=""
                />
              </div>
              <div
                class="vip-card"
                @click="tableClick('/vip')"
                :style="
                  'background-image:url(' +
                  `${VipInfo?.header?.web_info_back_url}` +
                  ')'
                "
              >
                <div class="no-vip-box" v-if="VipInfo?.is_vip == 0">
                  <div class="identity-title">
                    <img
                      class="identity"
                      :src="VipInfo?.header?.web_info_back_title_url"
                      alt=""
                    />
                    <div class="identity-time">{{ $t('userinfo.vip') }}</div>
                  </div>
                  <div
                    class="identity-text"
                    v-html="
                      $utils.getSearchKeyword(
                        VipInfo?.header?.info,
                        VipInfo?.header?.info_show_num,
                        Brand
                      )
                    "
                  ></div>
                </div>
                <div class="vip-box" v-if="VipInfo?.is_vip == 1">
                  <div class="identity-title">
                    <img
                      class="identity"
                      :src="VipInfo?.header?.web_info_back_title_url"
                      alt=""
                    />
                    <div class="identity-time">
                      {{ VipInfo?.header?.label }}
                    </div>
                  </div>
                  <div
                    class="identity-text"
                    v-html="
                      $utils.getSearchKeyword(
                        VipInfo?.header?.info,
                        VipInfo?.header?.info_show_num,
                        Brand
                      )
                    "
                  ></div>
                  <div class="power-box">
                    <div class="power-item">
                      <img class="icons" :src="VipInfo?.header?.icons[0]" />
                      <div class="box">
                        <div class="title">
                          {{ $t('userinfo.Downloadquota') }}
                        </div>
                        <div class="info">
                          {{ VipInfo?.header?.down_count_label }}
                        </div>
                      </div>
                    </div>
                    <div class="power-item">
                      <img class="icons" :src="VipInfo?.header?.icons[1]" />
                      <div class="box">
                        <div class="title">
                          {{ $t('userinfo.Downloadspeed') }}
                        </div>
                        <div class="info">
                          {{ VipInfo?.header?.down_speed_text }}
                        </div>
                      </div>
                    </div>
                    <div class="power-item">
                      <img class="icons" :src="VipInfo?.header?.icons[2]" />
                      <div class="box">
                        <div class="title">
                          {{ $t('userinfo.Cloudcapacity') }}
                        </div>
                        <div class="info">
                          {{ VipInfo?.header?.cloud_size_format }}
                        </div>
                      </div>
                    </div>
                    <div class="power-item">
                      <img class="icons" :src="VipInfo?.header?.icons[3]" />
                      <div class="box">
                        <div class="title">
                          {{ $t('userinfo.Cloudequity') }}
                        </div>
                        <div class="info">
                          {{ VipInfo?.header?.cloud_disk_benefits }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="space-box">
                <div class="title-box">
                  <el-tooltip
                    v-if="VipInfo?.cloud_size?.is_rate_flow == 1"
                    placement="bottom-start"
                  >
                    <template #content>
                      <div class="tooltip-Spacelevel">
                        <div
                          class="tooltip-item"
                          v-for="(item, index) in VipInfo?.cloud_size
                            ?.cloud_size_text"
                          :key="index"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </template>
                    <div class="Space-title">
                      {{ $t('userinfo.Cloudcapacity')
                      }}<svg-btn
                        class="Spacelevel"
                        icon-class="icon-Spacelevel"
                      ></svg-btn>
                    </div>
                  </el-tooltip>
                  <div v-else class="Space-title">
                    {{ $t('userinfo.Cloudcapacity') }}
                  </div>
                  <div class="Space-size">
                    {{ VipInfo?.cloud_size?.self_size }}G /
                    <span
                      style="margin-left: 2px"
                      :class="{
                        endless: VipInfo?.cloud_size?.cloud_size == '999999',
                      }"
                    >
                      {{
                        VipInfo?.cloud_size?.cloud_size == '999999'
                          ? '∞'
                          : VipInfo?.cloud_size?.cloud_size + 'G'
                      }}</span
                    >
                  </div>
                </div>
                <el-progress
                  :percentage="usedPre > 100 ? 100 : usedPre"
                  :stroke-width="4"
                  :text-inside="true"
                ></el-progress>
              </div>
              <div class="Memberdata">
                <div class="data-item">
                  <div class="item-title">
                    {{ VipInfo?.consume?.day_down_count }}
                  </div>
                  <div class="item-text">
                    {{ $t('userinfo.DownloadLimit') }}
                  </div>
                </div>
                <div class="data-item">
                  <div class="item-title">{{ VipInfo?.consume?.point }}</div>
                  <div class="item-text">
                    {{ $t('userinfo.awelfarepoint') }}
                  </div>
                </div>
                <div class="data-item">
                  <div class="item-title">
                    {{ VipInfo?.consume?.down_speed_text }}
                  </div>
                  <div class="item-text">
                    {{ $t('userinfo.Downloadspeed') }}
                  </div>
                </div>
              </div>
              <div class="btn-box">
                <div class="btn-text" @click="tableClick('/help')">
                  {{ $t('userinfo.HelpCentre') }}
                </div>
                <div class="ling"></div>
                <div class="btn-text" @click="tableClick('/account')">
                  {{ $t('userinfo.AccountSettings') }}
                </div>
                <div class="ling"></div>
                <div class="btn-text" @click="loginOut()">
                  {{ $t('userinfo.SignOut') }}
                </div>
              </div>
            </div>
          </HoverPop>
        </div>
      </div>
      <!-- 主要内容 -->
      <div class="newBox">
        <div class="BoxOne">
        <!-- 标题文字，已替换成图片 -->
          <!-- <div class="titleOne">Feemoo</div>
          <div
            class="titleTwo"
            :class="$store.state.language == 'en' ? 'titleThree' : ''"
          >
            {{ $t('home.TxtOne') }}
          </div> -->
          <img :class="$store.state.language == 'en'?'titleImgEn':'titleImg'" :src="titleImg" />
          <!-- 模拟背景图 -->
          <div class="rightBg" :style="{
            backgroundImage: `url(${$utils.getPng('fmNewBg')})`,
          }"></div>
          <div class="info">
            <template v-if="$store.state.language == 'en'">
              <div
                v-for="(item, index) in infoList.slice(0, 2)"
                :key="index"
                class="infoList"
              >
                <img class="icon" :src="item.img" />
                <span class="name">{{ $t(item.name) }}</span>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(item, index) in infoList"
                :key="index"
                class="infoList"
              >
                <img class="icon" :src="item.img" />
                <span class="name">{{ $t(item.name) }}</span>
              </div>
            </template>
          </div>
          <div
            class="info"
            v-if="$store.state.language == 'en'"
            style="margin-top: 20px"
          >
            <div class="infoList">
              <img class="icon" :src="$utils.getPng('fmNewThree')" />
              <span class="name">{{ $t('home.TxtFour') }}</span>
            </div>
          </div>
          <div class="BoxOneBt">
            <div class="one" @click="toUrl('register')">
              <span class="font" v-if="!islogin">{{ $t('home.Signup') }}</span>
              <span class="font" v-else>{{ $t('home.EnterCloud') }}</span>
              <img :src="$utils.getPng('bt-icon-one')" class="btIcon" />
            </div>
            <div
              class="two"
              @click="
                $refs.addScroll.scrollTo({ top: 9999, behavior: 'smooth' })
              "
            >
              <div class="font">{{ $t('home.Download') }}</div>
              <img :src="$utils.getPng('bt-icon-two')" class="btIcon" />
            </div>
          </div>
          <!-- <img
            v-if="$store.state.language == 'en'"
            class="blxEn"
            :src="$utils.getPng('fm-down-blx-en')"
          />
          <img v-else class="blx" :src="$utils.getPng('fm-down-blx')" /> -->
        </div>
        <div
          class="homeImgOne"
          :class="$store.state.language == 'en' ? 'enTopOne' : ''"
          :style="backgroundStyleOne"
        >
          <div
            class="fontBox"
            :class="$store.state.language == 'en' ? 'enFontBox' : ''"
          >
            <div :class="$store.state.language == 'en' ? 'font1En' : 'font1'">
              <span>{{ $t('home.UltraTransfer') }}</span>
              <span>{{ $t('home.TxtFive') }}</span>
            </div>
            <span
              :class="$store.state.language == 'en' ? 'font2En' : 'font2'"
              >{{ $t('home.TxtSix') }}</span
            >
            <div class="font3Box" @click="toUrl()">
              <span class="font3">{{ $t('home.TryNow') }}</span>
              <svg-btn class="right" icon-class="icon-right-arrow" />
            </div>
          </div>
        </div>
        <div
          class="homeImgTwo"
          :class="$store.state.language == 'en' ? 'enTopTwo' : ''"
          :style="backgroundStyleTwo"
        >
          <div
            class="fontBox"
            :class="$store.state.language == 'en' ? 'enRigheOne' : ''"
          >
            <div :class="$store.state.language == 'en' ? 'font1En' : 'font1'">
              <span>{{ $t('home.EasySharing') }}</span>
              <span>{{ $t('home.TxtSeven') }}</span>
            </div>
            <span
              :class="$store.state.language == 'en' ? 'font2En' : 'font2'"
              >{{ $t('home.TxtEight') }}</span
            >
            <div class="font3Box" @click="toUrl()">
              <span class="font3">{{ $t('home.TryNow') }}</span>
              <svg-btn class="right" icon-class="icon-right-arrow" />
            </div>
          </div>
        </div>
        <div
          class="homeImgThree"
          :class="$store.state.language == 'en' ? 'enTopTwo' : ''"
          :style="backgroundStyleThree"
        >
          <div
            class="fontBox"
            :class="$store.state.language == 'en' ? 'enFontBox' : ''"
          >
            <div :class="$store.state.language == 'en' ? 'font1En' : 'font1'">
              <span>{{ $t('home.OneSav') }}</span>
              <span>{{ $t('home.SeamlesslySave') }}</span>
            </div>
            <span
              :class="$store.state.language == 'en' ? 'font2En' : 'font2'"
              >{{ $t('home.TxtNine') }}</span
            >
            <div class="font3Box" @click="toUrl()">
              <span class="font3">{{ $t('home.TryNow') }}</span>
              <svg-btn class="right" icon-class="icon-right-arrow" />
            </div>
          </div>
        </div>
        <div class="homeImgFour" :style="backgroundStyleFour">
          <div
            class="fontBox"
            :class="$store.state.language == 'en' ? 'enRigheOne' : ''"
          >
            <div :class="$store.state.language == 'en' ? 'font1En' : 'font1'">
              <span>{{ $t('home.MultiSupport') }}</span>
              <span>{{ $t('home.TxtTen') }}</span>
            </div>
            <span
              :class="$store.state.language == 'en' ? 'font2En' : 'font2'"
              >{{ $t('home.TxtEleven') }}</span
            >
            <div class="font3Box" @click="toUrl()">
              <span class="font3">{{ $t('home.TryNow') }}</span>
              <svg-btn class="right" icon-class="icon-right-arrow" />
            </div>
          </div>
        </div>
        <div class="downBox">
          <span class="title">{{ $t('home.DownloadNowNew') }}</span>
          <div class="donwLoad">
            <div class="box" style="margin-left: 0">
              <div class="boxOne">
                <img class="img" :src="$utils.getPng('fm-down-one')" />
                <span class="font">{{ $t('home.AndroidDownload') }}</span>
              </div>
              <img class="code" :src="$utils.getPng('fm-down-code')" />
              <div class="boxTwo">
                <img class="down" :src="$utils.getPng('downapp')" />
                <span class="font">{{ $t('home.DownloadNow') }}</span>
              </div>
            </div>
            <div class="box">
              <div class="boxOne">
                <img class="img" :src="$utils.getPng('fm-down-two')" />
                <span class="font">{{ $t('home.iOSDownload') }}</span>
              </div>
              <img class="code" :src="$utils.getPng('fm-down-code')" />
              <div class="boxTwo">
                <img class="down" :src="$utils.getPng('downapp')" />
                <span class="font">{{ $t('home.DownloadNow') }}</span>
              </div>
            </div>
            <div class="box" @click="onDown(1)">
              <div class="boxOne">
                <img class="img" :src="$utils.getPng('fm-down-three')" />
                <span class="font">{{ $t('home.WindowsDownload') }}</span>
              </div>
              <img class="code2" :src="$utils.getPng('fm-down-right')" />
              <div class="boxTwo">
                <img class="img" :src="$utils.getPng('fm-down-top')" />
                <span class="font">{{ $t('home.DownloadNow') }}</span>
              </div>
            </div>
            <div class="box" @click="onDown(2)">
              <div class="boxOne">
                <img class="img" :src="$utils.getPng('fm-down-four')" />
                <span class="font">{{ $t('home.MacDownload') }}</span>
              </div>
              <img class="code2" :src="$utils.getPng('fm-down-right')" />
              <div class="boxTwo">
                <img class="img" :src="$utils.getPng('fm-down-top')" />
                <span class="font">{{ $t('home.DownloadNow') }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="bottom">
          <span
            >© Copyright. Feemoo Limited 2014-2025. All rights reserved</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import data3 from '../../utils/animation/avatar'
import HoverPop from '@/components/hoverPop/index.vue'
import {
  getHomeIndex,
  clientDownUrlAPI,
  getUserInfoAPI,
  checkLoginFlag,
  getWebUserVipInfoAPI
} from '@/utils/apiList/home'
import { loginOutAPI } from "@/utils/apiList/login";
export default {
  name: 'firstPage',
  data() {
    return {
      navData: ['home.Home', 'home.Clouddrive', 'home.VIP' ,'Feemoo AI'],
      privilegeList: [
        'icon-home-privilege0',
        'icon-home-privilege1',
        'icon-home-privilege2',
        'icon-home-privilege3',
        'icon-home-privilege4',
        'icon-home-privilege5',
        'icon-home-privilege6',
        'icon-home-privilege7',
      ],
      islogin: false,
      showNavbar2: false,
      downloadData: [
        { id: 1, name: 'home.AndroidClient' },
        { id: 2, name: 'home.iOSClient' },
        { id: 3, name: 'home.WindowsDesktop' },
        { id: 4, name: 'home.MacOSDesktop' },
      ],
      commentData: '',
      commentData2: '',
      userInfo: {},
      exe: {},
      fmpUrl: 'https://www.feemoo.com', //这是飞猫盘海外版 用feemoo
      infoList: [
        {
          name: 'home.TxtTwo',
          img: this.$utils.getPng('fmNewOne'),
        },
        {
          name: 'home.TxtThree',
          img: this.$utils.getPng('fmNewTwo'),
        },
        {
          name: 'home.TxtFour',
          img: this.$utils.getPng('fmNewThree'),
        },
      ],
      VipInfo: {},
      usedPre: 0,
      Brand: "",
    }
  },
  computed: {
    optionSetting() {
      return {
        step: 0.43, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100, // 单步运动停止的时间(默认值1000ms)
      }
    },
    language() {
      return this.$store.state.language
    },
    backgroundStyleOne() {
      if (this.$store.state.language == 'en') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-one-new-en')})`,
        }
      }
      if (this.$store.state.language == 'hk') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-one-new-hk')})`,
        }
      }
      return {
        backgroundImage: `url(${this.$utils.getPng('home-img-one-new')})`,
      }
    },
    backgroundStyleTwo() {
      if (this.$store.state.language == 'en') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-two-en')})`,
        }
      }
      if (this.$store.state.language == 'hk') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-two-hk')})`,
        }
      }
      return {
        backgroundImage: `url(${this.$utils.getPng('home-img-two')})`,
      }
    },
    backgroundStyleThree() {
      if (this.$store.state.language == 'en') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-three-en')})`,
        }
      }
      if (this.$store.state.language == 'hk') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-three-hk')})`,
        }
      }
      return {
        backgroundImage: `url(${this.$utils.getPng('home-img-three')})`,
      }
    },
    backgroundStyleFour() {
      if (this.$store.state.language == 'en') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-four-new-en')})`,
        }
      }
      if (this.$store.state.language == 'hk') {
        return {
          backgroundImage: `url(${this.$utils.getPng('home-img-four-new-hk')})`,
        }
      }
      return {
        backgroundImage: `url(${this.$utils.getPng('home-img-new-four')})`,
      }
    },
    titleImg() {
      if (this.$store.state.language == 'en') {
        return this.$utils.getPng('fm-new-title-new-en')
      }
      if (this.$store.state.language == 'hk') {
        return this.$utils.getPng('fm-new-title-new-hk')
      }
      return this.$utils.getPng('fm-new-title-now')
    },
  },
  created() {
    clientDownUrlAPI().then(res => {
      if (res.status == '1') this.exe = res.data
    })
    this.checkLoginFun()
    this.getSubAvatar()
  },
  components: {
    HoverPop,
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisiable)
  },
  destroyed() {
    document.removeEventListener('visibilitychange', this.handleVisiable)
  },
  methods: {
    //点击下载
    onDown(row) {
      if (row == 1) {
        // 创建链接元素
        var link = document.createElement('a')
        link.href = this.exe.win_url
        link.download = ''
        // 模拟点击链接以下载文件
        link.click()
      } else if (row == 2) {
        // 创建链接元素
        var link = document.createElement('a')
        link.href = this.exe.mac_url
        link.download = ''
        // 模拟点击链接以下载文件
        link.click()
      }
    },
    toUrl(type) {
      const lang = {
        en: '/en-US',
        hk: '/zh-HK',
        cn: '',
      }
      if (this.islogin) {
        this.$router.push({ path: this.$store.state.fmPath })
      } else {
        window.location.href = `${this.fmpUrl}${
          lang[this.$store.state.language]
        }/login?path=${this.$store.state.fmPath}${
          type == 'register' ? '&tab=2' : ''
        }`
      }
    },
    changeLanguage(index) {
      this.$utils.changeLanguage(this.$route, index)
      document.title = this.$t('common.title')
    },
    handleVisiable(e) {
      if (e.target.visibilityState === 'visible' && !this.islogin)
        this.checkLoginFun()
    },
    openDaren() {
      const url = process.env.VUE_APP_DAREN_URL
      window.open(url)
    },
    scrollBox(event) {
      this.showNavbar2 = event.target.scrollTop > 0
    },
    getSubAvatar() {
      getHomeIndex({ type: 'internal' })
        .then(res => {
          if (res.status == '1') {
            var commentList = res.data.iconList //评论
            this.commentData = commentList.slice(0, 4)
            this.commentData2 = commentList.slice(4, 8)
            let arr = data3.assets
            for (var i = 0; i < arr.length; i++) {
              for (var j = 0; j < res.data.logoInfo.length; j++) {
                if (i == j) {
                  arr[i].p = res.data.logoInfo[j].name
                  arr[i].u = res.data.logoInfo[j].path
                }
              }
            }
            data3.assets = arr
          } else {
          }
        })
        .catch(err => {})
    },
    goLogin(type) {
      const query = {
        path: this.$store.state.fmPath,
      }
      if (type === 'register') {
        query.tab = '2'
      }
      // this.$router.push({ path: "/login", query });
      const lang = {
        en: '/en-US',
        hk: '/zh-HK',
        cn: '',
      }
      window.location.href = `${this.fmpUrl}${
        lang[this.$store.state.language]
      }/login?path=${query.path}${type == 'register' ? '&tab=2' : ''}`
    },
    gocloud() {
      this.$router.push({ path: '/drive' })
    },
    goRouter(index) {
      const lang = {
        en: '/en-US',
        hk: '/zh-HK',
        cn: '',
      }
      if (index == 0) {
        window.location.reload()
      } else if (index == 1) {
        if (this.islogin) {
          this.$router.push({ path: this.$store.state.fmPath })
        } else {
          window.location.href = `${this.fmpUrl}${
            lang[this.$store.state.language]
          }/login?path=drive`
        }
      }else if(index == 3){
        this.$router.push({ path: 'ai' })
      } else {
        if (this.islogin) {
          this.$utils.routeModeReported('B8')
        }
        window.open(
          `${this.fmpUrl}${lang[this.$store.state.language]}/vip`,
          '_blank'
        )
      }
    },
    onClike(item) {
      if (item.id == 3) {
        // 创建链接元素
        var link = document.createElement('a')
        link.href = this.exe.win_url
        link.download = ''
        // 模拟点击链接以下载文件
        link.click()
      } else if (item.id == 4) {
        // 创建链接元素
        var link = document.createElement('a')
        link.href = this.exe.mac_url
        link.download = ''
        // 模拟点击链接以下载文件
        link.click()
      }
    },
    tableClick(item) {
      if (item) {
        this.$router.push({ path: item });
      }
    },
    loginOut() {
      loginOutAPI()
        .then((res) => {
          if (res.status == 1) {
            localStorage.setItem("loginflag", 1);
            localStorage.removeItem("platfro");
            this.$store.commit("clearUserData"); //重置缓存
            this.$utils.clearCookie(this.$glb.fmCookieName)
            this.islogin = false
            window.location.reload();
          }
        })
        .catch((err) => {});
    },
    getWebUserVipInfoFn() {
      getWebUserVipInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            this.VipInfo = res.data;
            document.documentElement.style.setProperty(
              "--Brand-1",
              ["#5F646F", "#915D44", "#374A71", "#764A0B", "#503567"][
                res.data.header.new_user_head
              ]
            ); // 浅色白底悬浮
            document.documentElement.style.setProperty(
              "--Brand-2",
              [
                "rgba(95, 100, 111, .6)",
                "rgba(145, 93, 68, .6)",
                "rgba(55, 74, 113, .6)",
                "rgba(118, 74, 11, .6)",
                "rgba(80, 53, 103, .6)",
              ][res.data.header.new_user_head]
            ); // 浅色白底悬浮
            this.Brand = [
              "#5F646F",
              "#915D44",
              "#374A71",
              "#764A0B",
              "#503567",
            ][res.data.header.new_user_head];
            if (res.data.cloud_size.cloud_size == "999999") {
              this.usedPre = 0;
            } else {
              this.usedPre =
                (res.data.cloud_size.self_size /
                  res.data.cloud_size.cloud_size) *
                100;
            }
          }
        })
        .catch((err) => {});
    },
    checkLoginFun: _.debounce(
      function () {
        checkLoginFlag({}).then(res => {
          if (res.status == '1') {
            if (res.data.status == '1') {
              getUserInfoAPI().then(res => {
                this.islogin = true
                this.userInfo = res.data
                this.getWebUserVipInfoFn();
              })
            } else {
              this.$utils.clearCookie(this.$glb.fmCookieName)
              this.islogin = false
            }
          }
        })
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
  },
}
</script>
<style lang="scss" scoped>
@import './index.scss';
.dropDownBox{
  .dropItem{
    top: 30px !important;
  }
}
#homepage {
  .userBox {
    display: flex;
    align-items: center;
    margin-left: 24px;
    .avater {
      border-radius: 14px;
      margin-right: 12px;
    }
    span {
      font-weight: 500;
      font-size: 12px;
      color: #2c2c2c;
      line-height: 20px;
    }
  }
  h1 {
    font-size: 40px;
    font-weight: 600;
    color: #2c2c2c;
    line-height: 60px;
    margin: 0;
  }
  .board {
    width: 1200px;
    margin: 152px auto 0;
    display: flex;
    align-items: center;
    .board-text {
      flex: auto;
    }
    h1 {
      line-height: 56px;
      margin-bottom: 24px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      white-space: pre-line;
      color: #8a8b92;
      line-height: 24px;
      margin-bottom: 32px;
    }
    img {
      height: 400px;
    }
  }
  .CloudMaster {
    text-align: center;
    padding: 180px 0;
    .list {
      width: 1140px;
      min-height: 192px;
      margin: 64px auto 52px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .item {
        width: 252px;
        text-align: left;
        .line {
          height: 1px;
          overflow: visible;
          background: #e5e5e5;
          margin-bottom: 24px;
          .bar {
            height: 2px;
            width: 24px;
            background: #f67942;
          }
        }
        p {
          font-weight: 500;
          font-size: 16px;
          color: #2c2c2c;
          line-height: 22px;
        }
        span {
          margin-top: 15px;
          font-weight: 400;
          font-size: 16px;
          color: #8a8b92;
          white-space: pre-line;
          line-height: 24px;
        }
      }
    }
  }
  .privilege {
    height: 1092px;
    padding: 180px 0;
    background: #f9f9f9;
    text-align: center;
    box-sizing: border-box;
    .card {
      margin: 64px auto;
      width: 1140px;
      height: 522px;
      box-sizing: border-box;
      background: #ffffff;
      border-radius: 40px;
      padding: 100px 70px 0;
      .list {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
      }
      .item {
        width: 210px;
        height: 154px;
        text-align: center;
        margin: 0 20px 38px;
        .svg-icon {
          height: 92px;
          width: 92px;
        }
        span {
          display: block;
          white-space: pre-line;
          margin: auto;
          font-weight: 500;
          font-size: 18px;
          color: #2c2c2c;
          line-height: 26px;
        }
      }
    }
  }
  .more {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: #f67942;
    line-height: 22px;
    .svg-icon {
      margin-left: 16px;
      width: 14px;
      height: 14px;
    }
  }
  .button {
    cursor: pointer;
    height: 52px;
    line-height: 52px;
    padding: 0 32px;
    border: 1px solid #e5e5e5;
    border-radius: 12px;
    font-weight: 500;
    font-size: 18px;
    background-color: #fff;
  }
  .button:hover {
    background: #f7f7f7;
  }
  .fm-button {
    font-weight: 500;
    font-size: 18px;
    padding: 0 32px;
    color: #fff;
    height: 52px;
    display: inline-flex;
    align-items: center;
    background: #f67942;
    border-radius: 12px;
    .svg-icon {
      margin-left: 8px;
      width: 14px;
      height: 14px;
    }
  }
  .fm-button:hover {
    background: #faaf8e;
  }
  .fm-button:active {
    background: #d84f13;
  }
}
</style>
