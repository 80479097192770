import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import "./assets/styles/index.scss";
import utils from "./utils/index";
import Toast from "@/components/ToastShow";
import FmButton from "@/components/Button";
import myConfig from "./utils/config/index";
import Highlight from "./utils/highlight-plugin";
import VueMarkdown from "vue-markdown"
import { Dropdown ,Menu} from "ant-design-vue";
import { initAliCodeValidate } from "@/components/Dialog/handleDialog";
import i18n from "@/langurage/index"; 

import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";
import _ from "lodash";
import "@/icons/index";
import scroll from "vue-seamless-scroll";
import VueClipBoard from "vue-clipboard2";
const originalWindowOpen = window.open;

// 重写 window.open 函数
window.open = function (...args) {
  if (store.state.language !== 'cn') {
    const domainMapping = {
      en: {
        'www.feemoo.com': '/en-US',
        'www.feimaoyun.com': '/en-US'
      },
      hk: {
        'www.feemoo.com': '/zh-HK',
        'www.feimaoyun.com': '/zh-HK'
      }
    };

    for (const [domain, path] of Object.entries(domainMapping[store.state.language])) {
      if (args[0].includes(domain)&&!args[0].includes(path)) {
        args[0] = args[0].replace(domain, `${domain}${path}`);
        break;
      }
    }
  }
  return originalWindowOpen.apply(this, args);
};
Vue.use(scroll);

Vue.prototype._ = _;

Vue.config.productionTip = false;
Vue.prototype.$utils = utils;
Vue.prototype.$glb = myConfig;
Vue.prototype.$AliCode = initAliCodeValidate();
// 注册按钮组件
Vue.component(FmButton.name, FmButton);
Vue.component("vue-markdown",  VueMarkdown)
Vue.use(Highlight);

Vue.use(VueClipBoard);
Vue.use(Dropdown);
Vue.use(Menu);

// 定义插件对象
const ToastObj = {
  install: function (Vue) {
    // 创建一个Vue的“子类”组件
    const ToastConstructor = Vue.extend(Toast);
    // 创建一个该子类的实例,并挂载到一个元素上
    const instance = new ToastConstructor();
    // 将这个实例挂载到动态创建的元素上,并将元素添加到全局结构中
    instance.$mount(document.createElement("div"));
    document.body.appendChild(instance.$el);
    // 在Vue的原型链上注册方法，控制组件
    Vue.prototype.$toast = (msg, state) => {
      instance.showToastfun(msg, state);
    };
    Vue.prototype.$toastClose = () => {
      instance.close();
    };
    setTimeout(() => {
      document.body.removeChild(instance.$el);
    }, 3000);
  },
};
Vue.use(ToastObj);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
