export default {
  home: { // 首页模块
    label1: "中文",
    FEEMOO: 'logo-fm-new', // logo
    Home: '首页', // 首页
    Clouddrive: '云盘', // 云盘
    VIP: '会员', //会员
    Login: '登录', //登录
    Enterthewebdisk: '进入网盘', // 进入网盘
    Signup: '立即注册', //立即注册
    Enter: '进入我的飞猫盘', // 进入我的飞猫盘
    Tenyearsinarow: '十年如一日', //十年如一日
    Launched: '上线10年老品牌 iOS、Android、Win、Mac全平台客户端', //上线10年老品牌 iOS、Android、Win、Mac全平台客户端
    FastDownload: '极速下载', // 极速下载
    OnlinePreview: '在线预览', // 在线预览
    Clouddecompression: '云端解压', // 云端解压
    MultiPlatformSupport: '多平台支持', // 多平台支持
    Shareandearn: '分享赚收益', // 分享赚收益
    Extreme1: '极致速度体验：', // 极致速度体验：
    Extreme2: ' 飞猫盘采用分布式存储技术，千兆宽带下载，支持多线程下载，无论多大文件都能轻松下载到本地。', // 飞猫盘采用分布式存储技术，千兆宽带下载，支持多线程下载，无论多大文件都能轻松下载到本地。
    Easy1: '音频图文轻松看： ', // 音频图文轻松看： 
    Easy2: ' 无需下载，即刻在线打开，超高清影音震撼你的视听，在线预览看不受限制，快来体验随心所欲的乐趣吧！', // 无需下载，即刻在线打开，超高清影音震撼你的视听，在线预览看不受限制，快来体验随心所欲的乐趣吧！
    Oneclick1: '一键解压： ', // 一键解压： 
    Oneclick2: ' 极速预览压缩包内容、一键解压、兼容性强、支持多种格式在线解压。', // 极速预览压缩包内容、一键解压、兼容性强、支持多种格式在线解压。
    Support: '支持rar、zip等主流解压文件格式', // 支持rar、zip等主流解压文件格式
    Crossplatform1: '跨平台云服务： ', // 跨平台云服务： 
    Crossplatform2: ' 高体验客户端支持Windows、Mac、Android、iOS等多种操作系统和设备，让您随时随地访问和管理您的文件。', // 高体验客户端支持Windows、Mac、Android、iOS等多种操作系统和设备，让您随时随地访问和管理您的文件。
    Rebate1: '返利计划： ', // 返利计划： 
    Rebate2: ' 上传文件并分享链接或合集给好友下载，就能获得下载返利！', // 上传文件并分享链接或合集给好友下载，就能获得下载返利！
    participate1: '参与返利计划需先在飞猫达人中心 ', // 参与返利计划需先在飞猫达人中心。
    participate2: '申请成为飞猫达人。', // 申请成为飞猫达人。
    BoardTitle1: '全平台支持',
    BoardText1: 'iOS、Android、Win、Mac全平台客户端',
    BoardTitle2: '传输与分享',
    BoardText2: '多平台：桌面端、移动端、网页端，随时随地管理文件。\n极速传输：快速上传下载， 提升工作效率。\n便捷分享：轻松分享文件，一键创建分享合集。',
    BoardTitle3: '安全又稳定',
    BoardText3: '飞猫盘采用分布式存储技术，千兆宽带下载，支持多线程下载，企业级安全服务，存储更安心。',
    CloudMaster: '成为CloudMaster赚钱', // 成为CloudMaster赚钱
    Sharing: '享受分享的乐趣，轻松赚取属于你的变现收益', // 享受分享的乐趣，轻松赚取属于你的变现收益
    Label1: '下载量收益', //下载量收益
    Text1: '分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label2: '会员返利收益', //下载量收益
    Text2: '分享文件给好友下载，按下载用户开通会员进行按比例分成，文件不过期，页面更简洁，适合大文件分享', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label3: '优惠码返利', //下载量收益
    Text3: '好友通过你的优惠码开通会员享折扣，同时您可获得高额返利；达人等级越高，优惠码折扣越大。', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label4: '下线返利', //下载量收益
    Text4: '好友通过你的邀请链接注册后成为飞猫达人，你可获得好友收益的高额返利。', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    More: '查看更多', // 查看更多
    MorePrivilege: '查看更多特权', // 查看更多特权
    Privilege: '会员用户特权', // 会员用户特权
    CardText0: '极致下载加速',
    CardText1: '最高1000GB\n存储空间',
    CardText2: '最高每天300次\n下载额度',
    CardText3: '便捷云转存',
    CardText4: '无广告',
    CardText5: '在线云解压',
    CardText6: '在线音视频',
    CardText7: '已删除文件将在\n回收站保留60天',
    estimation: '评论', // 评论
    evaluate: '评价', // 评价
    AndroidClient: 'Android客户端', // Android客户端
    Download: '下载', // 下载
    DownloadNow: '立即下载', // 立即下载
    iOSClient: 'iOS客户端', // iOS客户端
    WindowsDesktop: 'Windows桌面端', // Windows桌面端
    MacOSDesktop: 'Mac OS桌面端', // Mac OS桌面端
    ScanQRcodedownload: '扫码下载', // 扫码下载
    FEEMOOLIMITED: '备案信息', // 备案信息
    DownloadNowNew:'即刻下载',//即刻下载
    TryNow:'立即体验',//立即体验
    TxtOne:'极速传输 一键转存',//极速传输 一键转存
    TxtTwo:'合集分享 传递更多',//合集分享 传递更多
    TxtThree:'一键转存 高效管理',//一键转存 高效管理
    TxtFour:'多端同步 随时访问',//多端同步 随时访问
    UltraTransfer:'极速传输',//极速传输
    TxtFive:'高效的文件传输体验',//高效的文件传输体验
    TxtSix:'超快传输，尽享飞驰，FEEMOO凭借卓越的传输技术，轻松实现大文件和海量数据的瞬间同步，让数据流转如同飞驰电掣，带来极致的使用体验。',//超快传输，尽享飞驰，FEEMOO凭借卓越的传输技术，轻松实现大文件和海量数据的瞬间同步，让数据流转如同飞驰电掣，带来极致的使用体验。
    EasySharing:'轻松分享',//轻松分享
    TxtSeven:'便捷灵活的分享功能',//便捷灵活的分享功能
    TxtEight:'便捷分享，掌控自如，一键生成文件或合集的分享链接，发送给好友，灵活设置分享权限与分享模式，确保文件安全、分享无忧。',//便捷分享，掌控自如，一键生成文件或合集的分享链接，发送给好友，灵活设置分享权限与分享模式，确保文件安全、分享无忧。
    OneSav:'一键转存',//一键转存
    SeamlesslySave:'无缝衔接的快速保存',//一键转存
    TxtNine:'智能转存，井然有序，一键将好友分享的文件或合集高效转存至FEEMOO，自动智能分类，让文件管理井井有条，查找和使用更加便捷。',//智能转存，井然有序，一键将好友分享的文件或合集高效转存至FEEMOO，自动智能分类，让文件管理井井有条，查找和使用更加便捷。
    MultiSupport:'多平台支持',//多平台支持
    TxtTen:'自由畅行的跨平台体验',//自由畅行的跨平台体验
    TxtEleven:'无缝连接，畅享便捷，全面兼容Windows、Mac、iOS、Android等主流平台，实现文件的无缝同步和随时访问，确保数据的连续性和便捷性。',//无缝连接，畅享便捷，全面兼容Windows、Mac、iOS、Android等主流平台，实现文件的无缝同步和随时访问，确保数据的连续性和便捷性。
    EnterCloud:'进入我的云盘',//进入我的云盘
    AndroidDownload:'Android客户端',//Android客户端
    iOSDownload:'iOS客户端',//iOS客户端
    WindowsDownload:'Windows桌面端',//Windows桌面端
    MacDownload:'Mac OS桌面端',//Mac OS桌面端
  },
  login: { // 登录模块
    Home: '首页', // 首页
    Clouddrive: '云盘', // 云盘
    VIP: '会员', //会员
    ScanQRcodetologin: '扫码登录', // 扫码登录
    Pleaseuse1: '请使用', // 请使用
    Pleaseuse2: '飞猫盘', //  飞猫盘
    Pleaseuse3: 'APP', //  APP
    Pleaseuse4: '在页面右上角打开扫一扫', // 在页面右上角打开扫一扫
    DownloadAPP: '下载APP', // 下载APP
    DownloadDesktop: '下载桌面端', // 下载桌面端
    Login: '登录', // 登录
    Signup: '注册', // 注册
    Pleaseenter: '请输入账号/邮箱号/手机号', // 请输入账号/邮箱号/手机号
    Pleasepassword: '请输入密码', // 请输入密码
    Rememberpassword: '记住密码', // 记住密码
    Forgotpassword: '忘记密码？', // 忘记密码？
    Moreways: '更多方式', // 更多方式
    Back: '返回', // 返回
    Mobilephone: '手机号找回', // 手机号找回
    themobilephone: '请填写注册账号时所填写手机号', // 请填写注册账号时所填写手机号
    Pleaseentermobile: '请输入手机号', // 请输入手机号
    Next: '下一步', // 下一步
    Mobilephonenumber1: '手机号已停用？', // 手机号已停用？
    Mobilephonenumber2: '邮箱找回', // 邮箱找回
    Verifymobile: '验证手机号', // 验证手机号
    verificationminutes1: '请输入发送至', // 请输入发送至+8618899990000的6位验证码，有效期3分钟
    verificationminutes2: '的 6 位验证码，有效期3分钟', // 请输入发送至+8618899990000的6位验证码，有效期3分钟
    Retrieve: '后重新获取', // 重新获取
    VerifyEmail: '验证邮箱', // 验证邮箱
    Retrievecaptcha: '重新获取验证码', // 重新获取验证码
    Emailretrieval: '邮箱找回', // 邮箱找回
    Pleaseemail: '请填写注册账号时所填写邮箱', // 请填写注册账号时所填写邮箱
    Pleasemailbox: '请输入邮箱号', // 请输入邮箱号
    Emailnot: '邮箱无法接收？', // 邮箱无法接收？
    Phoneretrieval: '手机号找回', // 手机号找回
    Resetpassword: '重设密码', // 重设密码
    Pleasewithpassword: '请输入新密码，最少设置 6 位数字或字母', // 请输入新密码，最少设置 6 位数字或字母
    Newpassword: '新密码', // 新密码
    Enternewpassword: '再次输入新密码', // 再次输入新密码
    Gotologin: '完成，去登录', // 完成，去登录
    automatically1: '新手机号将自动注册，', // 新手机号将自动注册，
    automatically2: '已详读并同意', // 已详读并同意
    automatically3: '《用户服务协议》', // 《用户服务协议》
    Clicktorefresh: '点击刷新', // 点击刷新
    ViewSupported: '查看支持注册的邮箱号', // 查看支持注册的邮箱号
    Setupaccount: '设置账号', // 设置账号
    accountdigits: '请设置Feemoo账号（6-20位数字或字母）', // 请设置飞猫盘账号（6-20位数字或字母）
    Setpassword: '设置密码', // 设置密码
    Setloginpassword: '请设置登录密码（至少6位数字或字母）', // 请设置登录密码（至少6位数字或字母）
    Enterpassword: '请再次输入密码', // 请再次输入密码
    Confirm: '确定', // 确定
    Wechatscan: '微信扫码登录/注册', // 微信扫码登录/注册
    TheFEEMOOCenter:'飞猫达人中心',//飞猫达人中心
    GrantedPrivileges:'将获得以下权限',//将获得以下权限
    permissionOne: '获取你的FEEMOO头像、昵称',
    permissionTwo: '获取你的FEEMOO账号',
    Files: '文件', // 文件
    Set: '合集', // 合集
    Collect:'收藏',//收藏
    app: '客户端', // 客户端
  },
  vip: { // vip模块
    vip: '开通会员', // 开通会员
    intrtit0: '专属下载额度',//专属下载额度
    intrcontent0: '最高享300次/天',//最高享300次/天
    intrtit1: '下载加速',
    intrcontent1: '最高享极致加速',
    intrtit2: '去广告体验',
    intrcontent2: '会员专享纯净体验',
    intrtit3: '更多云体验',
    intrconten3: '6项在线功能',
    openVip: '立即开通会员服务享受特权', // 立即开通会员服务享受特权
    limitedSeries: '限量发售', // 限量发售
    greatValue: '超值爆款', // 超值爆款
    vipMax: '不限时长会员',//不限时长会员
    vipIntr: '注：标有*特权需消耗下载额度使用，剩余通用下载额度不足时，可消耗福利点使用；会员限时免费体验Feemoo AI，每月 1号刷新Feemoo AI对话的token用量。',//注：标有*特权需消耗下载额度使用，剩余通用下载额度不足时，可消耗福利点使用；会员限时免费体验Feemoo AI，每月 1号刷新Feemoo AI对话的token用量。
    welfare: '福利社',// 福利社
    buy:'开通',//开通
    buy1:'升级',//升级
    Notloggedin: '未登录', // 未登录
    Cloudequity: '权益', // 权益
    allBenefits: '全部权益',// 全部权益
    ViewAllBenefits: '查看全部权益', // 查看全部权益
    PremiumEscalation: '补差价升级', // 补差价升级
    ActualPayment: '实付', // 实付
    OpenVIP: '开通会员', // 开通会员
    Useode: '使用代金券或优惠码', // 使用代金券或优惠码
    offAlready: '已优惠', // 已优惠
    Renewalfee: '续费', // 续费25元
    Discount:'立减',//立减
    Premium:'开通会员',//开通会员
    PremiumTwo:'升级会员',//升级会员
    PremiumThree:'会员中心',//会员中心
  },
  cloud: { // 云盘模块
    CloudSpace: '云盘空间', // 云盘空间
    todayDownloadNum: '今日下载额度', // 今日下载
    files: '文件', // 文件
    share: '分享', // 分享
    history: '历史', // 历史
    recycle: '回收站', // 回收站
    daren: '飞猫达人',
    filePlaceholderTop: '搜索云盘文件',
    upload: '上传文件',//上传文件
    newFloder: '新建文件夹',//新建文件夹
    Refresh:'刷新',//刷新
    allFiles: '全部文件',//全部文件
    upname1: '文件',//文件
    upname2: '文件夹',//文件夹
    all: '全部',//全部
    package: '压缩包',//压缩包
    medium: '音视频',//音视频
    document: '文档',//文档
    picture: '图片',//图片
    fileName: '文件名',//文件名
    browseDown: '浏览/下载',//浏览/下载
    size: '大小',//大小
    creatTime: '创建时间',//创建时间
    noMore: '没有更多了',//没有更多了
    dragFiles0: '拖拽文件',//拖拽文件到这里上传
    dragFiles1: '到这里上传',//拖拽文件到这里上传
    cannot4g: '单个文件大小不能超过4G',//单个文件大小不能超过4G
    xieyinot: '严禁上传非法内容',//严禁上传非法内容
    rightOpen: '打开', // 打开
    rightDownload: '下载', // 下载
    rightShare: '分享', // 分享
    rightRename: '重命名', // 重命名
    rightDelete: '删除', // 删除
    rightMoveto: '移动到', // 移动到
    pcDownfile: '客户端下载', // 客户端下载
    notFile: '暂无相关文件', // 暂无相关文件
    trySearchOther: '试试搜索其他关键词', // 试试搜索其他关键词
    unzip: '解压', // 解压
    noUpLoad: '暂无上传任务', // 暂无上传任务
    setFileDirectory: '设置文件目录', // 设置文件目录Set file directory
    Addfile: '添加文件', // 添加文件
    Allstarted: '全部开始', // 全部开始
    vipDownload:'会员极速下载中', //会员极速下载中
    Allpause: '全部暂停', // 全部暂停
    AllCancel: '全部取消', // 全部取消
    getLoading: '获取文件信息中', // 获取文件信息中
    Pauseupload: '暂停上传', // 暂停上传
    Uploadfailed: '上传失败', // 上传失败
    chenUploaded: '已上传', // 已上传
    to:'至',//至
    pcTit: '全新PC端，大文件下载更稳定', // 全新PC端，大文件下载更稳定
    memberDownTit:'会员极速下载通道',//会员极速下载通道
    touristDownTit: '普通下载通道',//普通下载通道
    memberDownTitStr:'极速下载中',//极速下载中
    touristDownTitStr: '下载中',//下载中
    instantSpeed: '立即提速',//立即提速
    notRefresh:'有任务进行时请勿刷新网页',//有任务进行时请勿刷新网页
    Goexperience: '前往体验', // 前往体验
    Nodownloadrecord: '暂无下载记录', // 暂无下载记录
    Nodecompressionrecord: '暂无解压记录', // 暂无解压记录
    Queuing: '排队中', // 排队中
    DownloadSuccess: '下载成功', // 下载成功
    DownloadFailure: '下载失败', // 下载失败
    Decompressionrecords: '解压记录保存7天，到期后自动删除', // 解压记录保存7天，到期后自动删除
    Deleterecord: '删除解压记录', // 删除解压记录
    Unpacking: '正在解压', // 正在解压
    UnpackingFailed: '解压失败', // 解压失败
    Extractedto: '已解压到', // 已解压到
  },
  welfare: {// 福利社
    myWelfarePoints: '我的福利点',//我的福利点
    viewWelfarePoints: '查看明细',//查看明细
    getpoint: '得福利点',
    welfareIntr: '福利点可免费下载文件或兑换体验会员特权',
    watchVideotoApp: '去APP看视频',
    payPoint: '花福利点',
    todayTimes: '今日剩余',
    duihuan: '兑换',
    yiduihuan: '已兑完',
    canUsePoints: '福利点可用',
    welfarePoint: '福利点',
    welfareDown:"今日已兑完",//今日已兑完
    welfarePrefix: '今日剩余',
    welfareSuffix: '份'
  },
  common: { // 公共
    cloud: '云盘', // 云盘
    vip: '会员', // 会员,
    app: '客户端', // 客户端
    title: 'Feemoo - 飞猫盘｜安全加密的大容量云存储平台 | 免费跨平台文件同步与高速传输', // 页签名称
    Files: '文件', // 文件
    Set: '合集', // 合集
    Collect:'收藏',//收藏
  },
  setPage:{
    Set: '合集', // 合集
    SearchSet:'搜索合集', // 搜索合集
    AllSet:'全部合集', //全部合集
    SetName:'合集名', //合集名
    NumberEntries:'项目数',//项目数
    browse:'浏览',//浏览
    Collect:'收藏',//收藏
    ShareTime:'分享时间', // 分享时间
    View:'查看', //查看
    Edit:'编辑', // 编辑
    Delete:'删除', //删除
    NoCollection:'暂无合集',//暂无合集
    NoToCollection:'暂无相关合集',//暂无相关合集
    recyleNoCollec:'回收站暂无合集',//回收站暂无合集
    TextOne:'创建合集后可以在这里查看',//创建合集后可以在这里查看
    TextTwo:'试试搜索其他关键词',//试试搜索其他关键词
  },
  Collection:{
    Set: '合集', // 合集
    Files: '文件', // 文件
    allFiles: '全部文件',//全部文件
    SearchFavorites:'搜索收藏文件',//搜索收藏文件
    CollectionTime:'收藏时间',//收藏时间
    NoFavoritesFiles:'暂无收藏文件', //暂无收藏
    NoFavoriteSet:'暂无收藏合集',//暂无收藏合集
    TextOne:'收藏文件后可以在这里查看',//收藏文件后可以在这里查看
    Texttwo:'收藏合集后可以在这里查看',//收藏合集后可以在这里查看
    AllSet:'全部合集', //全部合集
    SetName:'合集名', //合集名
    SearchCollections:'搜索收藏合集',//搜索收藏合集
    New:'更新',//更新
    owningUser:'所属用户',//所属用户
    uncollect:'取消收藏',//取消收藏
    View:'查看', //查看
  },
  userinfo: { // 个人信息悬浮模块
    vip: '开通会员', // 开通会员
    Downloadquota: '下载额度', // 下载额度
    Downloadspeed: '下载加速', // 下载加速
    Cloudcapacity: '云盘空间', // 云盘空间
    Cloudequity: '云盘权益', // 云盘权益
    DownloadLimit: '今日下载额度', // 今日下载额度
    awelfarepoint: '福利点', // 福利点
    HelpCentre: '帮助中心', // 帮助中心
    AccountSettings: '账号设置', // 账号设置
    SignOut: '退出登录', // 退出登录
    Login: '登 录', // 登录
    Signup: '注 册', // 注册
  },
  share: { // 分享模块
    newCollections: '新建合集', // 新建合集
    allShare: '全部分享', // 全部分享
    all: '全部', // 全部
    files: '文件', // 文件
    collention: '合集', // 合集
    sharePlaceholder: '搜索分享文件', // 搜索分享文件
    copyLink: '复制链接', // 复制链接
    viewLink: '查看分享/取件码', // 查看分享/取件码
    editCollection: '编辑合集文件', // 编辑合集文件
    reName: '重命名', // 重命名
    deleteCollection: '删除合集', // 删除合集
    cancelShare: '取消分享', // 取消分享
    cancelDelete: '取消分享/删除合集', // 取消分享/删除合集
    notShareFiles: '暂无分享文件',//暂无分享文件
    shareLook:'分享后可以在这里查看',//分享后可以在这里查看
    
  },
  downLoadHistory: { // 下载历史模块
    openDownload: '开启记录下载历史',//开启记录下载历史
    openDownloaded: '已开启记录下载历史',//已开启记录下载历史
    notOpenDownload: '暂未开启记录下载历史',//暂未开启记录下载历史
    downLoadHistoryPlaceholder: '搜索下载历史', // 搜索下载历史
    openVipDownload: '开通会员专享，可开启记录下载历史', //开通会员专享，可开启记录下载历史
    notDownload: '暂无下载历史',//暂无下载历史
    notHistory:'暂无相关记录', // 暂无相关记录
    recordDownloaded:'记录下载过的文件', // 记录下载过的文件
    viewDownloadShareFiles: '查看已下载的分享文件',// 查看已下载的分享文件
    openVip: '开通会员', // 开通会员
    lookDownloaded: '查看下载', // 查看下载
    deleteDownloaded: '删除', // 删除下载
  },
  recycle: { // 回收站模块
    clearRecycle: '清空回收站', // 清空回收站
    recyclePlaceholder: '搜索回收站文件', // 搜索回收站文件
    effectiveTime: '有效时间',//有效时间
    deleteTime: '删除时间',//删除时间
    restore: '还原',//还原
    sweep: '彻底删除',//彻底删除
    noFiles:'回收站暂无文件',//回收站暂无文件
    tipsPrefix: '回收站内容保存 ',
    tipsSuffix: ' 天，到期后自动删除'
  },
  news: { // 信息悬浮框模块
    All: '全部', // 全部
    SystemNotification: '系统通知', // 系统通知
    OfficialNews: '官方消息', // 官方消息
    ReadAll: '全部已读', // 全部已读
    Nonews: '暂无消息', // 暂无消息
  },
  getapp: { // 下载客户端模块
    DownloadClient: '立即下载客户端', // 立即下载客户端
    Experiencemore: '体验更多云端新功能', // 体验更多云端新功能
    AndroidClient: 'Android客户端', // Android客户端
    iOSClient: 'iOS客户端', // iOS客户端
    WindowsDesktop: 'Windows桌面端', // Windows桌面端
    MacOSDesktop: 'Mac OS桌面端', // Mac OS桌面端,
    DownloadNow: '立即下载', // 立即下载
    ScanQRcodedownload: '扫码下载', // 扫码下载
  },
  downPage: { // 下载页面模块
    createTime: '创建日期', // 创建日期
    fileSize:'文件大小', // 文件大小
    download: '下载', // 下载
    saveFile: '转存至云盘',//转存至云盘
    allFiles: '全部文件',//全部文件
    intrOne0: '手里有资源想赚收益？其他平台收益太低？',
    intrOne1: '来飞猫盘上传文件并分享链接或合集给好友下载，就能获得超高返利！',
    intrOne2: '多种分享模式、多重返利方式，让你天天赚收益！',
    intrTwo0: '大文件下载再也不用等，',
    intrTwo1: '日常转发的资源、课件资料、办公软件，随用随下；',
    intrTwo2: '每日可免费下载/转存文件，大文件一键存网盘',
    intrThree0: '在线解压，无需下载轻松打开文件；',
    intrThree1: '支持后台解压、队列解压，打开压缩包更便捷。',
    intrFour0: '多种音视频，图片，压缩包文件在线预览；',
    intrFour1: '无需下载，直接查看！',
    experienceNow: '立即体验', // 立即体验
    installPC:'安装下载飞猫盘客户端', // 安装下载飞猫盘客户端
    toTop: '回到顶部保存文件', // 回到顶部保存文件
    report: '举报',//举报
    vipFun: '会员专项功能',//会员专项功能
    pcDown: '通过PC客户端下载', // 通过PC客户端下载
    pcAppDown: '通过APP下载', // 通过APP下载
    webDown: '网页端 极速下载', // 网页端 极速下载
    fmpPc: '飞猫盘客户端',//飞猫盘客户端
    touristDownFile: '非会员下载',//非会员下载
    fileStatus: '文件状态',//文件状态
    fileDelete: '此文件已被系统审核过滤或被上传者删除，无法访问',//此文件已被系统审核过滤或被上传者删除，无法访问
    reference:'参考资料', // 参考资料
    question: '如有疑问可访问', // 如有疑问可访问
    touristFun: '非会员功能', // 非会员功能
    webTouristDown: '网页端 非会员下载', // 网页端 非会员下载  
    watchVideoDown: '看视频下载', // 看视频下载
    fileDownloadStatus: '文件下载状态', // 文件下载状态
    downloadComplete: '下载完成', // 下载完成
    downloadError: '下载失败', // 下载失败
    downloadRetry: '重试', // 下载取消
    memberDownTitStr:'极速下载中',//极速下载中
    touristDownTitStr: '下载中',//下载中
    timeOut: '该文件已过期',//该文件已过期
    downloadNow: '立即极速下载', // 立即极速下载
    isprivateFile: '私有文件暂时无法访问',//私有文件暂时无法访问
    descPrefix: '本文件由网友：',
    descSuffix: ' 自行上传，并不代表本站立场'
  },
  setup: { // 账号设置模块
    EditAvatar: '编辑头像', // 编辑头像
    AccountNumber: '账号', // 账号
    PetName: '昵称', // 昵称
    ModifyPhone: '修改手机号', // 修改手机号
    PhoneNumber: '手机号', // 手机号
    WeChat: '微信', // 微信
    LoginPassword: '登录密码', // 登录密码
    Setpasswords: '设置包含数字和字母的密码，并定期更换', // 设置包含数字和字母的密码，并定期更换
    Edit: '编辑', // 编辑
    Bound1: '已绑定', // 已绑定
    Bound2: '绑定', // 绑定
    Bound3: '未绑定', // 未绑定
  },
  Help: { // 帮助中心模块
    FindCustomer: '找客服', // 找客服
    Feedback: '反馈历史', // 反馈历史
    Protocol: '协议信息', // 协议信息
    Announcement: '平台公告', // 平台公告
    Burning: '热门问题', // 热门问题
    Search: '按频道自主查找', // 按频道自主查找
    Nofeedbackhistory: '暂无反馈历史', // 暂无反馈历史
  },
  agreement: { // 协议信息模块
    disclaimerAgreement:'《免责协议》', // 《免责协议》
    privacyPolicy: '《隐私政策》', // 《飞猫盘隐私政策》
    userSharingAgreement: '《飞猫盘用户分享服务协议》', // 《飞猫盘用户分享服务协议》
    memberServiceAgreement: '《飞猫盘会员服务协议》', // 《飞猫盘会员服务协议》
    memberServiceAgreement2: '《飞猫盘会员服务协议》', // 《飞猫盘会员服务协议》
    intellectualPropertyStatement: '《知识产权声明》', // 《知识产权声明》
    userServiceAgreement:'《用户服务协议》', // 《飞猫盘用户服务协议》
    automaticRenewalServiceAgreement: '《飞猫盘自动续费服务协议》', // 《飞猫盘自动续费服务协议》
    privacyPolicyforIOS: '《飞猫盘隐私政策》-iOS版', // 《飞猫盘隐私政策》-iOS版
    readAndAgree: '阅读并同意', // 阅读并同意
    and: '以及', // 以及
    and1: '和', // 和
    disclosureOfContentReviewMechanism: '《飞猫盘内容审核机制公示》', // 《飞猫盘内容审核机制公示》
    dispositionOfOffendingAccounts: '《对于违规账号的处置方式》',// 《对于违规账号的处置方式》
    helpCenter: '《飞猫盘帮助中心》',//《飞猫盘帮助中心》
    downPageDisclosureOfContentReviewMechanism:'《飞猫盘内容审核机制公示》',//《飞猫盘内容审核机制公示》
  },
  chat:{
    StartNewChat:'开启新对话',//开启新对话
    NoChatHistory:'暂无历史对话',//暂无历史对话
    MonthlyTokens:'每月Token',//每月Token
    textOne:'Feemoo AI上线啦！',//飞猫盘智能对话上线啦！
    textTwo:'现已接入 DeepSeek R1 (🔋满血版）有任何问题随时问我哦！',//现已接入 DeepSeek R1 (满血版）有任何问题随时问我哦！
    textThree:'请输入你的问题，帮你深度解答',//请输入你的问题，帮你深度解答
    textFour:'深度思考 (R1)',//深度思考 (R1)
    textFive:'内容由 AI 生成，请仔细甄别',//内容由 Ai 生成，请仔细甄别
    Feed:'意见',//意见
    back:'反馈',//反馈
    Thinking:'思考中',// 思考中
    Copy:'复制',//复制
    Regenerate:'重新生成',//重新生成
    StopGeneration:'停止生成',//停止生成
    Edit:'编辑',//编辑
    Delete:'删除',//删除
    textSix:'AI新功能：极速通道体验顶级AI',//AI新功能：免费极速通道体验顶级AI
    FreeTrial:'前往体验',//免费体验
    EnterYourQuestion:'请输入你的问题',//请输入你的问题
    AboutToRunOut:'即将用尽，',//即将用尽，
    upgradeVIP:'升级会员',//升级会员
    forMoreTokens:'享更多Token',//享更多Token
    ThinkingStopped:'思考已停止',//思考已停止
    Thoughtfor:'已深度思考(用时',//已深度思考(用时
    seconds:'秒)',//秒
    textSeven:'很抱歉，token数量暂时不足，建议',//很抱歉，token数量暂时不足，建议
    textEight:'很抱歉，token数量已用尽，暂无更多Token',//很抱歉，token数量已用尽，暂无更多Token
    Cancel:'取消',//取消
    Send:'发送',//发送
    textNine:'已用尽，',//已用尽，
    textEleven:'享更多Token',//享更多Token
    textTen:'已用尽，暂无更多token',//已用尽，暂无更多token
    SubscribeTo:'请',//请
    VIP:'开通会员',//开通会员
    forTokens:'获得Token',//获得Token
    toVip:'升级会员',///升级会员
    Copied:'复制成功',//复制成功
    CopyFailed:'复制失败',//复制失败
    textTwelve:'升级会员',//升级会员
  },
};

