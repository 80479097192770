<template>
  <div
    ref="scrollContainer"
    :class="{
      chatRight: true,
      chatRightNot: chatHistory.length == 0,
    }"
  >
    <!-- 开启新对话 -->
    <div class="chatNot" v-if="chatHistory.length == 0 && chat_id == ''">
      <img :src="$utils.getPng('icon-deepseek-picture')" class="logo" />
      <!-- <span class="font1">{{ $t('chat.textOne') }}</span> -->
      <div class="font1">
        <svg-btn
          class="fontIcon"
          :icon-class="
            $store.state.language == 'en'
              ? 'icon-deepseek-AI-en'
              : $store.state.language == 'hk'
              ? 'icon-deepseek-AI-hk'
              : 'icon-deepseek-AI'
          "
        />
      </div>
      <span class="font2">{{ $t('chat.textTwo') }}</span>
      <div class="inputBox">
        <el-input
          ref="oneInput"
          class="input"
          :class="{ paddingZero: hasScrollbarOne }"
          v-model="question"
          resize="none"
          autosize
          @input="handleInputOne"
          autofocus
          :placeholder="$t('chat.textThree')"
          type="textarea"
          @keydown.enter.native="handleEnter"
        >
        </el-input>
        <div class="Inputbottom">
          <div class="think">{{ $t('chat.textFour') }}</div>
          <el-tooltip
            open-delay="1000"
            popper-class="deepseek-stop"
            effect="dark"
            :disabled="question.trim().length > 0"
            :content="$t('chat.EnterYourQuestion')"
            placement="bottom-start"
          >
            <div
              class="send"
              @click="inputSend"
              :class="question.trim().length > 0 ? 'sendRI' : ''"
            >
              <svg-btn class="icon" icon-class="send-chat" />
            </div>
          </el-tooltip>
        </div>
      </div>
      <div class="infoBoxWrapper">
        <div class="infoBox" v-show="!hotLoading && hotWordList.length > 0">
          <div
            class="info"
            v-for="(item, index) in hotWordList.slice(0, 10)"
            :key="index"
            @click="sendInfo(item)"
          >
            {{ item.content }}
          </div>
        </div>
        <div class="infoBox" v-show="hotLoading">
          <el-skeleton
            v-for="(item, index) in 10"
            :key="index"
            style="
              margin-right: 16px;
              margin-bottom: 16px;
              width: 150px;
              height: 35px;
            "
          >
            <template slot="template">
              <el-skeleton-item
                variant="text"
                style="width: 150px; height: 35px"
              />
            </template>
          </el-skeleton>
        </div>
      </div>
    </div>
    <!-- 聊天内容主题 -->
    <div
      class="chat-container"
      v-loading="chatLoading"
      v-if="chatHistory.length > 0"
    >
      <div
        v-for="(item, index) in chatHistory"
        :key="item.chat_id"
        class="message"
        v-show="item.question"
      >
        <div class="user">
          <span class="content" v-show="!item.isEdit">
            {{ item.question }}
            <div class="leftBt">
              <div class="iconBoxOne">
                <el-tooltip
                  open-delay="1000"
                  popper-class="deepseek-stop"
                  effect="dark"
                  :content="$t('chat.Copy')"
                  placement="bottom-start"
                >
                  <div class="boxIcon" @click="copy(item.question, item, 1)">
                    <svg-btn class="iconOne" icon-class="icon-deepseek-copy" />
                  </div>
                </el-tooltip>
                <el-tooltip
                  open-delay="1000"
                  popper-class="deepseek-stop"
                  effect="dark"
                  :content="$t('chat.Edit')"
                  placement="bottom-start"
                >
                  <div class="boxIcon" @click="editQuestion(item, index)">
                    <svg-btn class="iconOne" icon-class="icon-deepseek-edit" />
                  </div>
                </el-tooltip>
              </div>
            </div>
          </span>
          <div class="editInputBox" v-show="item.isEdit">
            <el-input
              :ref="'threeInput' + index"
              class="input"
              v-model="item.questionOld"
              resize="none"
              autosize
              autofocus
              type="textarea"
              @keydown.enter.native="sendEdit($event ,item, index)"
            >
            </el-input>
            <div class="btfooter">
              <fm-button
                style="margin-right: 12px"
                @click="editClose(item, index)"
                type="info"
                plain
                size="small"
                >{{ $t('chat.Cancel') }}</fm-button
              >
              <fm-button
                type="primary"
                size="small"
                :disabled="item.questionOld.trim().length == 0 || isAnswer"
                @click="sendEdit($event, item, index)"
                >{{ $t('chat.Send') }}</fm-button
              >
            </div>
          </div>
        </div>
        <div class="deepseek" v-show="item.showRight">
          <img :src="$utils.getPng('icon-deepseek-picture')" class="picture" />
          <div class="box">
            <div
              class="loadingBox"
              v-show="
                item.isVip == 'no' &&
                !item.isThink &&
                !item.reasoningContent &&
                !item.isThinkStop &&
                item.stop != 1
              "
            >
              <div class="loading"></div>
            </div>
            <div
              class="thinkBox"
              v-show="
                item.isVip == 'no' && (item.reasoningContent || item.think_time)
              "
              @click="retract(item, index)"
            >
              <!-- 是否在思考 isThink为true就是思考 item.isThinkStop"是否停止了思考 -->
              <div
                v-show="item.isThink && !item.isThinkStop"
                class="loading"
              ></div>
              <!-- 是否在思考结束 isThink为false就是思考结束，item.answerContent就是出现答案了 -->
              <svg-btn
                v-show="!item.isThink && !item.isThinkStop"
                class="reaSuccess"
                icon-class="icon-deepseek-success"
              />
              <!-- isThinkStop先判断是否思考已停止
              isThink在判断是否在思考中
              think_time判断思考是否已结束 -->
              <span class="thinkText">
                {{
                  item.isThinkStop
                    ? $t('chat.ThinkingStopped')
                    : item.isThink
                    ? $t('chat.Thinking')
                    : item.think_time
                    ? $t('chat.Thoughtfor') +
                      item.think_time +
                      $t('chat.seconds')
                    : $t('chat.Thinking')
                }}</span
              >
              <svg-btn
                :class="item.isRotated ? 'iconBottom' : 'iconTop'"
                icon-class="icon-deepseek-top"
              />
            </div>
            <div
              class="openVipBox"
              v-show="
                item.isVip == 'yes' ||
                item.isVip == 'yesNop' ||
                item.isVip == 'error'
              "
            >
              <span class="vipfontOne">{{
                item.isVip == 'yes'
                  ? $t('chat.textSeven')
                  : item.isVip == 'yesNop'
                  ? $t('chat.textEight')
                  : msg
              }}</span>
              <span
                v-show="item.isVip == 'yes'"
                class="vipfontTwo"
                @click="openVip"
                >{{ $t('chat.textTwelve') }}</span
              >
            </div>
            <div class="answer">
              <div
                class="reasoneBox"
                v-if="item.isVip == 'no'"
                :class="
                  item.stop == 1 && !item.answerContent ? 'contentLine' : ''
                "
              >
                <div
                  class="line"
                  v-if="item.reasoningContent && !item.isRotated"
                ></div>
                <span
                  class="reasone"
                  v-if="item.reasoningContent && !item.isRotated"
                >
                  {{ item.reasoningContent }}
                </span>
              </div>
              <span
                class="content"
                :class="item.stop == 1 ? 'contentLine' : ''"
                v-if="item.isVip == 'no' && item.answerContent"
              >
                <vue-markdown
                  :source="item.answerContent"
                  v-highlight
                  v-copy
                  :key="item.answerContent + '_' + item.chat_id"
                  :html="true"
                  :sanitize="false"
                  :anchorAttributes="{
                    target: '_blank',
                  }"
                >
                </vue-markdown>
              </span>
              <div class="bottomBt" v-show="item.stop == 1">
                <div class="iconBoxOne">
                  <el-tooltip
                    open-delay="1000"
                    popper-class="deepseek-stop"
                    effect="dark"
                    :content="$t('chat.Copy')"
                    placement="bottom-start"
                  >
                    <div
                      class="boxIcon"
                      @click="copy(item.answerContent, item, 2)"
                    >
                      <svg-btn
                        class="iconOne"
                        icon-class="icon-deepseek-copy"
                      />
                    </div>
                  </el-tooltip>
                  <el-tooltip
                    open-delay="1000"
                    popper-class="deepseek-stop"
                    effect="dark"
                    :content="$t('chat.Regenerate')"
                    placement="bottom-start"
                  >
                    <div class="boxIcon" @click="sendUpdate(item)">
                      <svg-btn
                        class="iconOne"
                        icon-class="icon-deepseek-update"
                      />
                    </div>
                  </el-tooltip>
                </div>
                <div
                  v-show="
                    item.isVip == 'no' && item.total_tokens && item.stop == 1
                  "
                  class="tokenText"
                >
                  Tokens: {{ item.total_tokens }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-show="chatHistory.length > 0" class="fgBox"></div> -->
    <!-- 底部input -->
    <div class="buttomInput">
      <div
        class="addBoxOne"
        v-show="shouldShowButton"
        style="transition: all 0.3s ease; will-change: opacity, transform;"
        :style="{
          opacity: shouldShowButton ? 1 : 0,
          transform: `translateY(${shouldShowButton ? 0 : '10px'})`
        }">
        <div class="addBox" @click="addChat">
          <svg-btn class="icon" icon-class="add-chat" />
          <span class="add">{{ $t('chat.StartNewChat') }}</span>
        </div>
      </div>
      <div class="inputBox" v-show="chatHistory.length > 0">
        <el-input
          ref="twoInput"
          class="input"
          :class="{ paddingZero: hasScrollbarTwo }"
          v-model="question"
          resize="none"
          @input="handleInputTwo"
          autosize
          autofocus
          :placeholder="$t('chat.textThree')"
          type="textarea"
          @keydown.enter.native="handleEnter"
        >
        </el-input>
        <div class="Inputbottom">
          <div class="think">{{ $t('chat.textFour') }}</div>
          <!-- 发送按钮 -->
          <el-tooltip
            open-delay="1000"
            popper-class="deepseek-stop"
            effect="dark"
            :disabled="question.trim().length > 0"
            :content="$t('chat.EnterYourQuestion')"
            placement="bottom-start"
          >
            <div
              class="send"
              @click="inputSend"
              v-show="!allThink && !isAnswer"
              :class="question.trim().length > 0 ? 'sendRI' : ''"
            >
              <svg-btn class="icon" icon-class="send-chat" />
            </div>
          </el-tooltip>
          <!-- loading按钮 -->
          <div class="send" v-if="allThink" @click="sendChat">
            <div class="loading"></div>
          </div>
          <!-- 停止生成按钮 -->
          <el-tooltip
            v-show="!allThink && isAnswer"
            open-delay="1000"
            popper-class="deepseek-stop"
            effect="dark"
            :content="$t('chat.StopGeneration')"
            placement="bottom-start"
          >
            <div class="sendStop" @click="stopAnswer(1)">
              <div class="icon"></div>
            </div>
          </el-tooltip>
        </div>
      </div>
      <div
        class="bttBox"
        v-show="chatHistory.length > 0 && showBackToBottom"
        :class="{ blackLine: $store.state.themeBtn != 'light' }"
        @click="toBottom"
      >
        <svg-btn class="bttIcon" icon-class="icon-deepseek-btt" />
      </div>
      <!-- 底部信息 -->
      <div class="footer">{{ $t('chat.textFive') }}</div>
    </div>
    <!-- 悬浮的意见反馈 -->
    <div
      class="fixed"
      @click="feedback"
      :class="{ blackLine: $store.state.themeBtn != 'light' }"
    >
      <svg-btn class="icon" icon-class="return-chat" />
      <div class="box">
        <span>{{ $t('chat.Feed') }}</span>
        <span>{{ $t('chat.back') }}</span>
      </div>
    </div>
    <!-- 顶部渐变色 -->
    <div class="topLine"></div>
    <!-- 升级会员弹窗 -->
    <PromoteDialog
      @handleClose="isOpenVip = false"
      :isShow="isOpenVip"
      :msg="msg"
      :vip_update="vip_update"
      icon="icon_diamond"
      type="1"
    >
    </PromoteDialog>
    <BindPhone
      :isShow="isShowBindPhone"
      :type="bindType"
      :time="time"
      @getPhoneCode="getPhoneCode"
      @bindPhone="bindPhone"
      @confirmNameAuth="realNameAuth"
      @handleClose="isShowBindPhone = false"
    ></BindPhone>
  </div>
</template>

<script>
import { EventBus } from '@/views/DownPage/EventBus'
import {
  sendChat,
  getHot,
  decrypt,
  inParameter,
  stopChat,
  chatDetails,
  getAiToken,
  keepChat,
} from '@/utils/apiList/chat'
import {
  bindPhoneAPI,
  bindPhoneSendCode,
  realNameAuth,
} from '@/utils/apiList/account'
import PromoteDialog from '@/components/Dialog/PromoteDialog'
import BindPhone from '@/components/Cloud/BindPhone'
import Clipboard from 'clipboard'
// 修改后的 getLanguage 方法（小写版）
function getLanguage(className) {
  if (!className) return 'text' // 修改默认值为小写
  const classes = className.split(' ')
  let language = 'text' // 修改默认值为小写
  for (const cls of classes) {
    if (cls.startsWith('language-')) {
      language = cls.replace('language-', '')
      break
    }
  }
  if (language == 'text') {
    // 同步修改判断条件
    const filtered = classes.filter(c => c !== 'hljs' && c !== '')
    if (filtered.length > 0) language = filtered[0]
  }
  return language.toLowerCase() // 修改为小写
}
function getCodeContent(codeElement) {
  // 克隆元素避免污染原始DOM
  const clonedCode = codeElement.cloneNode(true)

  // 移除所有行号元素（兼容不同插件）
  clonedCode
    .querySelectorAll('.hljs-ln-numbers, .line-numbers')
    .forEach(el => el.remove())

  // 创建临时容器保留格式
  const pre = document.createElement('pre')
  pre.appendChild(clonedCode)

  // 获取保留格式的文本内容
  return pre.textContent
    .replace(/\u00A0/g, ' ') // 转换 &nbsp; 为普通空格
    .replace(/\t/g, '    ') // 转换 tab 为4空格
    .trimEnd() // 保留末尾换行
}
export default {
  components: {
    PromoteDialog,
    BindPhone,
  },
  data() {
    return {
      question: '',
      hotWordList: [],
      chatHistory: [],
      hotLoading: true,
      isOpenVip: false, //升级会员弹窗
      msg: '',
      vip_update: '',
      allThink: false, //判断按钮是否进入loading状态
      showBackToBottom: false, // 控制回到底部按钮的显示与隐藏
      showBackToBottomTwo: false,
      lastScrollTop: 0, // 记录上一次的滚动位置
      isScrollingDown: false, // 是否正在向下滚
      chatLoading: false,
      chat_id: '', //会话ID
      question_id: '', //问题ID
      bindType: 1,
      isShowBindPhone: false,
      time: 60,
      msgid: '',
      timerA: null,
      phone_item: {
        phone: '',
        pcode: '',
      },
      isLogin: true,
      questionOld: '',
      noVip: false,
      abortController: null, // 用于取消 fetch 请求
      isStopping: false, // 停止按钮的状态
      isManuallyStopped: false, // 新增状态标识
      hasScrollbarOne: false,
      hasScrollbarTwo: false,
      // isUpdate: 0, //是否是重新生成
      aiToken: null,
      aiIndex: null,
      ws: null,
      heartbeatTimer: null,
      // 新增渲染任务队列
      renderQueue: [],
      isRendering: false,
      shouldStopRendering: false, // 新增标志位，用于控制是否停止渲染
      abortController: null,
      lastOutLine: '',
      tokenHis: false,
      isStop: false,
      autoScrollEnabled: true,    // 是否启用自动滚动
      isManualScroll: false,     // 是否手动滚动过
      scrollCheckTimeout: null,
      currentButtonState: false,
    }
  },
  directives: {
    copy: {
      inserted(el, binding, vnode) {
        const vm = vnode.context
        const blocks = el.querySelectorAll('pre')

        blocks.forEach(block => {
          const code = block.querySelector('code')
          if (!code) return

          // 创建操作栏
          const header = document.createElement('div')
          header.className = 'code-header'

          // 语言标签
          const langSpan = document.createElement('span')
          langSpan.className = 'language-tag'
          langSpan.textContent = getLanguage(code.className)
          langSpan.style.textTransform = 'none'
          // 检查 langSpan.textContent 是否有实际有效的文本内容
          if (
            langSpan.textContent &&
            langSpan.textContent.trim() !== '' &&
            langSpan.textContent != 'makefile' &&
            langSpan.textContent != 'undefined' &&
            langSpan.textContent != 'tap'
          ) {
            header.appendChild(langSpan)
          } else {
            header.className = 'code-header2'
          }

          // 复制按钮
          const copyBtn = document.createElement('button')
          copyBtn.className = 'copy-btn'
          const originalText = vm.$t('chat.Copy')
          copyBtn.textContent = originalText

          // 状态管理变量
          let isProcessing = false
          let timeoutId = null

          // 状态切换方法
          const setButtonState = (text, type = 'success', duration = 1000) => {
            if (isProcessing) return

            isProcessing = true
            copyBtn.textContent = text
            copyBtn.disabled = true

            // 清除之前的定时器
            if (timeoutId) clearTimeout(timeoutId)

            timeoutId = setTimeout(() => {
              copyBtn.textContent = originalText
              copyBtn.disabled = false
              isProcessing = false
            }, duration)
          }

          // 初始化 Clipboard
          const clipboard = new Clipboard(copyBtn, {
            text: () => getCodeContent(code),
          })

          clipboard.on('success', e => {
            vm.$message({ type: 'success', message: '复制成功' })
            setButtonState(vm.$t('chat.Copied'), 'success', 1000)
            e.clearSelection()
          })

          clipboard.on('error', e => {
            vm.$message({ type: 'error', message: '复制失败' })
            setButtonState(vm.$t('chat.CopyFailed'), 'error', 2000)
          })

          // 销毁时清理
          block._clipboard = clipboard
          header.appendChild(copyBtn)
          block.parentNode.insertBefore(header, block)
        })
      },
      unbind(el) {
        // 清理 Clipboard 实例
        el.querySelectorAll('pre').forEach(block => {
          if (block._clipboard) {
            block._clipboard.destroy()
          }
        })
      },
    },
  },
  created() {
    this.getHotWord()
    this.aiToken = localStorage.getItem('AItoken')
    if (!this.aiToken) {
      this.getAIToken()
    }
  },
  mounted() {
    // 监听滚动容器的滚动事件
    const container = this.$refs.scrollContainer
    container.addEventListener('scroll', this.handleScroll)
    // 监听添加会话事件
    EventBus.$on('addChat', value => {
      this.addChat()
    })
    // 监听点击会话事件
    EventBus.$on('chatDetail', (value, index) => {
      this.getChatDetail(value, index)
    })
    // 监听删除对话事件
    EventBus.$on('delChat', () => {
      this.chatHistory = []
      this.question_id = ''
      this.chat_id = ''
      localStorage.removeItem('chat_id')
      this.$nextTick(() => {
        this.$refs.oneInput?.focus()
      })
    })
    // 监听未登录事件
    EventBus.$on('isLogin', value => {
      this.isLogin = value
      this.chatHistory = []
      this.chat_id = ''
      this.question_id = ''
      localStorage.removeItem('chat_id')
    })
    // 监听未登录事件
    EventBus.$on('noVip', value => {
      this.noVip = value
    })
    const chatId = localStorage.getItem('chat_id')
    if (chatId) {
      this.getChatDetail(chatId)
    }
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听
    EventBus.$off('addChat')
    EventBus.$off('chatDetail')
    EventBus.$off('delChat')
    EventBus.$off('isLogin')
    EventBus.$off('noVip')
    // 在组件销毁前，移除滚动事件监听器，避免内存泄漏
    const container = this.$refs.scrollContainer
    container.removeEventListener('scroll', this.handleScroll)
    //关闭WebSocket
    if (this.ws) {
      this.ws.close()
      // console.log('销毁WebSocket')
    }
  },
  computed: {
    //判断是否还在生成中
    isAnswer() {
      // 遍历数组中的每个对象
      for (let i = 0; i < this.chatHistory.length; i++) {
        // 检查当前对象的 stop 属性是否不等于 1
        if (this.chatHistory[i].stop != 1) {
          return true
        }
      }
      return false
    },
    shouldShowButton() {
        return this.showBackToBottomTwo && this.chatHistory.length > 0
    },
    //还在生成中的chart_id
    // isChatId() {
    //   for (let i = 0; i < this.chatHistory.length; i++) {
    //     // 检查当前对象的 stop 属性是否不等于 1
    //     if (this.chatHistory[i].chat_id && this.chatHistory[i].stop == 0) {
    //       return this.chatHistory[i].chat_id
    //     }
    //   }
    // },
  },
  watch: {
    isAnswer(newValue) {
      // 当 isAnswer 的值发生变化时，通过 EventBus 发送事件
      // console.log('newValue', newValue)
      // console.log('isRenderingComplete', this.isRenderingComplete())
      // if (!newValue && !this.isRenderingComplete()) {
      //   console.log('其他标签页面已经停止')
      //   this.stopAnswer()
      // }
      EventBus.$emit('isAnswer', newValue)
    },
  },
  methods: {
    debouncedScrollCheck(distance, scrollTop) {
      const isScrollingUp = scrollTop < this.lastScrollTop
      // 立即响应向上滚动
      if (isScrollingUp) {
        clearTimeout(this.scrollCheckTimeout)
        const newState = distance < 50
        if (newState !== this.currentButtonState) {
          this.showBackToBottomTwo = newState
          this.currentButtonState = newState
        }
        return
      }

      // 向下滚动保持防抖
      clearTimeout(this.scrollCheckTimeout)
      this.scrollCheckTimeout = setTimeout(() => {
        const newState = distance < 50
        if (newState !== this.currentButtonState) {
          this.showBackToBottomTwo = newState
          this.currentButtonState = newState
        }
      }, isScrollingUp ? 0 : 100) // 向上立即执行，向下保持延迟
    },
    // 初始化WebSocket连接
    async initWebSocket(row) {
      //没有token则不连接
      if (!this.aiToken) {
        return
      }
      let url =
        this.$store.state.userInfo.push_url + `/ai?ai_token=${this.aiToken}` ||
        ''
      // if (
      //   this.ws &&
      //   this.ws.readyState != WebSocket.CLOSED &&
      //   this.ws.readyState == WebSocket.OPEN
      // ) {
      //   console.log('已有未关闭且处于打开状态的WebSocket连接，不再重新连接')
      //   return
      // }
      // 关闭现有的WebSocket连接
      // if (this.ws) {
      //   this.ws.close()
      // }

      this.ws = new WebSocket(url)

      this.ws.onopen = () => {
        // console.log('WebSocket连接成功')
        //心跳
        this.startHeartbeat()
      }
      this.ws.onmessage = message => {
        // console.log('接收到消息啦', message.data)
        this.handleMessage(JSON.parse(message.data), row)
      }
      this.ws.onerror = error => {
        // console.log('WebSocket连接错误')
        this.stopHeartbeat()
      }
      this.ws.onclose = event => {
        // console.log('WebSocket连接关闭')
        //停止心跳
        this.stopHeartbeat()
        if (this.$route.name == 'ai') {
          // 重新连接如果是token过期了不要第一时间进行续传重连，要等newchat接口拿到question_id后才可以进行续传重连
          if (!this.tokenHis) {
            this.reconnect()
          }
        }
      }
    },
    // 心跳
    startHeartbeat() {
      this.heartbeatTimer = setInterval(() => {
        if (this.ws && this.ws.readyState === WebSocket.OPEN) {
          this.ws.send('hb')
        }
      }, 10000)
    },
    // 处理消息
    handleMessage(message, row) {
      //如果点了立即停止，则不渲染message.status不为80006的数据了
      //因为后端会在点了立即停止后还给前端一部分数据，并且后面携带token,所以只取token，不渲染其他参数
      if (this.isStop) {
        if (message.status != 80006) {
          return
        }
      }
      const parsedData = message
      // 处理token过期的情况
      if (parsedData.status == 80010) {
        // console.log('WebSocket连接成功')
        if (row) {
          //续传
          // console.log('从连接成功走的续传')
          this.isScrollingDown = true
          this.keepOnChat()
        }
        return
      }
      // 处理token过期的情况
      if (parsedData.status == 80007) {
        // console.log('token过期了')
        // this.lastOutLine = 0
        this.tokenHis = true
        this.handleTokenExpiration(row)
        return
      }
      const formObj = parsedData.data?.ak
        ? decrypt(parsedData.data)
        : parsedData.data

      const {
        reasoning_content,
        content,
        think_time,
        stop,
        total_tokens,
        outLine,
        isUsage,
      } = formObj

      // const aiIndex =
      //   this.isUpdate == 1
      //     ? this.chatHistory.length - 1
      //     : this.chatHistory.findIndex(
      //         item => item.question_id == this.question_id
      //       )
      //后端说固定匹配数组最后一条数据
      const aiIndex = this.chatHistory.length - 1
      if (aiIndex === -1) return

      const currentChat = this.chatHistory[aiIndex]

      if (reasoning_content) {
        this.allThink = false
        currentChat.isThink = true
        this.addRenderTask(reasoning_content, currentChat, 'reasoningContent')
      }

      if (think_time) {
        currentChat.think_time = think_time
      }

      if (content) {
        currentChat.isThink = false
        this.addRenderTask(content, currentChat, 'answerContent')
      }

      if (stop) {
        // this.isUpdate = 0
        currentChat.stop = Number(stop)
        currentChat.total_tokens = total_tokens
        //等于0就是手动停止的
        if (Number(isUsage) === 0) {
          currentChat.isThinkStop = true
        }
        this.scrollToBottomCheck()
        EventBus.$emit('addToken', total_tokens)
        // console.log('对话结束')
      }

      if (outLine) {
        this.lastOutLine = outLine
      }
      this.tokenHis = false
      this.isStop = false
      // this.scrollToBottom()
    },

    //重新连接
    reconnect() {
      if (this.isAnswer) {
        // console.log('续传重连')
        if (!this.ws || this.ws?.readyState == WebSocket.CLOSED) {
          this.initWebSocket('keep')
        } else {
          // this.keepOnChat()
        }
      }
    },
    // 获取AItoken
    getAIToken(row) {
      getAiToken().then(res => {
        if (res.status == 1) {
          this.aiToken = res.data.token
          // console.log('获取了新token')
          //获取前判断一下有没有连接
          if (!this.ws || this.ws?.readyState == WebSocket.CLOSED) {
            this.initWebSocket()
          }
          if (row) {
            //续传
            // console.log('从AItoken走的续传')
            this.isScrollingDown = true
            this.keepOnChat()
          }
          localStorage.setItem('AItoken', res.data.token)
        }
      })
    },
    // 停止心跳
    stopHeartbeat() {
      if (this.heartbeatTimer) {
        clearInterval(this.heartbeatTimer)
        this.heartbeatTimer = null
      }
    },
    //重新获取token
    handleTokenExpiration(row) {
      localStorage.removeItem('AItoken')
      this.getAIToken(row)
    },
    handleInputOne() {
      this.$nextTick(() => {
        setTimeout(() => {
          const textarea = this.$refs.oneInput?.$el?.querySelector(
            '.el-textarea__inner'
          )
          if (!textarea) return

          // 检测是否有滚动条
          const hasScrollbar = textarea.scrollHeight > textarea.clientHeight
          this.hasScrollbarOne = hasScrollbar // 更新状态变量
        }, 100) // 延迟 100 毫秒
      })
    },
    handleInputTwo() {
      this.$nextTick(() => {
        setTimeout(() => {
          const textarea = this.$refs.twoInput?.$el?.querySelector(
            '.el-textarea__inner'
          )
          if (!textarea) return

          // 检测是否有滚动条
          const hasScrollbar = textarea.scrollHeight > textarea.clientHeight
          this.hasScrollbarTwo = hasScrollbar // 更新状态变量
        }, 100) // 延迟 100 毫秒
      })
    },
    handleScroll() {
      // 获取滚动容器的引用
      const container = this.$refs.scrollContainer
      // 计算滚动条距离底部的距离
      const distanceToBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight
      // 当距离底部超过 150 时，显示回到底部按钮
      this.showBackToBottom = distanceToBottom > 150
      // this.showBackToBottomTwo = distanceToBottom < 50
      const distance = container.scrollHeight - container.scrollTop - container.clientHeight
  
      // 传递当前scrollTop用于方向判断
      this.debouncedScrollCheck(distance, container.scrollTop)
      // 自动滚动逻辑核心
      if (distanceToBottom <= 50) {
        // 当距离底部小于50px时启用自动滚动
        this.autoScrollEnabled = true
        this.isManualScroll = false
      } else if (container.scrollTop < this.lastScrollTop) {
        // 当检测到向上滚动时，标记需要手动滚动
        this.isManualScroll = true
      }

      // 更新滚动方向判断
      this.isScrollingDown = container.scrollTop > this.lastScrollTop
      this.lastScrollTop = container.scrollTop
    },
    //滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
      const container = this.$refs.scrollContainer
      if (!container) return

      // 如果满足自动滚动条件
      if (this.autoScrollEnabled && !this.isManualScroll) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth' // 使用平滑滚动
        })
      }
    })
    },
    scrollToBottomCheck() {
      this.$nextTick(() => {
        const container = this.$refs.scrollContainer
        if (container) {
          container.scrollTop = container.scrollHeight
          this.autoScrollEnabled = true
          this.isManualScroll = false
        }
      })
    },
    toBottom() {
      this.scrollToBottomCheck()
    },
    //开启新对话
    addChat() {
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      // if (this.isAnswer) {
      //   this.$toast('回答输出中，请稍后操作或点击停止生成', 2)
      //   return
      // }
      //断开连接
      if (this.ws) {
        this.ws.close()
      }
      this.chatHistory = []
      this.chat_id = ''
      this.question_id = ''
      this.question = ''
      this.autoScrollEnabled = true   // 恢复自动滚动
      this.isManualScroll = false     // 重置手动滚动标记
      localStorage.removeItem('chat_id')
      EventBus.$emit('delHover', true)
      this.$nextTick(() => {
        this.$refs.oneInput?.focus()
      })
    },
    //发送按钮
    sendInfo(item) {
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      this.question = item.content
      this.sendChat()
    },
    //回车事件
    handleEnter(event) {
      if (event.key === 'Enter' && !event.ctrlKey) {
        event.preventDefault(); // 阻止默认的换行行为
      }
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      if (this.isAnswer) {
        this.scrollToBottomCheck()
        this.$toast('回答输出中，请稍后操作或点击停止生成', 2)
        return
      }
      if (this.question.trim().length == 0) {
          return
      }
      // 调用滚动到容器底部的方法
      this.chatHistory.forEach(item => {
        item.isEdit = false
      })
      this.question_id = ''
      this.isScrollingDown = true
      this.sendChat()
    },
    //获取会话详情
    async getChatDetail(value, index, idx) {
      if (value == this.chat_id && !index) {
        return
      }
      if (!idx) {
        this.chatLoading = true
      }
      this.question = ''
      this.chat_id = value
      let res = await chatDetails({
        chat_id: value,
      })
      if (res.status == '1') {
        let arr = res.data.list
        let is_keep_on = res.data.is_keep_on
        if (arr.length > 0) {
          this.chatHistory = arr.map(item => ({
            chat_id: value,
            question: item?.[0].question,
            questionOld: item?.[0].question,
            reasoningContent: item?.[0].answer.reasoning_content,
            answerContent: item?.[0].answer.content,
            think_time: item?.[0].answer.think_time,
            isThink:
              item?.[0].answer.settlement_status == 0 &&
              item?.[0].answer.think_time == 0
                ? true
                : false,
            isThinkStop:
              (item?.[0].answer.reasoning_content != '' &&
                item?.[0].answer.content == '' &&
                item?.[0].answer.settlement_status > 0) ||
              idx
                ? true
                : false,
            stop: item?.[0].answer.settlement_status == 0 ? 0 : 1,
            isVip: 'no',
            isEdit: false,
            isRotated: false,
            total_tokens: item?.[0].answer.total_tokens || '',
            question_id: item?.[0].answer.question_id,
            showRight: true,
            outLine: item?.[0].answer.outLine,
          }))
          //断开连接
          if (this.ws) {
            this.ws.close()
          }
          if (is_keep_on == 1) {
            const lastChat = this.chatHistory[this.chatHistory.length - 1]
            if (lastChat) {
              this.chatLoading = false
              const { question_id, outLine } = lastChat
              this.question_id = question_id
              this.lastOutLine = outLine
              await this.initWebSocket('keep')
            }
          }
          localStorage.setItem('chat_id', value)
        } else {
          this.chatHistory = []
          this.chat_id = ''
          this.question_id = ''
          localStorage.removeItem('chat_id')
        }
        this.scrollToBottomCheck()
        this.chatLoading = false
      }
    },
    inputSend() {
      this.question_id = ''
      this.sendChat()
    },
    addRenderTask(text, chat, property) {
      this.renderQueue.push({ text, chat, property })
      if (!this.isRendering) {
        this.processRenderQueue()
      }
    },
    async processRenderQueue() {
      this.isRendering = true
      while (this.renderQueue.length > 0) {
        const { text, chat, property } = this.renderQueue.shift()
        this.abortController = new AbortController()
        try {
          await this.smoothRender(
            text,
            chat,
            property,
            this.abortController.signal
          )
          this.scrollToBottom()
        } catch (error) {}
      }

      // 重置状态
      this.isRendering = false
      this.abortController = null
    },
    // 检查渲染是否完成
    isRenderingComplete() {
      return !this.isRendering // 如果没有渲染任务，返回 true
    },
    // 逐字快速渲染
    smoothRender(content, chat, property, signal) {
      return new Promise(resolve => {
        let index = 0
        let isStopped = false

        // 监听中止信号
        if (signal) {
          signal.addEventListener('abort', () => {
            isStopped = true
            resolve()
          })
        }

        const updateText = newText => {
          return new Promise(res => {
            this.$set(chat, property, newText) // 使用 Vue.set 确保响应式更新
            this.$nextTick(() => {
              res()
            })
          })
        }

        const renderNextChar = async () => {
          if (!isStopped && index < content.length) {
            const newText = chat[property] + content[index]
            index++
            await updateText(newText)

            // 使用 requestAnimationFrame 实现逐字渲染
            requestAnimationFrame(renderNextChar)
          } else {
            resolve()
          }
        }

        renderNextChar()

        // 暴露停止方法
        this.stopSmoothRender = () => {
          isStopped = true
          resolve()
        }
      })
    },
    // 多标签同步渲染
    // smoothRender(content, chat, property, signal) {
    //   return new Promise(resolve => {
    //     let index = 0
    //     let isStopped = false

    //     // 监听页面可见性变化
    //     const handleVisibilityChange = () => {
    //       if (document.visibilityState !== 'visible' && !isStopped) {
    //         isStopped = true
    //         const remainingText = content.slice(index)
    //         const newText = chat[property] + remainingText
    //         this.$set(chat, property, newText)
    //         this.$nextTick(() => {
    //           document.removeEventListener(
    //             'visibilitychange',
    //             handleVisibilityChange
    //           )
    //           resolve()
    //         })
    //       }
    //     }

    //     document.addEventListener('visibilitychange', handleVisibilityChange)

    //     // 监听中止信号
    //     if (signal) {
    //       signal.addEventListener('abort', () => {
    //         isStopped = true
    //         document.removeEventListener(
    //           'visibilitychange',
    //           handleVisibilityChange
    //         )
    //         resolve()
    //       })
    //     }

    //     const updateText = newText => {
    //       return new Promise(res => {
    //         this.$set(chat, property, newText)
    //         this.$nextTick(() => {
    //           res()
    //         })
    //       })
    //     }

    //     const renderNextChar = async () => {
    //       if (isStopped || index >= content.length) {
    //         document.removeEventListener(
    //           'visibilitychange',
    //           handleVisibilityChange
    //         )
    //         resolve()
    //         return
    //       }

    //       // 如果页面不可见，立即完成渲染
    //       if (document.visibilityState !== 'visible') {
    //         handleVisibilityChange()
    //         return
    //       }

    //       const newText = chat[property] + content[index]
    //       index++
    //       await updateText(newText)

    //       // 使用 setTimeout 继续渲染
    //       setTimeout(renderNextChar, 10)
    //     }

    //     renderNextChar()

    //     // 暴露停止方法
    //     this.stopSmoothRender = () => {
    //       isStopped = true
    //       const remainingText = content.slice(index)
    //       const newText = chat[property] + remainingText
    //       this.$set(chat, property, newText)
    //       document.removeEventListener(
    //         'visibilitychange',
    //         handleVisibilityChange
    //       )
    //       resolve()
    //     }
    //   })
    // },

    // 平滑逐字渲染
    // smoothRender(content, chat, property, signal) {
    //   return new Promise(resolve => {
    //     let index = 0
    //     let isStopped = false

    //     // 监听中止信号
    //     if (signal) {
    //       signal.addEventListener('abort', () => {
    //         isStopped = true
    //         resolve()
    //       })
    //     }

    //     const updateText = newText => {
    //       return new Promise(res => {
    //         this.$set(chat, property, newText) // 使用 Vue.set 确保响应式更新
    //         this.$nextTick(() => {
    //           res()
    //         })
    //       })
    //     }

    //     const renderNextChar = async () => {
    //       if (!isStopped && index < content.length) {
    //         const newText = chat[property] + content[index]
    //         index++
    //         await updateText(newText)

    //         // 动态调整渲染速度
    //         const bufferSize = this.renderQueue.length
    //         let delay = 0 // 默认延迟
    //         if (bufferSize > 10) {
    //           delay = 0 // 缓冲队列较长，加快渲染
    //         } else if (bufferSize < 3) {
    //           delay = 30 // 缓冲队列较短，减慢渲染
    //         }

    //         setTimeout(() => {
    //           requestAnimationFrame(renderNextChar)
    //         }, delay)
    //       } else {
    //         resolve()
    //       }
    //     }

    //     renderNextChar()

    //     // 暴露停止方法
    //     this.stopSmoothRender = () => {
    //       isStopped = true
    //       resolve()
    //     }
    //   })
    // },
    // 分段快速渲染
    // smoothRender(content, chat, property) {
    //     return new Promise(resolve => {
    //         let index = 0;
    //         let isStopped = false;
    //         // 根据内容长度动态调整批量大小
    //         const batchSize = content.length > 50 ? 5 : 3;
    //         let delay = 50;

    //         const updateText = newText => {
    //             return new Promise(res => {
    //                 this.$set(chat, property, newText);
    //                 this.$nextTick(() => {
    //                     res();
    //                 });
    //             });
    //         };

    //         const renderNextBatch = async () => {
    //             if (!isStopped && index < content.length) {
    //                 let batchText = '';
    //                 for (let i = 0; i < batchSize && index < content.length; i++) {
    //                     batchText += content[index];
    //                     index++;
    //                 }
    //                 const newText = chat[property] + batchText;
    //                 await updateText(newText);
    //                 // 使用 requestAnimationFrame 替代 setTimeout
    //                 requestAnimationFrame(renderNextBatch);
    //             } else {
    //                 resolve();
    //             }
    //         };

    //         renderNextBatch();

    //         this.stopSmoothRender = () => {
    //             isStopped = true;
    //             resolve();
    //         };
    //     });
    // },
    // 逐字渲染
    // smoothRender(content, chat, property, signal, delay = 45) {
    //   return new Promise((resolve, reject) => {
    //     let index = 0

    //     const render = () => {
    //       if (signal.aborted) {
    //         reject(new DOMException('渲染任务已取消', 'AbortError')) // 任务被取消
    //         return
    //       }

    //       if (index < content.length) {
    //         // 使用 this.$set 确保响应式更新
    //         this.$set(chat, property, chat[property] + content[index])
    //         index++
    //         setTimeout(render, delay) // 继续渲染
    //       } else {
    //         resolve() // 渲染完成
    //       }
    //     }

    //     render()

    //     // 暴露一个方法，允许外部停止渲染
    //     this.stopSmoothRender = () => {
    //       if (this.abortController) {
    //         this.abortController.abort() // 取消当前渲染任务
    //       }
    //       resolve() // 确保 Promise 被 resolve
    //     }
    //   })
    // },

    sendChat: _.debounce(
      async function (row) {
        if (!this.isLogin) {
          this.$router.push({ path: '/login?path=ai' })
          return
        }
        if (this.question.trim().length == 0 && !row) {
          return
        }
        if (this.checkPhoneEmpty()) {
          this.bindType = 1
          this.isShowBindPhone = true
          return
        }
        //发送前判断一下有没有连接
        if (!this.ws || this.ws?.readyState == WebSocket.CLOSED) {
          this.initWebSocket()
        }
        if (this.isAnswer) {
          this.scrollToBottomCheck()
          this.$toast('回答输出中，请稍后操作或点击停止生成', 2)
          return
        }
        //关闭所有编辑状态
        this.chatHistory.forEach(item => {
          item.isEdit = false
        })
        let id = this.chat_id
        this.allThink = true
        const newChat = {
          questionOld: this.question,
          chat_id: this.chat_id,
          question_id: this.question_id,
          reasoningContent: '',
          answerContent: '',
          think_time: '',
          isThink: false,
          isThinkStop: false,
          stop: 0,
          isVip: 'no',
          isEdit: false,
          isRotated: false,
          total_tokens: '',
          showRight: true,
          outLine: '',
        }
        this.chatHistory.push(newChat)
        this.question = ''
        this.$nextTick(() => {
          this.$refs.twoInput?.focus()
          this.scrollToBottomCheck()
        })
        if (row) {
          //走的编辑
          newChat.questionOld = row
        }
        if (this.noVip) {
          //不是会员
          newChat.isVip = 'yes' //提示要开通会员
          newChat.stop = 1
          this.msg = '请开通会员体验 Feemoo AI智能对话' //请开通会员体验 Feemoo AI智能对话
          this.vip_update = 2 //开通会员
          this.isOpenVip = true
          newChat.question = newChat.questionOld
          this.question = ''
          this.$nextTick(() => {
            this.$refs.twoInput?.focus()
            this.scrollToBottomCheck()
          })
          return
        }
        let data = {
          question: newChat.questionOld,
          chat_id: this.chat_id,
          question_id: this.question_id,
          token: this.aiToken,
        }
        newChat.question = newChat.questionOld
        try {
          let res = await sendChat(data)
          // console.log('对话接口返回', res)
          if (res.status == 1) {
            const { chat_id, question_id } = res.data
            newChat.question_id = question_id
            this.question_id = question_id
            newChat.chat_id = chat_id
            this.chat_id = chat_id
            localStorage.setItem('chat_id', chat_id)
            if (id == '') {
              EventBus.$emit('addChatList', true)
            }
            //token过期
            if (this.tokenHis) {
              this.reconnect()
            }
          } else if (res.status == 63200) {
            newChat.isVip = 'yes' //提示要开通会员
            this.msg = res.msg //请开通会员体验 Feemoo AI智能对话
            this.vip_update = 2 //开通会员
            this.isOpenVip = true
            newChat.stop = 1
            this.allThink = false
          } else if (res.status == 63202) {
            newChat.isVip = 'yes' //提示要升级会员
            this.msg = res.msg //智能AI对话Token已用尽，请升级会员获得更多Token
            this.vip_update = 1
            this.isOpenVip = true
            newChat.stop = 1
            this.allThink = false
          } else if (res.status == 80000) {
            newChat.isVip = 'yesNop' //提示额度已经用尽
            this.msg = res.msg //智能AI对话Token已用尽，暂无更多Token
            this.vip_update = 0 //知道啦
            this.isOpenVip = true
            newChat.stop = 1
            this.allThink = false
          } else if (res.status == 40001) {
            //绑定手机号
            this.bindType = 1
            this.isShowBindPhone = true
            newChat.stop = 1
            this.allThink = false
          } else if (res.status == 80004) {
            //已经有对话了
            newChat.stop = 1
            this.allThink = false
            EventBus.$emit('haveChat', res.data.chat_id)
            this.$toast(res.msg, 2)
          } else if (res.status == 80003) {
            this.msg = res.msg //所有错误提示语，动态的
            newChat.isVip = 'error' //错误繁忙等提示语
            newChat.stop = 1
            this.allThink = false
          } else if (res.status == 0) {
            newChat.stop = 1
            this.allThink = false
            newChat.showRight = false
            this.$toast(res.msg, 2)
          }
        } catch (error) {
          // console.log('error', error)
        }
      },
      500,
      {
        leading: true,
        trailing: false,
      }
    ),
    async keepOnChat() {
      let data = {
        question_id: this.question_id,
        out_line: this.lastOutLine == ''?0:this.lastOutLine, // 传递最新的 outLine
        token: this.aiToken,
      }
      // console.log('进入续传', data)
      try {
        let res = await keepChat(data)
        // console.log('续传接口返回', res)
      } catch (error) {}
    },
    //停止生成回答
    stopAnswer: _.debounce(
      async function () {
        if (!this.isLogin) {
          this.$router.push({ path: '/login?path=ai' })
          return
        }
        if (this.checkPhoneEmpty()) {
          this.bindType = 1
          this.isShowBindPhone = true
          return
        }
        try {
          let res = await stopChat({
            chat_id: this.chat_id,
          })
          if (res.status == 1) {
            this.isStop = true
            // console.log('chatHistory',this.chatHistory)
            this.chatHistory.forEach(item => {
              if (item.chat_id == this.chat_id && item.stop != 1) {
                // console.log('全部停止了')
                item.isThinkStop = true
                item.stop = 1
              }
            })
            if (this.stopSmoothRender) {
              this.stopSmoothRender() // 调用停止渲染的方法
            }
            this.shouldStopRendering = true // 设置标志位，停止处理队列
            this.renderQueue = [] // 清空渲染队列
          } else if (res.status == 0) {
            this.$toast(res.msg, 2)
          }
        } catch {}
      },
      500,
      {
        leading: true,
        trailing: false,
      }
    ),
    //升级会员
    openVip() {
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      window.open('/vip', '_blank')
    },
    //收起思考
    retract(item, index) {
      this.chatHistory[index].isRotated = !this.chatHistory[index].isRotated
    },
    //获取热门搜索词
    getHotWord() {
      this.hotLoading = true
      getHot()
        .then(res => {
          if (res.status == 1) {
            this.hotWordList = res.data
            this.hotLoading = false
          }
        })
        .catch(error => {})
    },
    //编辑
    editQuestion(item, index) {
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      if (this.isAnswer) {
        this.$toast('回答输出中，请稍后操作或点击停止生成', 2)
        return
      }
      this.chatHistory[index].isEdit = true
      this.$nextTick(() => {
        this.$refs['threeInput' + index][0]?.focus()
      })
    },
    //取消编辑
    editClose(item, index) {
      // this.chatHistory[index].question = item.questionOld
      this.chatHistory[index].questionOld = item.question
      this.chatHistory[index].isEdit = false
    },
    //发送编辑
    sendEdit(event, item, index) {
      if (event.key === 'Enter' && !event.ctrlKey) {
        event.preventDefault(); // 阻止默认的换行行为
      }
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      if (item.questionOld.trim().length == 0) {
        return
      }
      // this.question = item.questionOld
      this.question_id = ''
      this.chatHistory[index].isEdit = false
      this.sendChat(item.questionOld)
    },
    //重新生成
    sendUpdate(item, index) {
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      // this.question = item.question
      this.question_id = item.question_id
      // this.isUpdate = 1
      this.sendChat(item.question)
    },
    //复制问题
    copy(row, item, num) {
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      //row是复制内容主题
      //itm.isVip是判断是否要提示开通会员
      //num 1 为问题，2 为回答
      let text
      if (
        (item.isVip == 'yes' ||
          item.isVip == 'yesNop' ||
          item.isVip == 'error') &&
        num == 2
      ) {
        if (item.isVip == 'yes') {
          text = this.$t('chat.textEight') + this.$t('chat.toVip')
        } else if (item.isVip == 'yesNop') {
          text = this.$t('chat.textEight')
        } else {
          text = this.msg
        }
      } else {
        if (!row) {
          this.$toast('暂无内容可复制', 2)
          return
        }
        text = row
      }
      this.$copyText(text).then(
        () => {
          this.$toast('复制成功', 1)
        },
        () => {
          this.$toast('复制失败', 2)
        }
      )
    },
    //意见反馈
    feedback() {
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      window.open('https://jsj.top/f/fGuzqA', '_blank')
    },
    //判断手机号是否为空
    checkPhoneEmpty() {
      // 从 store 中获取 userInfo 对象
      const userInfo = this.$store.state.userInfo
      // 检查 phone 属性是否为空字符串
      if (userInfo && userInfo.phone == '') {
        return true
      }
      return false
    },
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      }
      this.getPhoneAly()
    },
    getPhoneAly(captcha_verify) {
      return new Promise(resolve => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || '',
        }).then(res => {
          if (res.status == '1') {
            resolve({
              result: true,
              validate: true,
            })
            this.$toast(res.msg, 1)
            this.msgid = res.data.msgid
            this.bindType = 3
            this.timerA = setInterval(() => {
              this.minute()
              this.$refs.bindPhoneCom?.onFocus()
            }, 1000)
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            })
            this.$toast(res.msg, 2)
            this.$AliCode.handleOpen(this.getPhoneAly)
          } else {
            resolve({
              result: true,
              validate: true,
            })
            this.$toast(res.msg, 2)
          }
        })
      })
    },
    minute() {
      this.time = this.time - 1
      if (this.time == 0) {
        this.time = 60
        clearInterval(this.timerA)
      }
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data }
      bindPhoneAPI(params).then(res => {
        if (res.status == '1') {
          this.isShowBindPhone = false
          this.$store.commit('getuserVuex')
          this.$toast(res.msg, 1)

          if (this.$store.state.bindPhoneCallback) {
            this.$store.state.bindPhoneCallback()
          }
        } else {
          this.$toast(res.msg, 2)
        }
      })
    },
    realNameAuth(data) {
      realNameAuth(data).then(res => {
        if (res.status == '1') {
          this.isShowBindPhone = false
          this.$store.commit('getuserVuex')
          this.$toast(res.msg, 1)
        } else {
          this.$toast(res.msg, 2)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
<style lang="scss">
.deepseek-stop {
  background: #2c2c2c;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 5px 8px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  margin-top: 4px !important;
  .popper__arrow {
    display: none;
  }
}
.code-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #50505a;
  padding: 8px 12px;
  border-radius: 12px 12px 0 0;
}
.code-header2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #50505a;
  padding: 8px 12px;
  border-radius: 12px 12px 0 0;
}
.language-tag {
  font-size: 0.9em;
  color: #ffffff;
  text-transform: uppercase;
}

.copy-btn {
  transition: all 0.3s;
  background: transparent;
  cursor: pointer;
  border: none;
  color: #ffffff;
}
.copy-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  /* 根据状态改变颜色 */
  &[data-state='success'] {
    color: #67c23a;
  }
  &[data-state='error'] {
    color: #f56c6c;
  }
}
// 新对话
.chatRightNot {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0 !important;
  flex-direction: column;
}
/* 行号列样式 */
.hljs-ln-numbers {
  padding-right: 12px !important;
  border-right: 1px solid #ddd !important;
  text-align: right;
  color: #888;
}

/* 代码列样式 */
.hljs-ln-code {
  padding-left: 12px !important;
}

/* 表格容器 */
.hljs-ln {
  border-collapse: collapse;
  width: 100%;
}
/* 代码块整体样式 */
.chatRight pre {
  // position: relative;
  border-radius: 0 0 12px 12px;
  overflow-x: auto;
}
.chatRight ul,
.chatRight ol,
.chatRight li {
  list-style-position: inside;
}
.chatRight .content * {
  // font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  @include useTheme {
    color: getVar('text-color2-2C2C2C');
  }
}
</style>
