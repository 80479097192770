import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";

var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥

export function decrypt(data) {
  if (data?.ak) {
    let plaintext = utils.rd(data.ak, unescape(sfile));
    let obj = utils.secret(data.ed, plaintext, true);
    return JSON.parse(obj);
  } else {
    return data;
  }
}

export function inParameter(data) {
  if (data) {
    return { sn: ed, jt: utils.secret(data, ak)};
  } else {
    return data;
  }
}

//发送消息获取回答
export function sendChat(data) {
  return request({
    url: "/ai-service/chat/newChatV2",
    method: "post",
    data: inParameter(data),
    })
    .then((res) => {
      res.data = decrypt(res.data)
      return res
    })
    .catch((err) => {});
}
//续传接口
export function keepChat(data) {
  return request({
    url: "/ai-service/chat/keepOnDataV2",
    method: "post",
    data: inParameter(data),
    })
    .then((res) => {
      res.data = decrypt(res.data)
      return res
    })
    .catch((err) => {});
}
//获取对话列表
export function getChatList(data) {
  return request({
    url: "/ai-service/chat/chatList",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//获取用户token用量
export function getTokenNum(data) {
  return request({
    url: "/ai-service/chat/getUserToken",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//删除对话
export function deleteChat(data) {
  return request({
    url: "/ai-service/chat/delChat",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//重命名
export function editChat(data) {
  return request({
    url: "/ai-service/chat/editChat",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//热门搜索词
export function getHot(data) {
  return request({
    url: "/ai-service/chat/hotWord",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//停止AI回答
export function stopChat(data) {
  return request({
    url: "/ai-service/chat/stopAnswer",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//会话详情
export function chatDetails(data) {
  return request({
    url: "/ai-service/chat/chatInfo",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}

//续传详情
export function chatContinued(data) {
  return request({
    url: "/ai-service/chat/chatInfo",
    method: "post",
    data: inParameter(data),
  }).then((res)=>{
    res.data = decrypt(res.data)
    return res
  }).catch((err) => {});
}
//续传详情
export function getSwitchs() {
  return request({
    url: "/ai-service/chat/getSwitchs",
    method: "post",
  }).then((res)=>{
    return res
  }).catch((err) => {});
}
//获取推送token
export function getAiToken() {
  return request({
    url: "/ai-service/chat/getPushToken",
    method: "post",
  }).then((res)=>{
    return res
  }).catch((err) => {});
}
