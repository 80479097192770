<template>
  <div class="tooltipBox" :style="{ top: getTopStyle, left: getLeftStyle }" @contextmenu.prevent>
    <div v-if="isVisible" class="Box">
      <!-- 箭头 -->
      <div class="tooltipArrow"></div>
      <div class="content">
        <span class="font">{{ $t('chat.textSix') }}</span>
        <div class="rightBox" @click="closeTooltip">
          <svg-btn class="icon" icon-class="icon-deepseek-yes" />
          <span class="font2">{{ $t('chat.FreeTrial') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSwitchs } from '@/utils/apiList/chat'
export default {
  name: 'CustomTooltip',
  props: {
    top: {
      type: Number,
      default: -1000,
      validator: value => {
        if (typeof value !== 'number') {
          return false
        }
        return true
      },
    },
    left: {
      type: Number,
      default: -1000,
      validator: value => {
        if (typeof value !== 'number') {
          return false
        }
        return true
      },
    },
  },
  data() {
    return {
      isVisible: false,
    }
  },
  computed: {
    getTopStyle() {
      return `${this.top}px`
    },
    getLeftStyle() {
      return `${this.left}px`
    },
  },
  mounted() {
    this.getSwitch()
  },
  methods: {
    getSwitch() {
      getSwitchs().then(res => {
        const isTooltip = localStorage.getItem('isTooltip')
        if (
          res.data.switchs.ai_chat_switch == 1 &&
          res.data.switchs.ai_new_prompt_switch == 1
        ) {
          if (isTooltip == 2 || isTooltip == null) {
            this.isVisible = true
            localStorage.setItem('isTooltip', 2)
          } else {
            this.isVisible = false
            localStorage.setItem('isTooltip', 3)
          }
        } else {
          this.isVisible = false
          localStorage.setItem('isTooltip', 3)
        }
      })
    },
    closeTooltip() {
      // 关闭提示框的逻辑
      this.isVisible = false
      localStorage.setItem('isTooltip', 3)
      // this.$router.push({ path: '/ai' })
      window.open('/ai', '_href')
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
