<!-- CustomContextMenu.vue -->
<template>
  <div
    v-if="visible"
    ref="menuContainer"
    class="custom-menu"
    :style="{ left: x + 'px', top: y + 'px' }"
    @click.stop
    @blur="handleBlur"
    tabindex="0"
    @contextmenu.prevent.stop
  >
    <div
      v-for="item in selectedItem"
      :key="item.id"
      class="menu-item"
      @click="handleAction(item.id)"
    >
      {{ $t(item.name) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
    selectedItem: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    // 仅在菜单可见时监听全局右键事件
    visible(newVal) {
      if (newVal) {
        // 当菜单显示时，让菜单容器获得焦点
        this.$nextTick(() => {
          if (this.$refs.menuContainer) {
            this.$refs.menuContainer.focus()
          }
        })
      }
    },
  },
  methods: {
    handleBlur(event) {
      // 失焦时关闭菜单
      this.$emit('close')
    },
    handleAction(action) {
      this.$emit('action', action)
      this.$emit('close')
    },
    getMenuSize() {
      if (this.$refs.menuContainer) {
        return {
          width: this.$refs.menuContainer.offsetWidth,
          height: this.$refs.menuContainer.offsetHeight,
        }
      }
      return { width: 162, height: 0 } // 默认值
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-menu {
  position: fixed;
  width: 162px;
  height: auto;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  z-index: 1000;
  padding: 8px 6px;
  outline: none;
  z-index: 3000;
  @include useTheme {
    background: getVar('text-color3-ffffff');
    border-color: getVar('text-color1-e5e5e5');
    border: getVar('border-color-e5e5e5');
  }

  .menu-item {
    padding: 7px 10px;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 2px;
    font-size: 13px;
    width: 150px;
    height: 32px;
    @include useTheme {
      color: getVar('text-color2-2c2c2c');
    }
    &:hover {
      @include useTheme {
        background: getVar('text-color1-f6f6f6');
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
    font-size: 13px;
  }
}
</style>
