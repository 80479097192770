<template>
  <el-dialog
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="PromoteDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left"></div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <img :src="leadSrc" class="imgLogin"/>
      </div>
      <div class="d-footer">
        <fm-button type="primary" round @click="goLogin">立即登录/注册</fm-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() { return {}},
  computed: {
    leadSrc() {
      return this.$store.state.themeBtn !== 'light' 
        ? this.$utils.getPng('icon-down-leadLogin-black-new') 
        : this.$utils.getPng('icon-down-leadLogin-new');
    }
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    goLogin() {
      this.$emit("handleClose");
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
    },
  },
};
</script>

<style lang="scss" scoped>
.PromoteDialog {
  width: 480px;
  .d-content{
    margin-top: 0 !important;
    .imgLogin{
      width: 100%;
      height: auto;
      margin-bottom: 0;
    }
  }
  .d-footer {
    .fm-button {
      width: 200px !important;
    }
  }
}
::v-deep .el-dialog__body{
    padding: 64px 32px 32px !important;
  }
</style>
