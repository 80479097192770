<template>
  <el-dialog
    title="提示"
    :visible.sync="isShowModel"
    :append-to-body="true"
    :close-on-click-modal="false"
    class="limitDialog"
  >
    <div class="limitedTimeModel2">
      <div class="three-content">
        <img class="threedayVip" :src="$utils.getPng('threedayVip2')" alt="" />
        <div class="header">
          <span>扫码开通 享每日10次下载额度</span>
        </div>
        <div class="switching-box">
          <div
            class="switching-item"
            :class="selectType == 1 ? 'payment' : ''"
            @click="select(1)"
          >
            <img
              class="payment-icon"
              :src="$utils.getPng('icon_pay_1')"
              alt=""
            />
            <span>支付宝</span>
          </div>
          <div
            class="switching-item"
            :class="selectType == 2 ? 'payment' : ''"
            @click="select(2)"
          >
            <img
              class="payment-icon"
              :src="$utils.getPng('icon_pay_2_1')"
              alt=""
            />
            <span>微信支付</span>
          </div>
        </div>
        <div class="QRcode-box">
          <div class="QR-box">
            <img
              class="threeyuan-icon"
              :src="$utils.getPng('threeyuan')"
              alt=""
            />
            <div class="QRcode-item">
              <div class="code" v-loading="ewmloading">
                <div
                  v-show="selectType == 1"
                  class="pay_status"
                  id="limitedTimeCode2"
                ></div>
                <div
                  v-show="selectType == 2"
                  class="pay_status"
                  id="limitedTimeCode3"
                ></div>
                <img
                  v-show="selectType == 1"
                  class="alipay_icon"
                  :src="$utils.getPng('alipay_icon')"
                />
                <img
                  v-show="selectType == 2"
                  class="alipay_icon"
                  :src="$utils.getPng('pay_icon')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="end">
          已经阅读并同意<span @click="goAgree(1)">《飞猫盘会员服务协议》</span
          >和<span @click="goAgree(2)">《会员活动规则》</span>
        </div>
      </div>
      <img
        class="icon_close2"
        @click="handleClose()"
        :src="$utils.getPng('icon_wclose')"
        alt=""
      />
      <div class="end-text">- 到期后会员权益将失效 -</div>
    </div>
  </el-dialog>
</template>

<script>
import { generatePaySignAPI, getOrderStateAPI } from "@/utils/apiList/vip.js";

export default {
  props: {
    isShowModel: Boolean,
    loading: Boolean,
    isAbDown: {
      default: "B",
      type: "string",
    },
    text1: {
      default: "到期前将以￥25/月自动续费会员连续包月，可随时取消",
      type: "string",
    },
    activity_vip_id: {
      default: "1055",
      type: "string",
    },
  },
  data() {
    return {
      selectType: 1,
      order_status: 0,
      coupndataLast: {},
      orderInfo1: {},
      orderInfo2: {},
      order_status: 2,
      ewmloading: true,
      payTimer2: null,
      payTimer1: null,
    };
  },
  watch: {
    isShowModel: {
      handler(val) {
        if (val) {
          this.selectType = 1;
          this.newPay(this.activity_vip_id, this.selectType);
        } else {
          this.orderInfo2 = {};
          this.orderInfo1 = {};
          clearInterval(this.payTimer2);
          clearInterval(this.payTimer1);
        }
      },
    },
  },
  methods: {
    select(type) {
      this.selectType = type; //type == 1，orderInfo1 支付宝｜｜｜｜type == 2，orderInfo2  微信
      if (type == 1) {
        if (this.orderInfo1?.out_trade_no) {
          return;
        }
      } else {
        if (this.orderInfo2?.out_trade_no) {
          return;
        }
      }
      this.newPay(this.activity_vip_id, this.selectType);
    },
    handleClose() {
      this.$emit("handleClose");
    },
    goAgree(type) {
      if (type === 1) {
        window.open(`${this.$glb.fmUrl}/askdetail/124`);
      } else if (type === 2) {
        window.open(`${this.$glb.fmUrl}/askdetail/96`);
      } 
    },
    newPay: _.debounce(
      function (pay_id, selectType, coupndata = {}, scene = "") {
        this.$parent.animation = "";
        this.coupndataLast = coupndata; //将本次带的优惠码存储在coupndataLast,切换套餐时需要保留优惠只有在切换套餐至连续包月和花呗时清空coupndataLast
        this.ewmloading = true;
        generatePaySignAPI({
          pay_id: pay_id,
          pay_type: selectType == 1 ? "alipay" : "wxpay",
          coupon: coupndata.coupon ? coupndata.coupon : "",
        })
          .then((res) => {
            if (this.selectType == 1) {
              this.orderInfo1 = res.data;
            } else {
              this.orderInfo2 = res.data;
            }
            this.createCode(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //生成支付二维码
    createCode: _.debounce(
      function (code) {
        this.ewmloading = false;
        // 1.创建二维码对象。qrcode(二维码类型（1~40，输入 0 以自动检测）,容错级别（L、M、Q、H）)
        var qr = qrcode(0, "L");
        // 2.添加二维码信息。
        qr.addData(code.qrcode);
        // 3.生成二维码对象（并不显示）。
        qr.make();
        // createImgTag(cellSize, margin, alt); cellSize 像素宽度,margin补白像素宽度
        this.$nextTick(() => {
          if (this.selectType == 2) {
            document.getElementById("limitedTimeCode3").innerHTML =
              qr.createImgTag(4, 0);
            clearInterval(this.payTimer2);
            this.payTimer2 = setInterval(() => {
              this.checkOrder(code);
            }, 4000);
          } else {
            document.getElementById("limitedTimeCode2").innerHTML =
              qr.createImgTag(4, 0);
            clearInterval(this.payTimer1);
            this.payTimer1 = setInterval(() => {
              this.checkOrder(code);
            }, 4000);
          }
        });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    checkOrder: _.debounce(
      function (code) {
        //除了 wxpay 全部是 alipay
        let order = {
          order_id: code.out_trade_no,
          pay_type: code.pay_type,
          pay_id: this.pay_id,
        };
        getOrderStateAPI(order)
          .then((res) => {
            this.order_status = res.status;
            if (res.status == 2) {
              clearInterval(this.payTimer2);
              clearInterval(this.payTimer1);
              this.orderInfo2 = {};
              this.orderInfo1 = {};
              this.newPay(this.activity_vip_id, this.selectType);
            }
            if (res.status == 1) {
              this.$emit("openSuccess", res.data.pay_toast);
              this.$emit("handleClose");
              clearInterval(this.payTimer2);
              clearInterval(this.payTimer1);
            }
            if(res.status == 1012) {
              clearInterval(this.payTimer2);
              clearInterval(this.payTimer1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    refresh() {},
  },
};
</script>

<style lang="scss">
// 1元3天活动弹框
.limitDialog {
  z-index: 5000;

  // background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  .el-dialog {
    /* background: rgba(0, 0, 0, .2) !important; */
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
    backdrop-filter: none;
    box-shadow: none;
    width: 480px;
    height: 383px;
    padding: 0;
  }

  :v-deep .el-dialog__body {
    width: 480px;
    height: 383px;
    padding: 0;
    background: transparent !important;
    margin: 0;
  }
}
.limitedTimeModel2 {
  width: 432px;
  box-shadow: none;
  background: transparent;
  position: relative;
  .end-text {
    height: 14px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    line-height: 14px;
    margin-top: 20px;
    text-align: center;
  }
  .three-content {
    width: 428px;
    height: 383px;
    box-shadow: 0px 2px 60px 0px rgba(255, 243, 237, 0.73);
    box-sizing: border-box;
    border-radius: 54px;
    border: 4px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(180deg, #f3d8c0, #fff7f7),
      linear-gradient(180deg, rgba(254, 252, 231, 1), rgba(255, 255, 255, 1));
    .threedayVip {
      width: 432px;
      height: 152px;
      position: absolute;
      top: -120px;
    }
    .header {
      height: 24px;
      font-size: 16px;
      font-weight: 400;
      color: #88423e;
      line-height: 24px;
      text-align: center;
      margin-top: 37px;
      margin-bottom: 17px;
    }
    .switching-box {
      width: 268px;
      height: 36px;
      background-color: rgba(255, 255, 255, 0.48);
      border-radius: 9px;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      .switching-item {
        width: 130px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #2c2c2c;
        .payment-icon {
          width: 20px;
          height: 20px;
          margin-right: 2px;
        }
      }
      .payment {
        background: #ffffff;
        border-radius: 6px;
      }
    }
    .QRcode-box {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      margin-top: 12px;
      width: 380px;
      height: 208px;
      box-shadow: 0px 15px 24px 0px rgba(174, 52, 28, 0.3);
      box-sizing: border-box;
      border-radius: 34px;
      border: 2px solid transparent;
      background-clip: padding-box, border-box;
      background-origin: padding-box, border-box;
      background-image: linear-gradient(140deg, #f1aa67, #ea472e),
        linear-gradient(180deg, rgba(252, 237, 204, 1), rgba(247, 212, 181, 1));

      .QR-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 364px;
        height: 192px;
        border-radius: 30px;
        border: 1px solid rgba(255, 255, 255, 0.32);
        .threeyuan-icon {
          width: 92px;
          height: 62px;
          margin-left: 33px;
        }
        .QRcode-item {
          width: 156px;
          height: 156px;
          background-color: rgba(255, 255, 255, 0.48);
          border-radius: 20px;
          margin-right: 34px;
          display: flex;
          align-items: center;
          .code {
            width: 136px;
            height: 136px;
            background: #ffffff;
            border-radius: 16px;
            overflow: hidden;
            margin: auto;
            display: flex;
            position: relative;
            .pay_status {
              width: 116px;
              height: 116px;
            }
            #limitedTimeCode2 {
              display: flex;
              margin: auto;
              align-items: center;
              img {
                width: 116px !important;
                height: 116px !important;
              }
            }
            #limitedTimeCode3 {
              display: flex;
              margin: auto;
              align-items: center;
              img {
                width: 116px !important;
                height: 116px !important;
              }
            }
            .alipay_icon {
              width: 24px;
              height: 24px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    .end {
      color: #88423d;
      text-align: center;
      height: 18px;
      font-size: 12px;
      line-height: 18px;
      margin-top: 16px;
      span {
        color: #2c2c2c;
        cursor: pointer;
      }
    }
  }
  .icon_close2 {
    width: 28px;
    height: 28px;
    position: absolute;
    top: -60px;
    right: -35px;
    margin: auto;
    cursor: pointer;
  }
}
</style>
