<template>
  <div id="aliCodeValidate">
    <button ref="fmAliCode" style="display: none" id="fmAliCode"></button>
  </div>
</template>

<script>
import { getSliderInfoAPI } from "@/utils/apiList/home";
export default {
  name: "aliCodeValidate",
  data() {
    return {
      captcha: null,
      apiCallback: null,
      dialogClosed: null,
      isSetHtml: false,
      ncInfo: {},
      aliCodeWidth: 400,
      aliCodeHeight: 48,
      codeSync: {
        res: null,
        rej: null,
      },
      timeId:0
    };
  },
  mounted() {
    this.initAliCode();
  },
  methods: {
    handleOpenSync(apiCallback) {
      return new Promise((resolve, reject) => {
        this.apiCallback = apiCallback;
        this.codeSync.res = resolve;
        this.codeSync.rej = reject;
        setTimeout(() => {
          let fmAliCode = document.getElementById("fmAliCode");
          if (fmAliCode) {
            fmAliCode.click();
          }
        }, 300);
      });
    },
    handleOpen(apiCallback, dialogClosed) {
      let fmAliCode = document.getElementById("fmAliCode");
      if (fmAliCode) {
        fmAliCode.click();
        this.apiCallback = apiCallback;
        this.dialogClosed = dialogClosed;
      }
    },
    initAliCode: _.debounce(
      function () {
        this.captcha = null;
        // 弹出式
        window.initAliyunCaptcha({
          SceneId: "18jnd02o", // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
          prefix: "cbak14", // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
          mode: "popup", // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
          element: "#captcha-element", //页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
          button: "#fmAliCode", // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
          captchaVerifyCallback: this.captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
          onBizResultCallback: this.onBizResultCallback, // 业务请求结果回调函数，无需修改
          getInstance: this.getInstance, // 绑定验证码实例函数，无需修改
          slideStyle: {
            width: this.aliCodeWidth,
            height: this.aliCodeHeight,
          }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
          language: "cn", // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
        });
      },
      100,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    async captchaVerifyCallback(captchaVerifyParam) {
      if (captchaVerifyParam?.indexOf("failover") >= 0) {
        this.timeId +=1
        if(this.timeId >=3){
          return;
        }
        this.captcha = null;
        this.initAliCode();
        setTimeout(() => {
          this.captchaVerifyCallback();
        }, 200);
        return;
      }
      // 1.向后端发起业务请求，获取验证码验证结果和业务结果
      let result = await this.apiCallback(captchaVerifyParam);
      if (result.validate) {
        this.apiCallback = null;
        if (this.codeSync.res) {
          this.codeSync.res(result);
          this.codeSync.res = null;
          this.codeSync.rej = null;
        }
      }
      // 2.构造标准返回参数
      return {
        captchaResult: result.validate, // 验证码验证是否通过，boolean类型，必选
        bizResult: result.result, // 业务验证是否通过，boolean类型，可选；若为无业务验证结果的场景，bizResult可以为空
      };
    },
    onBizResultCallback(bizResult) {
      if (this.dialogClosed) {
        this.dialogClosed(bizResult);
        this.dialogClosed = null;
      }
      if (this.codeSync.rej) {
        this.codeSync.rej(bizResult);
        this.codeSync.res = null;
        this.codeSync.rej = null;
      }
    },
    getInstance(instance) {
      this.captcha = instance;
      if (!this.isSetHtml) {
        this.isSetHtml = true;
        this.setAliCodeHtml();
        this.getgeconfigv2();
      }
    },
    goDetail(type) {
      if (type === 1) {
        window.open(
          this.$glb.Jmdyh + "/vd/MDAwMDAwMDAwMH7QfmeCfXqcs9yH3Yiqu5uFc3Ce"
        );
      } else {
        if (parseInt(this.ncInfo.type) === 2) {
          window.open(this.ncInfo.link,'_blank');
        }
      }
    },
    getgeconfigv2() {
      getSliderInfoAPI()
        .then((res) => {
          if (res.status == 1) {
            let _data = res.data || [];
            this.ncInfo = _data[0] || {};

            if (this.ncInfo.cover) {
              let imgBox = document.getElementById("ali-code-ad-img");
              if (imgBox) {
                imgBox.src = this.ncInfo.cover;
                imgBox.style.cursor = "pointer";
                imgBox.onclick = () => {
                  this.goDetail(2);
                };
              }
            }
          }
        })
        .catch((err) => {});
    },
    setAliCodeHtml() {
      let aliCodeAdImg = document.querySelector("#ali-code-ad-img");
      if (aliCodeAdImg) {
        return;
      }

      let aliLIne = document.querySelector(".aliyunCaptcha-line");
      if (aliLIne) {
        aliLIne.style.display = "none";
      }
      let aliTop = document.querySelector(".aliyunCaptcha-top.aliyun-captcha");
      if (aliTop) {
        aliTop.style.display = "none";
      }
      let aliBox = document.querySelector("#aliyunCaptcha-window-popup");
      if (aliBox) {
        let adImg = document.createElement("div");
        adImg.className = "pop-ad-box";
        adImg.innerHTML =
          '<div style="font-size: 0; line-height: 0;min-height: 50px;min-width: 320px;">\n' +
          '            <img style="width: ' +
          this.aliCodeWidth +
          'px;" id="ali-code-ad-img" src="" alt=""/>\n' +
          "          </div>";

        let firstChild = aliBox.firstChild;
        aliBox.insertBefore(adImg, firstChild);

        let footer = document.createElement("div");
        footer.className = "pop-footer-box";
        footer.innerHTML =
          '<div class="pop-footer">' +
          '<div id="howValidate" class="pointer">' +
          '<span class="desc">不会验证？</span></div>' +
          '<div id="close-ali-box" style="font-size: 0;line-height: 0;" class="pointer">' +
          '<i style="font-size: 18px;" class="el-icon-close"></i>' +
          "</div></div>";

        aliBox.appendChild(footer);

        let aliClose = document.querySelector("#close-ali-box");
        if (aliClose) {
          aliClose.onclick = () => {
            let mask = document.querySelector("#aliyunCaptcha-mask");
            if (mask) {
              mask.click();
              this.onBizResultCallback(false);
            }
          };
        }

        let howValidate = document.querySelector("#howValidate");
        if (howValidate) {
          howValidate.onclick = () => {
            this.goDetail(1);
          };
        }
      }
    },
  },
};
</script>

<style scoped></style>
