import CryptoJS from "CryptoJS";
import JSEncrypt from "JSEncrypt";
import store from "@/store";
import {  newReportedAPI, attributionPathLogAPI,autoRetryStatAPI } from "@/utils/apiList/home";
import { Message } from 'element-ui'
import i18n from "@/langurage/index";
import router from "@/router"; 

import conf from "./config/index";

var rsaUtil = {
  // RSA 位数  1024，这里要跟后端对应
  bits: 512,
  // 当前JSEncrypted对象
  thisKeyPair: {},
};
let is_event = false;
export default {
  // 替换搜索颜色
  getSearchKeyword(keyword, desc, color) {
    if (desc) {
      // 创建一个正则表达式，使用 'g' 选项进行全局匹配
      const regex = new RegExp(`(${desc})`, 'g');
      // 使用 replace 方法将匹配的部分用 span 包裹
      const highlightedText = keyword.replace(regex, `<span style="color: ${color}">$1</span>`);
      return highlightedText
    }
  },
  // 获取aeskey，
  genak(length = 12) {
    let random =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let str = "";
    for (let i = 0; i < length; i++) {
      str = str + random.charAt(Math.random() * random.length);
    }
    return str;
  },

  // aes加密
  ae(plaintext, key) {
    // if (plaintext instanceof Object) {
    // // JSON.stringify
    //   plaintext = JSON.stringify(plaintext)
    // }
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(plaintext),
      CryptoJS.enc.Utf8.parse(key),
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return encrypted.toString();
  },

  // aes 解密
  ad(ciphertext, key) {
    let decrypt = CryptoJS.AES.decrypt(
      ciphertext,
      CryptoJS.enc.Utf8.parse(key),
      { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    let decString = CryptoJS.enc.Utf8.stringify(decrypt).toString();
    // if (decString.charAt(0) === '{' || decString.charAt(0) === '[') {
    // // JSON.parse
    //   decString = JSON.parse(decString)
    // }
    return decString;
  },

  // 生成rsa密钥对(公钥和私钥)
  genrp(bits = rsaUtil.bits) {
    let genKeyPair = {};
    rsaUtil.thisKeyPair = new JSEncrypt({ default_key_size: bits });
    // 获取私钥
    genKeyPair.privateKey = rsaUtil.thisKeyPair.getPrivateKey();
    // 获取公钥
    genKeyPair.publicKey = rsaUtil.thisKeyPair.getPublicKey();
    return genKeyPair;
  },
  // 公钥加密
  re(plaintext, publicKey) {
    // 由于没有genRsaKeyPair，所以需要执行下面这句
    rsaUtil.thisKeyPair = new JSEncrypt({ default_key_size: rsaUtil.bits });
    if (plaintext instanceof Object) {
      plaintext = JSON.stringify(plaintext);
    }

    publicKey && rsaUtil.thisKeyPair.setPublicKey(publicKey);
    return rsaUtil.thisKeyPair.encrypt(plaintext);
  },

  // 私钥解密
  rd(ciphertext, privateKey) {
    privateKey && rsaUtil.thisKeyPair.setPrivateKey(privateKey);
    let decString = rsaUtil.thisKeyPair.decrypt(ciphertext);
    // if (decString.charAt(0) === '{' || decString.charAt(0) === '[') {
    //   // JSON.parse
    //   decString = JSON.parse(decString)
    // }
    return decString;
  },
  // 加密解密方法：string-需要解密的字段  code-约定的密钥    operation 默认false表示加密，传入true表示解密
  secret(string, code, operation) {
    if (string instanceof Object) {
      // JSON.stringify
      string = JSON.stringify(string);
    }
    code = CryptoJS.MD5(code).toString();
    var iv = CryptoJS.enc.Utf8.parse(code.substring(0, 16));
    var key = CryptoJS.enc.Utf8.parse(code.substring(16));
    if (operation) {
      return CryptoJS.AES.decrypt(string, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
      }).toString(CryptoJS.enc.Utf8);
    }
    return CryptoJS.AES.encrypt(string, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
  },

  setCookie(name, value, second) {
    var timestr = "";
    if (second > 0) {
      var exp = new Date();
      exp.setTime(exp.getTime() + 1000 * second);
      timestr = ";expires=" + exp.toGMTString();
    }
    document.cookie = name + "=" + escape(value) + timestr;
  },
  getCookie(name) {
    var arrt = document.cookie.match(
      new RegExp("(^| )" + name + "=([^;]*)(;|$)")
    );
    if (arrt) {
      return unescape(arrt[2]);
    } else {
      return null;
    }
  },
  clearCookie(cname) {
    var cd = new Date();
    cd.setTime(cd.getTime() - 1);
    document.cookie = cname + "=-1;expires=" + cd.toUTCString();
    // window.document.cookie = cname + '=del;path=/;expires=-1'
  },
  getPng(str) {
    // 得到正确路径的图片
    return conf.cdnPic + "/fm2023/" + str + ".png";
  },
  changeTheme(theme) {
    store.commit("setAttr", {
      name: "theme",
      val:theme,
    });
  },
  //新增埋点数据
  newReported(event_id) {
    // newReportedAPI({event_id}).then((res) => {
    // }).catch((err) => {
    // });
  },
  // 来路模式埋点数据  num:埋点id，voucher：身份标识，screen点击坐标
  routeModeReported(num,voucher = '') {
    // 未登录的时候不能发送请求
    // path_official_channel_switch 官方渠道开关邓鑫
    // path_daren_channel_switch 达人渠道开关
    if(this.getCookie(conf.fmCookieName) == null) return
    if(store.state?.userInfo?.switchs?.path_official_channel_switch == 0 && voucher == '') return
    if(store.state?.userInfo?.switchs?.path_daren_channel_switch == 0 && voucher) return
    
    var e = window.event;
    // if(is_event) return
    // is_event = true
    if(store.state.route_ids[store.state.route_ids.length - 1] != num){
      store.commit("setAttr", {
        name: "route_ids",
        val: [...store.state.route_ids,num],
      })
    }
    if(voucher){
      store.commit("setAttr", {
        name: "voucher",
        val: voucher,
      })
    }
    // setTimeout(()=>{
    //   is_event = false
    // },200)
    attributionPathLogAPI({
      num,
      voucher,
      screen: e?.clientX ? `${e.clientX}*${e.clientY}` : ''
    }).then((res) => {
      route_id.push(num)
    }).catch((err) => {});

  },
  autoRetryStatFn(data) {
    autoRetryStatAPI(data).then((res) => {
    }).catch((err) => {});
  },
  //文件图标判断
  fileIcon(ext){
    let type = this.getFileExt(this.formatExt(ext))
    switch (type) {
      case 'png':
        return this.getPng('fm_icon_photo')
      case 'zip':
        return this.getPng('fm_icon_zip')
      case 'doc':
        return this.getPng('fm_icon_word')
      case 'ppt':
        return this.getPng('fm_icon_ppt')
      case 'apk':
        return this.getPng('fm_icon_apk')
      case 'txt':
        return this.getPng('fm_icon_txt')
      case 'xls':
        return this.getPng('fm_icon_excelicon')
      case 'mp3':
        return this.getPng('fm_icon_music')
      case 'mp4':
        return this.getPng('fm_icon_video')
      case 'ipa':
        return this.getPng('fm_icon_mac')
      case 'exe':
        return this.getPng('fm_icon_exe')
      case 'common':
        return this.getPng('fm_icon_common')
      case 'folder':
        return this.getPng('fm_icon_files')
      case 'pdf':
        return this.getPng('fm_icon_pdf')
      case 'xmind':
        return this.getPng('fm_icon_xmind')
      case 'font':
        return this.getPng('fm_icon_font')
      case 'psd':
        return this.getPng('fm_icon_psd')
      case 'html':
        return this.getPng('fm_icon_html')
    }
  },
  getFileExt(ext) {
    if (this.in_array(ext, ['apk', 'mp3', 'exe', 'txt','xmind','psd','html'])) {
      return ext.toLowerCase()
    } else if (
      this.in_array(ext, ['PNG', 'png', 'jpg', 'gif', 'bmp', 'jpeg','heic'])
    ) {
      return 'png'
    } else if (this.in_array(ext, ['zip', 'rar'])) {
      return 'zip'
    } else if (this.in_array(ext, ['doc', 'docx', 'wps','wpt','dot','dotx','docm','dotm','rtf'])) {
      return 'doc'
    } else if (this.in_array(ext, ['ppt', 'pptx','pptm','ppsx','ppsm','pps','potx','potm','dpt','dps'])) {
      return 'ppt'
    } else if (this.in_array(ext, ['xls','xlt','et','xlsx','xltx','csv','xlsm','xltm'])) {
      return 'xls'
    } else if (
      this.in_array(ext, ['mov', 'mp4', 'MP4'])
    ) {
      return 'mp4'
    } else if (this.in_array(ext, ['ipa', 'dmg'])) {
      return 'ipa'
    } else if (this.in_array(ext, ['folder'])) {
      return 'folder'
    } else if (this.in_array(ext, ['pdf'])) {
      return 'pdf'
    } else if (this.in_array(ext, ['font','otf','ttf','woff'])) {
      return 'font'
    } else {
      return 'common'
    }
  },
  in_array(search, arr) {
    for (var i = 0, len = arr.length; i < len; i++) {
      if (arr[i] === search.toLowerCase()) {
        return true
      }
    }
    return false
  },
  //飞猫盘允许上传格式
  allowexp(ext) {
    return this.in_array(ext, [
      'jpg',
      'png',
      'ico',
      'bmp',
      'jpeg',
      'gif',
      'apk',
      'ipa',
      'dmg',
      'exe',
      'txt',
      'xlsx',
      'docx',
      'doc',
      'ppt',
      'pptx',
      'rar',
      'xls',
      'zip',
      '7z',
      'tar',
      'iso',
      'dmg',
      'gz',
      'bz2',
      'psd',
      'pdf',
      'xmind',
      'ai',
      'wps',
      'csv',
      'heic'
    ])
  },
  bytesToSize(bytes) {
    bytes = parseInt(bytes)
    if (!bytes || bytes === 0 || bytes === '0') return '0 B'
    var k = 1024
    var sizes = ['B', 'KB', 'MB', 'GB', 'T']
    var i = Math.floor(Math.log(bytes) / Math.log(k))
    return (bytes / Math.pow(k, i)).toFixed(2) + sizes[i]
  },
  //15s 当前下载大小小于100k 下载变为失败 出现重试按钮 使用的方法
  bytesToSizePagec(bytes) {
    bytes = parseInt(bytes)
    if (!bytes || bytes === 0 || bytes === '0') return '0 B'
    var k = 1024
    var sizes = ['B', 'K', 'M', 'G', 'T']
    var i = Math.floor(Math.log(bytes) / Math.log(k))
    return (i==1 && (bytes / Math.pow(k, i)).toFixed(2) < 100) ? true : false
  },
  formatSeconds(value) {
    var secondTime = parseInt(value) > 0 ? parseInt(value) : 0
    var minuteTime = 0
    var hourTime = 0
    if (secondTime > 60) {
      minuteTime = parseInt(secondTime / 60)
      secondTime = parseInt(secondTime % 60)
      if (minuteTime > 60) {
        hourTime = parseInt(minuteTime / 60)
        minuteTime = parseInt(minuteTime % 60)
      }
    }
    var result = (secondTime < 10 ? '0' : '') + secondTime +'秒'
    result =  (minuteTime > 0 ? (minuteTime < 10 ? '0' : '') + parseInt(minuteTime) + '分钟' : '00分钟') + result
    result = (hourTime > 0 ? (hourTime < 10 ? '0' : '') + parseInt(hourTime) + '小时' : '00小时') + result
    return result
  },
  formatSeconds2(value) {
    var secondTime = parseInt(value) > 0 ? parseInt(value) : 0
    var minuteTime = 0
    var hourTime = 0
    if (secondTime > 60) {
      minuteTime = parseInt(secondTime / 60)
      secondTime = parseInt(secondTime % 60)
      if (minuteTime > 60) {
        hourTime = parseInt(minuteTime / 60)
        minuteTime = parseInt(minuteTime % 60)
      }
    }
    var result = (secondTime < 10 ? '0' : '') + secondTime
    result =  (minuteTime > 0 ? (minuteTime < 10 ? '0' : '') + parseInt(minuteTime) + ':' : '00:') + result
    result = (hourTime > 0 ? (hourTime < 10 ? '0' : '') + parseInt(hourTime) + ':' : '00:') + result
    return result
  },
  convertSecondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    // 使用padStart确保时、分、秒都是两位数，不足两位补0
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');
    if(seconds!=0){
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }else{
      return "00:00:00";
    }
    
  },
  //数组去重
  arrFilter(arr) {
    let newArr = arr.reduce((pre, cur) => {
      if (!pre.includes(cur)) {
        return pre.concat(cur)
      } else {
        return pre
      }
    }, [])
    return newArr
  },
  formatExt(str){
    let oneStr = str.substr(0,1)
    if(oneStr=='.'){
      return str.substr(1,str.length);
    }else{
      return str
    }
  },
  searchTitSlice(keywords) {
    if(store.state.language == 'en') {
      return `Search Results for "${keywords.length > 20  ? keywords.slice(0, 20)+ "..." :keywords}"`
    } else if (store.state.language == 'hk') {
      return `搜索“ ${keywords.length > 20  ? keywords.slice(0, 20)+ "..." :keywords  } ”的結果`
    } else {
      return `搜索“ ${keywords.length > 20  ? keywords.slice(0, 20)+ "..." :keywords  } ”的结果`
    }
  },
  getConnection() {
    const nav = navigator;
    if (typeof nav !== 'object') return null;
    return nav.connection || nav.mozConnection || nav.webkitConnection;
  },
  changeLanguage(route, index) {
    //index == 0 英文 index == 1 简体中文 index == 2 繁体中文
    const language = ['en','cn','hk']
    store.commit("setAttr", {
      name: "language",
      val: language[index],
    })
    i18n.locale = language[index];
    localStorage.setItem('FeemooLang',i18n.locale)
    // if (index == 0) {
    //   store.commit("setAttr", {
    //     name: "language",
    //     val: 'en',
    //   })
    //   i18n.locale = 'en';
    // } else if (index == 2) {
    //   store.commit("setAttr", {
    //     name: "language",
    //     val: 'tc',
    //   })
    //   i18n.locale = 'zh-HK';
    // }

    if (index == 0) {
      if (route.fullPath.indexOf('/en-US') == -1) {
        router.push({ path: '/en-US' + route.fullPath.replace('/zh-HK', '') });
      }
    }
    if (index == 1) {
      const newPath = route.fullPath.replace('/en-US', '').replace('/zh-HK', '');
      router.push({ path: newPath == '' ? '/' : newPath });
    }
    if (index == 2) {
      if (route.fullPath.indexOf('/zh-HK') == -1) {
        router.push({ path: '/zh-HK' + route.fullPath.replace('/en-US', '') });
      }
    }
  },
};
