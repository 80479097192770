export default {
  home: { // 首页模块
    label1: "中文",
    FEEMOO: 'logo-fm-new', // logo
    Home: '首頁', // 首页
    Clouddrive: '雲盤', // 云盘
    VIP: '會員', //会员
    Login: '登錄', //登录
    Enterthewebdisk: '進入網盤', // 进入网盘
    Signup: '立即註冊', //立即注册
    Enter: '進入我的飛貓盤', // 进入我的飞猫盘
    Tenyearsinarow: '十年如一日', //十年如一日
    Launched: '上線10年老品牌 iOS、Android、Win、Mac全平臺客戶端', //上线10年老品牌 iOS、Android、Win、Mac全平台客户端
    FastDownload: '極速下載', // 极速下载
    OnlinePreview: '在線預覽', // 在线预览
    Clouddecompression: '雲端解壓', // 云端解压
    MultiPlatformSupport: '多平臺支持', // 多平台支持
    Shareandearn: '分享賺收益', // 分享赚收益
    Extreme1: '極致速度體驗：', // 极致速度体验：
    Extreme2: ' 飛貓盤採用分佈式存儲技術，千兆寬帶下載，支持多線程下載，無論多大文件都能輕鬆下載到本地。', // 飞猫盘采用分布式存储技术，千兆宽带下载，支持多线程下载，无论多大文件都能轻松下载到本地。
    Easy1: '音頻圖文輕鬆看：  ', // 音频图文轻松看： 
    Easy2: ' 無需下載，即刻在線打開，超高清影音震撼你的視聽，在線預覽看不受限制，快來體驗隨心所欲的樂趣吧！', // 无需下载，即刻在线打开，超高清影音震撼你的视听，在线预览看不受限制，快来体验随心所欲的乐趣吧！
    Oneclick1: '一鍵解壓： ', // 一键解压： 
    Oneclick2: ' 極速預覽壓縮包內容、一鍵解壓、兼容性強、支持多種格式在線解壓。', // 极速预览压缩包内容、一键解压、兼容性强、支持多种格式在线解压。
    Support: '支持rar、zip等主流解壓文件格式', // 支持rar、zip等主流解压文件格式
    Crossplatform1: '跨平臺雲服務： ', // 跨平台云服务：
    Crossplatform2: ' 高體驗客戶端支持Windows、Mac、Android、iOS等多種操作系統和設備，讓您隨時隨地訪問和管理您的文件。', // 高体验客户端支持Windows、Mac、Android、iOS等多种操作系统和设备，让您随时随地访问和管理您的文件。
    Rebate1: '返利計劃： ', // 返利计划： 
    Rebate2: ' 上傳文件並分享鏈接或合集給好友下載，就能獲得下載返利！', // 上传文件并分享链接或合集给好友下载，就能获得下载返利！
    participate1: '參與返利計劃需先在飛貓達人中心 ', // 参与返利计划需先在飞猫达人中心。
    participate2: '申請成爲飛貓達人。', // 申请成为飞猫达人。
    BoardTitle1: '全平臺支持',
    BoardText1: 'iOS、Android、Win、Mac全平臺客戶端',
    BoardTitle2: '傳輸與分享',
    BoardText2: '多平臺：桌面端、移動端、網頁端，隨時隨地管理文件。\n極速傳輸：快速上傳下載， 提升工作效率。\n便捷分享：輕鬆分享文件，一鍵創建分享合集。',
    BoardTitle3: '安全又穩定',
    BoardText3: '飛貓盤採用分佈式存儲技術，千兆寬帶下載，支持多線程下載，企業級安全服務，存儲更安心。',
    CloudMaster: '成爲CloudMaster賺錢', // 成为CloudMaster赚钱
    Sharing: '享受分享的樂趣，輕鬆賺取屬於你的變現收益', // 享受分享的乐趣，轻松赚取属于你的变现收益
    Label1: '下載量收益', //下载量收益
    Text1: '分享文件給好友下載，按分大小文件按下載量返利，區分等級萬次下載最高1888元，每週三、週四雙倍收益。', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label2: '會員返利收益', //下载量收益
    Text2: '分享文件給好友下載，按下載用戶開通會員進行按比例分成，文件不過期，頁面更簡潔，適合大文件分享', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label3: '優惠碼返利', //下载量收益
    Text3: '好友通過你的優惠碼開通會員享折扣，同時您可獲得高額返利；達人等級越高，優惠碼折扣越大。', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    Label4: '下線返利', //下载量收益
    Text4: '好友通過你的邀請鏈接註冊後成爲飛貓達人，你可獲得好友收益的高額返利。', //  分享文件给好友下载，按分大小文件按下载量返利，区分等级万次下载最高1888元，每周三、周四双倍收益。
    More: '查看更多', // 查看更多
    MorePrivilege: '查看更多特權', // 查看更多特权
    Privilege: '會員用戶特權', // 会员用户特权
    CardText0: '極致下載加速',
    CardText1: '最高1000GB\n存儲空間',
    CardText2: '最高每天300次\n下載額度',
    CardText3: '便捷雲轉存',
    CardText4: '無廣告',
    CardText5: '在線雲解壓',
    CardText6: '在線音視頻',
    CardText7: '已刪除文件將在\n回收站保留60天',
    estimation: '評論', // 评论
    evaluate: '評價', // 评价
    AndroidClient: 'Android客戶端', // Android客户端
    Download: '下載', // 下载
    DownloadNow: '立即下載', // 立即下载
    iOSClient: 'iOS客戶端', // iOS客户端
    WindowsDesktop: 'Windows桌面端', // Windows桌面端
    MacOSDesktop: 'Mac OS桌面端', // Mac OS桌面端
    ScanQRcodedownload: '掃碼下載', // 扫码下载
    FEEMOOLIMITED: '備案信息', // 备案信息
    DownloadNowNew:'即刻下載',//即刻下载
    TryNow:'即刻體驗',//立即体验
    TxtOne:'極速傳輸 一鍵轉存',//极速传输 一键转存
    TxtTwo:'合集分享 傳遞更多',//合集分享 传递更多
    TxtThree:'一鍵轉存 高效管理',//一键转存 高效管理
    TxtFour:'多端同步 隨時訪問',//多端同步 随时访问
    UltraTransfer:'極速傳輸',//极速传输
    TxtFive:'高效嘅文件傳輸體驗',//高效的文件传输体验
    TxtSix:'超快傳輸，盡享飛馳，FEEMOO憑藉卓越嘅傳輸技術，輕鬆實現大文件同海量數據嘅瞬間同步，令到數據流轉猶如飛馳電掣，帶嚟極致嘅使用體驗。',//超快传输，尽享飞驰，FEEMOO凭借卓越的传输技术，轻松实现大文件和海量数据的瞬间同步，让数据流转如同飞驰电掣，带来极致的使用体验。
    EasySharing:'輕鬆分享',//轻松分享
    TxtSeven:'方便靈活嘅分享功能',//便捷灵活的分享功能
    TxtEight:'便捷分享，掌控自如，一鍵生成文件或者合集嘅分享鏈接，發送畀好友，靈活設置分享權限同分享模式，確保文件安全、分享無憂。',//便捷分享，掌控自如，一键生成文件或合集的分享链接，发送给好友，灵活设置分享权限与分享模式，确保文件安全、分享无忧。
    OneSav:'一鍵轉存',//一键转存
    SeamlesslySave:'無縫銜接嘅快速保存',//一键转存
    TxtNine:'智能轉存，井然有序，一鍵將好友分享嘅文件或者合集高效轉存至FEEMOO，自動智能分類，令到文件管理井井有條，揾同用更加方便。',//智能转存，井然有序，一键将好友分享的文件或合集高效转存至FEEMOO，自动智能分类，让文件管理井井有条，查找和使用更加便捷。
    MultiSupport:'多平台支援',//多平台支持
    TxtTen:'自由暢行嘅跨平台體驗',//自由畅行的跨平台体验
    TxtEleven:'無縫連接，暢享便捷，全面兼容Windows、Mac、iOS、Android等主流平台，實現文件嘅無縫同步同隨時訪問，確保數據嘅連續性同便捷性。',//无缝连接，畅享便捷，全面兼容Windows、Mac、iOS、Android等主流平台，实现文件的无缝同步和随时访问，确保数据的连续性和便捷性。
    EnterCloud:'進入我的雲盤',//进入我的云盘
    AndroidDownload:'Android客戶端',//Android客户端
    iOSDownload:'iOS客戶端',//iOS客户端
    WindowsDownload:'Windows桌面端',//Windows桌面端
    MacDownload:'Mac OS桌面端',//Mac OS桌面端
  },
  login: { // 登录模块
    Home: '首頁', // 首页
    Clouddrive: '雲盤', // 云盘
    VIP: '會員', //会员
    ScanQRcodetologin: '掃碼登錄', // 扫码登录
    Pleaseuse1: '請使用', // 请使用
    Pleaseuse2: '飛貓盤', //  飞猫盘
    Pleaseuse3: 'APP', //  APP
    Pleaseuse4: '在頁面右上角打開掃一掃', // 在页面右上角打开扫一扫
    DownloadAPP: '下載APP', // 下载APP
    DownloadDesktop: '下載桌面端', // 下载桌面端
    Login: '登錄', // 登录
    Signup: '註冊', // 注册
    Pleaseenter: '請輸入賬號/郵箱號/手機號', // 请输入账号/邮箱号/手机号
    Pleasepassword: '請輸入密碼', // 请输入密码
    Rememberpassword: '記住密碼', // 记住密码
    Forgotpassword: '忘記密碼？', // 忘记密码？
    Moreways: '更多方式', // 更多方式
    Back: '返回', // 返回
    Mobilephone: '手機號找回', // 手机号找回
    themobilephone: '請填寫註冊賬號時所填寫手機號', // 请填写注册账号时所填写手机号
    Pleaseentermobile: '請輸入手機號', // 请输入手机号
    Next: '下一步', // 下一步
    Mobilephonenumber1: '手機號已停用？', // 手机号已停用？
    Mobilephonenumber2: '郵箱找回', // 邮箱找回
    Verifymobile: '驗證手機號', // 验证手机号
    verificationminutes1: '請輸入發送至', // 请输入发送至+8618899990000的6位验证码，有效期3分钟
    verificationminutes2: '的 6 位驗證碼，有效期3分鐘', // 请输入发送至+8618899990000的6位验证码，有效期3分钟
    Retrieve: '後重新獲取', // 重新获取
    VerifyEmail: '驗證郵箱', // 验证邮箱
    Retrievecaptcha: '重新獲取驗證碼', // 重新获取验证码
    Emailretrieval: '郵箱找回', // 邮箱找回
    Pleaseemail: '請填寫註冊賬號時所填寫郵箱', // 请填写注册账号时所填写邮箱
    Pleasemailbox: '請輸入郵箱號', // 请输入邮箱号
    Emailnot: '郵箱無法接收？', // 邮箱无法接收？
    Phoneretrieval: '手機號找回', // 手机号找回
    Resetpassword: '重設密碼', // 重设密码
    Pleasewithpassword: '請輸入新密碼，最少設置 6 位數字或字母', // 请输入新密码，最少设置 6 位数字或字母
    Newpassword: '新密碼', // 新密码
    Enternewpassword: '再次輸入新密碼', // 再次输入新密码
    Gotologin: '完成，去登錄', // 完成，去登录
    automatically1: '新手機號將自動註冊，', // 新手机号将自动注册，
    automatically2: '已詳讀並同意', // 已详读并同意
    automatically3: '《用戶服務協議》', // 《用户服务协议》
    Clicktorefresh: '點擊刷新', // 点击刷新
    ViewSupported: '查看支持註冊的郵箱號', // 查看支持注册的邮箱号
    Setupaccount: '設置賬號', // 设置账号
    accountdigits: '請設置Feemoo賬號（6-20位數字或字母）', // 请设置飞猫盘账号（6-20位数字或字母）
    Setpassword: '設置密碼', // 设置密码
    Setloginpassword: '請設置登錄密碼（至少6位數字或字母）', // 请设置登录密码（至少6位数字或字母）
    Enterpassword: '請再次輸入密碼', // 请再次输入密码
    Confirm: '確定', // 确定
    Wechatscan: '微信掃碼登錄/註冊', // 微信扫码登录/注册
    TheFEEMOOCenter:'飛貓達人中心',//飞猫达人中心
    GrantedPrivileges:'將獲得以下權限',//将获得以下权限
    permissionOne: '獲取你的FEEMOO頭像、昵稱',
    permissionTwo: '獲取你的FEEMOO帳號',
    Files: '文件', // 文件
    Set: '合集', // 合集
    Collect:'收藏',//收藏
    app: '客戶端', // 客户端
  },
  vip: { // vip模块
    vip: '開通會員', // 开通会员
    intrtit0: '專屬下載額度',//专属下载额度
    intrcontent0: '最高享300次/天',//最高享300次/天
    intrtit1: '下載加速',
    intrcontent1: '最高享極致加速',
    intrtit2: '去廣告體驗',
    intrcontent2: '會員專享純淨體驗',
    intrtit3: '更多雲體驗',
    intrconten3: '6項在線功能',
    openVip: '立即開通會員服務享受特權', // 立即开通会员服务享受特权
    limitedSeries: '限量發售', // 限量发售
    greatValue: '超值爆款', // 超值爆款
    vipMax: '不限時長會員',//不限时长会员
    vipIntr: '注：標有*特權需消耗下載額度使用，剩餘通用下載額度唔夠嗰陣，可以用福利點嚟用；會員限時免費體驗FEEMOO AI，每月1號刷新FEEMOO AI對話嘅token用量。',//注：標有 * 特權需消耗下載額度使用，剩餘通用下載額度不足時，可消耗福利點使用；會員限時免費體驗 deepseek 智能對話，每月 1 號刷新智能對話 token 用量。
    welfare: '福利社',// 福利社
    buy:'開通',//开通
    buy1:'升級',//升级
    Notloggedin: '未登錄', // 未登录
    Cloudequity: '權益', // 权益
    allBenefits: '全部權益',// 全部权益
    ViewAllBenefits: '查看全部權益', // 查看全部权益
    PremiumEscalation: '補差價升級', // 补差价升级 
    ActualPayment: '實付', // 实付
    OpenVIP: '開通會員', // 开通会员
    Useode: '使用代金券或優惠碼', // 使用代金券或优惠码
    offAlready: '已優惠', // 已优惠
    Renewalfee: '續費', // 续费25元
    Discount:'立減',//立减
    Premium:'開通會員',//开通会员
    PremiumTwo:'升級會員',//升级会员
    PremiumThree:'會員中心',//会员中心
  },
  cloud: { // 云盘模块
    CloudSpace: '雲盤空間', // 云盘空间
    todayDownloadNum: '今日下載額度', // 今日下载
    files: '文件', // 文件
    share: '分享', // 分享
    history: '歷史', // 历史
    recycle: '回收站', // 回收站
    daren: '飛貓達人',
    filePlaceholderTop: '搜索雲盤文件',
    upload: '上傳文件',//上传文件
    newFloder: '新建文件夾',//新建文件夹
    Refresh:'刷新',//刷新
    allFiles: '全部文件',//全部文件
    upname1: '文件',//文件
    upname2: '文件夾',//文件夹
    all: '全部',//全部
    package: '壓縮包',//压缩包
    medium: '音視頻',//音视频
    document: '文檔',//文档
    picture: '圖片',//图片
    fileName: '文件名',//文件名
    browseDown: '瀏覽/下載',//浏览/下载
    size: '大小',//大小
    creatTime: '創建時間',//创建时间
    noMore: '沒有更多了',//没有更多了
    dragFiles0: '拖拽文件',//拖拽文件到这里上传
    dragFiles1: '到這裏上傳',//拖拽文件到这里上传
    cannot4g: '單個文件大小不能超過4G',//单个文件大小不能超过4G
    xieyinot: '嚴禁上傳非法內容',//严禁上传非法内容
    rightOpen: '打開', // 打开
    rightDownload: '下載', // 下载
    rightShare: '分享', // 分享
    rightRename: '重命名', // 重命名
    rightDelete: '刪除', // 删除
    rightMoveto: '移動到', // 移动到
    pcDownfile: '客戶端下載', // 客户端下载
    notFile: '暫無相關文件', // 暂无相关文件
    trySearchOther: '試試搜索其他關鍵詞', // 试试搜索其他关键词
    unzip: '解壓', // 解压
    noUpLoad: '暫無上傳任務', // 暂无上传任务
    setFileDirectory: '設置文件目錄', // 设置文件目录Set file directory
    Addfile: '添加文件', // 添加文件
    Allstarted: '全部開始', // 全部开始
    vipDownload:'會員極速下載中', //会员极速下载中
    Allpause: '全部暫停', // 全部暂停
    AllCancel: '全部取消', // 全部取消
    getLoading: '獲取文件信息中', // 获取文件信息中
    Pauseupload: '暫停上傳', // 暂停上传
    Uploadfailed: '上傳失敗', // 上传失败
    chenUploaded: '已上傳', // 已上传
    to:'至',//至
    pcTit: '全新PC端，大文件下載更穩定', // 全新PC端，大文件下载更稳定
    memberDownTit:'會員極速下載通道',//会员极速下载通道
    touristDownTit: '普通下載通道',//普通下载通道
    memberDownTitStr:'極速下載中',//极速下载中
    touristDownTitStr: '下載中',//下载中
    instantSpeed: '立即提速',//立即提速
    notRefresh:'有任務進行時請勿刷新網頁',//有任务进行时请勿刷新网页
    Goexperience: '前往體驗', // 前往体验
    Nodownloadrecord: '暫無下載記錄', // 暂无下载记录
    Nodecompressionrecord: '暫無解壓記錄', // 暂无解压记录
    Queuing: '排隊中', // 排队中
    DownloadSuccess: '下載成功', // 下载成功
    DownloadFailure: '下載失敗', // 下载失败
    Decompressionrecords: '解壓記錄保存7天，到期後自動刪除', // 解压记录保存7天，到期后自动删除
    Deleterecord: '刪除解壓記錄', // 删除解压记录
    Unpacking: '正在解壓', // 正在解压
    UnpackingFailed: '解壓失敗', // 解压失败
    Extractedto: '已解壓到', // 已解压到
  },
  welfare: {// 福利社
    myWelfarePoints: '我的福利點',//我的福利点
    viewWelfarePoints: '查看明細',//查看明细
    getpoint: '得福利點',
    welfareIntr: '福利點可免費下載文件或兌換體驗會員特權',
    watchVideotoApp: '去APP看視頻',
    payPoint: '花福利點',
    todayTimes: '今日剩餘',
    duihuan: '兌換',
    yiduihuan: '已兌完',
    canUsePoints: '福利點可用',
    welfarePoint: '福利點',
    welfareDown:"今日已兌完",//今日已兑完
    welfarePrefix: '今日剩餘',
    welfareSuffix: '份'
  },
  common: { // 公共
    cloud: '雲盤', // 云盘
    vip: '會員', // 会员,
    app: '客戶端', // 客户端
    title: 'Feemoo雲端硬碟 - 安全加密大容量雲儲存平台｜免費跨平台同步及極速傳檔', // 页签名称
    Files: '文件', // 文件
    Set: '合集', // 合集
    Collect:'收藏',//收藏
  },
  setPage:{
    Set: '合集', // 合集
    SearchSet:'搜索合集', // 搜索合集
    AllSet:'全部合集', //全部合集
    SetName:'合集名', //合集名
    NumberEntries:'項目數',//项目数
    browse:'瀏覽',//浏览
    Collect:'收藏',//收藏
    ShareTime:'分享時間', // 分享时间
    View:'查看', //查看
    Edit:'編輯', // 编辑
    Delete:'刪除', //删除
    NoCollection:'暫無合集',//暂无合集
    NoToCollection:'暫無相關合集',//暂无相关合集
    recyleNoCollec:'回收站暫無合集',//回收站暂无合集
    TextOne:'創建合集後可以在這裏查看',//创建合集后可以在这里查看
    TextTwo:'試試搜索其他關鍵詞',//试试搜索其他关键词
  },
  Collection:{
    Set: '合集', // 合集
    Files: '文件', // 文件
    allFiles: '全部文件',//全部文件
    SearchFavorites:'搜索收藏文件',//搜索收藏文件
    CollectionTime:'收藏時間',//收藏时间
    NoFavoritesFiles:'暫無收藏文件', //暂无收藏
    NoFavoriteSet:'暫無收藏合集',//暂无收藏合集
    TextOne:'收藏文件後可以在這裏查看',//收藏文件后可以在这里查看
    Texttwo:'收藏合集後可以在這裏查看',//收藏合集后可以在这里查看
    AllSet:'全部合集', //全部合集
    SetName:'合集名', //合集名
    SearchCollections:'搜索收藏合集',//搜索收藏合集
    New:'更新',//更新
    owningUser:'所屬用戶',//所属用户
    uncollect:'取消收藏',//取消收藏
    View:'查看', //查看
  },
  userinfo: { // 个人信息悬浮模块
    vip: '開通會員', // 开通会员
    Downloadquota: '下載額度', // 下载额度
    Downloadspeed: '下載加速', // 下载加速
    Cloudcapacity: '雲盤空間', // 云盘空间
    Cloudequity: '雲盤權益', // 云盘权益
    DownloadLimit: '今日下載額度', // 今日下载额度
    awelfarepoint: '福利點', // 福利点
    HelpCentre: '幫助中心', // 帮助中心
    AccountSettings: '賬號設置', // 账号设置
    SignOut: '退出登錄', // 退出登录
    Login: '登 錄', // 登录
    Signup: '註 冊', // 注册
  },
  share: { // 分享模块
    newCollections: '新建合集', // 新建合集
    allShare: '全部分享', // 全部分享
    all: '全部', // 全部
    files: '文件', // 文件
    collention: '合集', // 合集
    sharePlaceholder: '搜索分享文件', // 搜索分享文件
    copyLink: '複製鏈接', // 复制链接
    viewLink: '查看分享/取件碼', // 查看分享/取件码
    editCollection: '編輯合集文件', // 编辑合集文件
    reName: '重命名', // 重命名
    deleteCollection: '刪除合集', // 删除合集
    cancelShare: '取消分享', // 取消分享
    cancelDelete: '取消分享/刪除合集', // 取消分享/删除合集
    notShareFiles: '暫無分享文件',//暂无分享文件
    shareLook:'分享後可以在這裏查看',//分享后可以在这里查看
    
  },
  downLoadHistory: { // 下载历史模块
    openDownload: '開啓記錄下載歷史',//开启记录下载历史
    openDownloaded: '已開啓記錄下載歷史',//已开启记录下载历史
    notOpenDownload: '暫未開啓記錄下載歷史',//暂未开启记录下载历史
    downLoadHistoryPlaceholder: '搜索下載歷史', // 搜索下载历史
    openVipDownload: '開通會員專享，可開啓記錄下載歷史', //开通会员专享，可开启记录下载历史
    notDownload: '暫無下載歷史',//暂无下载历史
    notHistory:'暫無相關記錄', // 暂无相关记录
    recordDownloaded:'記錄下載過的文件', // 记录下载过的文件
    viewDownloadShareFiles: '查看已下載的分享文件',// 查看已下载的分享文件
    openVip: '開通會員', // 开通会员
    lookDownloaded: '查看下載', // 查看下载
    deleteDownloaded: '刪除', // 删除下载
  },
  recycle: { // 回收站模块
    clearRecycle: '清空回收站', // 清空回收站
    recyclePlaceholder: '搜索回收站文件', // 搜索回收站文件
    effectiveTime: '有效時間',//有效时间
    deleteTime: '刪除時間',//删除时间
    restore: '還原',//还原
    sweep: '徹底刪除',//彻底删除
    noFiles:'回收站暫無文件',//回收站暂无文件
    tipsPrefix: '回收站內容保存 ',
    tipsSuffix: ' 天，到期後自動刪除'
  },
  news: { // 信息悬浮框模块
    All: '全部', // 全部
    SystemNotification: '系統通知', // 系统通知
    OfficialNews: '官方消息', // 官方消息
    ReadAll: '全部已讀', // 全部已读
    Nonews: '暫無消息', // 暂无消息
  },
  getapp: { // 下载客户端模块
    DownloadClient: '立即下載客戶端', // 立即下载客户端
    Experiencemore: '體驗更多雲端新功能', // 体验更多云端新功能
    AndroidClient: 'Android客戶端', // Android客户端
    iOSClient: 'iOS客戶端', // iOS客户端
    WindowsDesktop: 'Windows桌面端', // Windows桌面端
    MacOSDesktop: 'Mac OS桌面端', // Mac OS桌面端,
    DownloadNow: '立即下載', // 立即下载
    ScanQRcodedownload: '掃碼下載', // 扫码下载
  },
  downPage: { // 下载页面模块
    createTime: '創建日期', // 创建日期
    fileSize:'文件大小', // 文件大小
    download: '下載', // 下载
    saveFile: '轉存至雲盤',//转存至云盘
    allFiles: '全部文件',//全部文件
    intrOne0: '手裏有資源想賺收益？其他平臺收益太低？', //手里有资源想赚收益？其他平台收益太低？
    intrOne1: '來飛貓盤上傳文件並分享鏈接或合集給好友下載，就能獲得超高返利！', //来飞猫盘上传文件并分享链接或合集给好友下载，就能获得超高返利！
    intrOne2: '多種分享模式、多重返利方式，讓你天天賺收益！', //多种分享模式、多重返利方式，让你天天赚收益！
    intrTwo0: '大文件下載再也不用等，', //大文件下载再也不用等，
    intrTwo1: '日常轉發的資源、課件資料、辦公軟件，隨用隨下；', //日常转发的资源、课件资料、办公软件，随用随下；
    intrTwo2: '每日可免費下載/轉存文件，大文件一鍵存網盤', //每日可免费下载/转存文件，大文件一键存网盘
    intrThree0: '在線解壓，無需下載輕鬆打開文件；', //在线解压，无需下载轻松打开文件；
    intrThree1: '支持後臺解壓、隊列解壓，打開壓縮包更便捷。', //支持后台解压、队列解压，打开压缩包更便捷。
    intrFour0: '多種音視頻，圖片，壓縮包文件在線預覽；', //多种音视频，图片，压缩包文件在线预览；
    intrFour1: '無需下載，直接查看！', //无需下载，直接查看！
    experienceNow: '立即體驗', // 立即体验
    installPC:'安裝下載飛貓盤客戶端', // 安装下载飞猫盘客户端
    toTop: '回到頂部保存文件', // 回到顶部保存文件
    report: '舉報',//举报
    vipFun: '會員專項功能',//会员专项功能
    pcDown: '通過PC客戶端下載', // 通过PC客户端下载
    pcAppDown: '通過APP下載', // 通过APP下载
    webDown: '網頁端 極速下載', // 网页端 极速下载
    fmpPc: '飛貓盤客戶端',//飞猫盘客户端
    touristDownFile: '非會員下載',//非会员下载
    fileStatus: '文件狀態',//文件状态
    fileDelete: '此文件已被系統審覈過濾或被上傳者刪除，無法訪問',//此文件已被系统审核过滤或被上传者删除，无法访问
    reference: '參考資料', // 参考资料
    question: '如有疑問可訪問', // 如有疑问可访问
    touristFun: '非會員功能', // 非会员功能
    webTouristDown: '網頁端 非會員下載', // 网页端 非会员下载  
    fileDownloadStatus: '文件下載狀態', // 文件下载状态
    downloadComplete: '下載完成', // 下载完成
    downloadError: '下載失敗', // 下载失败
    downloadRetry: '重試', // 下载取消
    memberDownTitStr:'極速下載中',//极速下载中
    touristDownTitStr: '下載中',//下载中
    timeOut: '該文件已過期',//该文件已过期
    downloadNow: '立即極速下載', // 立即极速下载
    isprivateFile: '私有文件暫時無法訪問',//私有文件暂时无法访问
    descPrefix: '本文件由網友：',
    descSuffix: ' 自行上傳，並不代表本站立場'
  },
  setup: { // 账号设置模块
    EditAvatar: '編輯頭像', // 编辑头像
    AccountNumber: '賬號', // 账号
    PetName: '暱稱', // 昵称
    ModifyPhone: '修改手機號', // 修改手机号
    PhoneNumber: '手機號', // 手机号
    WeChat: '微信', // 微信
    LoginPassword: '登錄密碼', // 登录密码
    Setpasswords: '設置包含數字和字母的密碼，並定期更換', // 设置包含数字和字母的密码，并定期更换
    Edit: '編輯', // 编辑
    Bound1: '已綁定', // 已绑定
    Bound2: '綁定', // 绑定
    Bound3: '未綁定', // 未绑定
  },
  Help: { // 帮助中心模块
    FindCustomer: '找客服', // 找客服
    Feedback: '反饋歷史', // 反馈历史
    Protocol: '協議信息', // 协议信息
    Announcement: '平臺公告', // 平台公告
    Burning: '熱門問題', // 热门问题
    Search: '按頻道自主查找', // 按频道自主查找
    Nofeedbackhistory: '暫無反饋歷史', // 暂无反馈历史
  },
  agreement: { // 协议信息模块
    disclaimerAgreement:'《免責協議》', // 《免责协议》
    privacyPolicy: '《隱私政策》', // 《飞猫盘隐私政策》
    userSharingAgreement: '《飛貓盤用戶分享服務協議》', // 《飞猫盘用户分享服务协议》
    memberServiceAgreement: '《飛貓盤會員服務協議》', // 《飞猫盘会员服务协议》
    memberServiceAgreement2: '《飛貓盤會員服務協議》', // 《飞猫盘会员服务协议》
    intellectualPropertyStatement: '《知識產權聲明》', // 《知识产权声明》
    userServiceAgreement:'《用戶服務協議》', // 《飞猫盘用户服务协议》
    automaticRenewalServiceAgreement: '《飛貓盤自動續費服務協議》', // 《飞猫盘自动续费服务协议》
    privacyPolicyforIOS: '《飛貓盤隱私政策》-iOS版', // 《飞猫盘隐私政策》-iOS版
    readAndAgree: '閱讀並同意', // 阅读并同意
    and: '以及', // 以及
    and1: '和', // 和
    disclosureOfContentReviewMechanism: '《飛貓盤內容審覈機制公示》', // 《飞猫盘内容审核机制公示》
    dispositionOfOffendingAccounts: '《對於違規賬號的處置方式》',// 《对于违规账号的处置方式》
    helpCenter: '《飛貓盤幫助中心》',//《飞猫盘帮助中心》
    downPageDisclosureOfContentReviewMechanism:'《飛貓盤內容審覈機制公示》',//《飞猫盘内容审核机制公示》
  },
  chat:{
    StartNewChat:'開啟新對話',//开启新对话
    NoChatHistory:'暫無歷史對話',//暂无历史对话
    MonthlyTokens:'每月Token',//每月Token
    textOne:'Feemoo AI上線啦！',//飞猫盘智能对话上线啦！
    textTwo:'現已接入 DeepSeek R1（🔋滿血版），有任何問題隨時問我哦！',//现已接入 DeepSeek R1 (满血版）有任何问题随时问我哦！
    textThree:'請輸入你的問題，幫你深度解答',//请输入你的问题，帮你深度解答
    textFour:'深度思考（R1）',//深度思考 (R1)
    textFive:'內容由 AI 生成，請仔細甄別',//内容由 Ai 生成，请仔细甄别
    Feed:'意見',//意见
    back:'反饋',//反馈
    Thinking:'思考中',// 思考中
    Copy:'複製',//复制
    Regenerate:'重新生成',//重新生成
    StopGeneration:'停止生成',//停止生成
    Edit:'編輯',//编辑
    Delete:'刪除',//删除
    textSix:'AI新功能：極速通道體驗頂級AI',//AI新功能：免费极速通道体验顶级AI
    FreeTrial:'前往體驗',//免费体验
    EnterYourQuestion:'請輸入你的問題',//请输入你的问题
    AboutToRunOut:'快將用盡，',//即将用尽，
    upgradeVIP:'升級會員',//升级会员
    forMoreTokens:'享更多Token',//享更多Token
    ThinkingStopped:'思考已經停止',//思考已停止
    Thoughtfor:'已經深度思考(用咗',//已深度思考(用时
    seconds:'秒)',//秒
    textSeven:'很抱歉，token 數量暫時唔夠，建議',//很抱歉，token数量暂时不足，建议
    textEight:'很抱歉，token数量已用尽，暂无更多Token',//很抱歉，token数量已用尽，暂无更多Token
    Cancel:'取消',//取消
    Send:'發送',//发送
    textNine:'已經用盡，',//已用尽，
    textEleven:'享更多Token',//享更多Token
    textTen:'已經用盡，暫時冇更多token',//已用尽，暂无更多token
    SubscribeTo:'請',//请
    VIP:'開通會員',//开通会员
    forTokens:'以獲取Token',//获得Token
    toVip:'升級會員',///升级会员
    Copied:'複製成功',//复制成功
    CopyFailed:'複製失敗',//复制失败
    textTwelve:'升級會員',//升级会员
  },
};

