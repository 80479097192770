import Hljs from 'highlight.js';
import 'highlight.js/styles/stackoverflow-dark.css';
import { lineNumberInit } from "../utils/highlight-line-numbers.js";
lineNumberInit(Hljs, window, window.document);
const Highlight = {
  install(Vue) {
    Vue.directive('highlight', {
      inserted(el) {
        const blocks = el.querySelectorAll('pre code');
        blocks.forEach((block) => {
          Hljs.highlightBlock(block);
          block.classList.add('hljs');
          // Hljs.lineNumbersBlock(block);
        });
      },
    });
  },
};

export default Highlight;
