<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
const rrweb = require("rrweb");
import { EventBus } from "./views/DownPage/EventBus.js";
export default {
  created() {
    document.title = this.$t('common.title');
  },
  data: {
    return: {
      events: [],
      stopFn: null,
    },
  },
  computed: {
    currentTheme() {
      return this.$route.name == 'login' || this.$route.name == 'HomePage'  ? 'light': this.$store.state.theme; // 从Vuex中获取当前的主题值
    },
  },
  watch: {
    currentTheme(newVal, oldVal) {
      const bodyElement = document.body;
      bodyElement.classList.remove(oldVal);
      bodyElement.classList.add(newVal);
      bodyElement.setAttribute("data-theme", newVal);
    },
  },
  mounted() {
    const bodyElement = document.body;
    bodyElement.setAttribute("data-theme", this.currentTheme);
    bodyElement.classList.add("body-theme");
    if (this.currentTheme == "light") {
      bodyElement.classList.add("light");
      bodyElement.classList.remove("dark");
    } else {
      bodyElement.classList.remove("light");
      bodyElement.classList.add("dark");
    }
    this.$store.commit("setAttr", {
      name: "onStartRecord",
      val: this.onStartRecord,
    });
    this.$store.commit("setAttr", {
      name: "onEndRecord",
      val: this.onEndRecord,
    });

    //修改fmPath在这将store中路由名称替换成需要修改的路由
    //  if (this.$store.state.fmPath == "fmp") {
    this.$store.commit("setAttr", {
      name: "fmPath",
      val: "drive",
    });
    // }
    //禁用el-dialog、el-message、滑块、el-tooltip__popper、el-loading-mask的右键事件
    this.disableContextMenu = (e) => {
      // 只拦截目标元素，避免全局拦截
      if (
        e.target.closest('.el-dialog, .el-dialog__wrapper') || 
        e.target.closest('.el-message, .el-message__wrapper') ||
        e.target.closest('.window-show, .mask-show') || 
        e.target.closest('.el-tooltip__popper', 'el-loading-mask')
      ) {
        e.preventDefault();
      }
    };
    document.addEventListener('contextmenu', this.disableContextMenu);
  },
  beforeDestroy() {
    // 移除监听
    document.removeEventListener('contextmenu', this.disableContextMenu);
  },
  methods: {
    checkDarkMode() {
      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
      } else {
      }
    },
    onStartRecord() {
      this.events = [];
      let _that = this;
      this.stopFn = rrweb.record({
        emit(event) {
          _that.events.push(event);
          EventBus.$emit("updateData", _that.events);
        },
        plugins: [
          rrweb.getRecordConsolePlugin({
            level: ["info", "log", "warn", "error"],
            lengthThreshold: 10000,
            stringifyOptions: {
              stringLengthLimit: 1000,
              numOfKeysLimit: 100,
              depthOfLimit: 1,
            },
            logger: window.console,
          }),
        ],
      });
    },
    onEndRecord() {
      if(this.stopFn){
        this.stopFn();
      }
    },
  },
};
</script>

<style lang="scss">
#app {
  @include useTheme {
    // background: getVar("bg-color");
    color: getVar("text-color2-2c2c2c");
  }
  .app-container {
    @include useTheme {
      background: getVar("bg-color");
    }
  }
}
</style>
