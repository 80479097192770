<template>
  <div class="chatLeft" v-loading="chatLoading"           @mouseleave="handleMouseleave2($event)">
    <!-- 开启新对话 -->
    <div class="addChatBt" @click="addChat">
      <svg-btn class="icon" icon-class="add-chat" />
      <span class="add">{{ $t('chat.StartNewChat') }}</span>
    </div>
    <!-- 列表 -->
    <div
      class="chatList"
      :class="chatLength == 0 ? 'notCenter' : ''"
      v-infinite-scroll="getList"
      infinite-scroll-immediate="false"
      :infinite-scroll-disabled="disabled"
      infinite-scroll-distance="50"
    >
      <div
        v-for="(group, tag, index) in groupList"
        :key="tag"
        class="chat"
        :class="index == chatLength - 1 ? 'noBottom' : ''"
        v-show="chatLength > 0"
      >
        <div class="time">{{ tag }}</div>
        <div
          v-for="(item, idx) in group"
          @mouseenter="handleMouseenter($event, tag, idx)"
          @mouseleave="handleMouseleave($event)"
          @click="checkChat(tag, idx, item)"
          :key="item.id"
          class="content"
          :class="{
            content1: hoverIndex == idx && tagName == tag,
            content2: item.isInput,
            content3: item.isHover,
          }"
        >
          <el-tooltip
            open-delay="1000"
            popper-class="deepseek-left"
            effect="dark"
            :content="item.title"
            placement="bottom-end"
          >
            <div
              ref="textElement"
              :data-date="tag"
              :data-idx="idx"
              v-show="!item.isInput"
              :class="{
                text: true,
                text1:
                  hoverIndex == idx &&
                  tagName == tag,
                text2: isOverflowed[tag] && isOverflowed[tag][idx],
              }"
            >
              {{ item.title }}
            </div>
          </el-tooltip>
          <div class="textInput" v-show="item.isInput" @click.stop>
            <el-input
              :ref="'oneInput' + item.id"
              autofocus
              maxlength="200"
              @blur.stop="inputBlur(event, tag, idx)"
              @keydown.enter.native="handleEnter(tag, idx)"
              v-model="item.title"
            >
            </el-input>
          </div>
          <div
            class="threeBt"
            @click.stop
            v-if="
              ((hoverIndex == idx && tagName == tag) || item.isHover) &&
              !item.isInput
            "
          >
            <el-dropdown
              :ref="'myDropdown' + idx"
              placement="bottom-end"
              @command="command => handleCommand(command, tag, item, idx)"
              :popper-options="{ boundariesElement: 'viewport' }"
              :append-to-body="true"
              @visible-change="handleChange"
              trigger="click"
            >
              <div class="moreBox">
                <svg-btn class="more" icon-class="icon-deepseek-more"></svg-btn>
              </div>
              <div class="botBox"></div>
              <el-dropdown-menu class="moreAct" slot="dropdown">
                <el-dropdown-item command="a">
                  <svg-btn
                    class="icon-deepseek"
                    icon-class="icon-deepseek-edit"
                  />
                  {{ $t('cloud.rightRename') }}
                </el-dropdown-item>
                <el-dropdown-item class="delete" command="b">
                  <svg-btn
                    class="icon-deepseek"
                    icon-class="icon-multiple-delete"
                  />
                  <span>{{ $t(`setPage.Delete`) }}</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div v-show="chatLength == 0 && !chatLoading" class="notChat">
        {{ $t('chat.NoChatHistory') }}
      </div>
    </div>
    <div class="buttomBox space-box">
      <!-- 每月token -->
      <!-- <div
        class="tokenBox"
        :class="usedPre > 90 || isVip === 0 ? 'tokenUP' : ''"
      >
        <div class="tokenNum">
          <span
            class="font1"
            :class="$store.state.language == 'en' ? 'font3' : ''"
            >{{ $t('chat.MonthlyTokens') }}</span
          >
          <span
            class="font2"
            :class="$store.state.language == 'en' ? 'font3' : ''"
            >{{ formatNumber(use_token) }}/{{ formatNumber(token_quota) }}</span
          >
        </div>
        <el-progress
          :percentage="usedPre > 100 ? 100 : usedPre"
          :stroke-width="4"
          :text-inside="true"
        ></el-progress>
        <div v-show="isUp" class="upgrade">
          <span class="font">{{ $t('chat.AboutToRunOut') }}</span>
          <span class="font color" @click="openVip">{{
            $t('chat.upgradeVIP')
          }}</span>
          <span class="font">{{ $t('chat.forMoreTokens') }}</span>
        </div>
        <div v-show="isVip === 0" class="upgrade">
          <span class="font">{{ $t('chat.SubscribeTo') }}</span>
          <span v-if="$store.state.language == 'en'">&nbsp;</span>
          <span class="font color" @click="openVip">{{ $t('chat.VIP') }}</span>
          <span v-if="$store.state.language == 'en'">&nbsp;</span>
          <span class="font">{{ $t('chat.forTokens') }}</span>
        </div>
        <div v-show="usedPre >= 100 && isUpVip == 0" class="upgrade">
          <span class="font">{{ $t('chat.textTen') }}</span>
        </div>
        <div v-show="usedPre >= 100 && isUpVip == 1" class="upgrade">
          <span class="font">{{ $t('chat.textNine') }}</span>
          <span class="font color" @click="openVip">{{
            $t('chat.upgradeVIP')
          }}</span>
          <span v-if="$store.state.language == 'en'">&nbsp;</span>
          <span class="font">{{ $t('chat.textEleven') }}</span>
        </div>
      </div> -->
      <!-- 切换模式 -->
      <div class="themeBox">
        <div
          v-for="item in themeList"
          class="one"
          :class="{ one2: $store.state.themeBtn == item }"
          @click="changeTheme(item)"
          :key="item"
        >
          <svg-btn :icon-class="'icon-switch-' + item" />
        </div>
      </div>
    </div>
    <!-- 删除对话提示框 -->
    <TitleDialog
      ref="TitleDialogRef"
      :is-show="iShow"
      title="永久删除对话"
      text="删除后不可恢复，确定删除吗？"
      confirm="确定删除"
      @handleClose="onClose"
      @handleConfirm="onConfirm"
    >
    </TitleDialog>
    <BindPhone
      :isShow="isShowBindPhone"
      :type="bindType"
      :time="time"
      @getPhoneCode="getPhoneCode"
      @bindPhone="bindPhone"
      @confirmNameAuth="realNameAuth"
      @handleClose="isShowBindPhone = false"
    ></BindPhone>
  </div>
</template>

<script>
import {
  getChatList,
  deleteChat,
  editChat,
  getTokenNum,
} from '@/utils/apiList/chat'
import {
  bindPhoneAPI,
  bindPhoneSendCode,
  realNameAuth,
} from '@/utils/apiList/account'
import { EventBus } from '@/views/DownPage/EventBus.js'
import TitleDialog from '@/components/Dialog/TitleDialog.vue'
import BindPhone from '@/components/Cloud/BindPhone'
export default {
  components: {
    TitleDialog,
    BindPhone,
  },
  data() {
    return {
      themeList: ['system', 'light', 'dark'],
      use_token: 0,
      token_quota: 0,
      usedPre: 0,
      isVip: '',
      chatList: [],
      groupList: {},
      chatLoading: true, // 是否正在加载
      noMore: false, // 是否还有更多数据
      page: 1,
      limit: 50,
      isUpVip: 0,
      hoverIndex: '', //判断鼠标移入了哪个index
      tagName: '', //判断鼠标移入了哪个taga
      isOverflowed: {},
      iShow: false,
      handleItem: {}, //当前操作项
      isAnswer: false,
      isDropdownOpen: false,
      idAdd: false, //是否新增对话
      bindType: 1,
      isShowBindPhone: false,
      time: 60,
      msgid: '',
      timerA: null,
      phone_item: {
        phone: '',
        pcode: '',
      },
      isLogin: true,
    }
  },
  watch: {
    groupList: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.$nextTick(this.checkOverflow)
        })
      },
    },
  },
  created() {
    // 监听添加token事件
    EventBus.$on('addToken', value => {
      let num = this.use_token + Number(value)
      //如果计算出来的时候大于所有token总量，则等于token总量
      if (num < this.token_quota) {
        this.use_token = num
      } else {
        this.use_token = this.token_quota
      }
      if (this.token_quota !== 0) {
        this.usedPre = (this.use_token / this.token_quota) * 100
      }
    })
    // 监听是否还在回答事件
    EventBus.$on('isAnswer', value => {
      this.isAnswer = value
    })
    // 监听是否还在新增会话
    EventBus.$on('addChatList', value => {
      this.hoverIndex = ''
      this.tagName = ''
      this.idAdd = true
      this.setFalse()
      this.page = 1
      this.limit = 1
      this.noMore = false
      this.getList()
    })
    // 监听右组件是否点击了开启新对话
    EventBus.$on('delHover', value => {
      this.setFalse()
      this.hoverIndex = ''
      this.tagName = ''
    })
    // 监听右组件有对话在进行
    EventBus.$on('haveChat', value => {
      this.haveChat(value)
    })
  },
  mounted() {
    this.getList()
    this.getToken()
    this.listenTheme()
  },
  beforeDestroy() {
    // 组件销毁前移除事件监听
    EventBus.$off('addToken')
    EventBus.$off('isAnswer')
    EventBus.$off('addChatList')
    EventBus.$off('delHover')
    EventBus.$off('haveChat')

  },
  computed: {
    chatLength() {
      return Object.keys(this.groupList).length
    },
    disabled() {
      return this.chatLoading || this.noMore
    },
    isUp() {
      return (
        this.isUpVip == 1 &&
        this.usedPre > 90 &&
        this.usedPre < 100 &&
        this.token_quota > 0
      )
    },
  },
  methods: {
    //初始化数据
    getList: _.debounce(
      async function () {
        if (this.noMore) return
        this.chatLoading = true
        try {
          let res = await getChatList({
            page: this.page,
            limit: this.limit,
          })
          if (res.status == '1') {
            res.data.list.forEach(item => {
              item.isHover = false
              item.isInput = false
              item.titleTwo = item.title
            })
            if (this.idAdd) {
              //新增会话
              this.chatList.unshift(res.data.list?.[0])
            } else {
              if (this.page == 1) {
                this.chatList = res.data.list
              } else {
                this.chatList = this.chatList.concat(res.data.list)
              }
              this.page++
            }
            this.noMore = this.chatList.length >= res.data.total
            this.groupDataByTag()
          } else {
            this.chatList = []
            this.chatLoading = false
          }
        } catch (e) {}
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //获取用户token
    getToken() {
      getTokenNum()
        .then(res => {
          if (res.status == 1) {
            this.isVip = Number(res.data.isVip)
            this.use_token = Number(res.data.use_token)
            this.token_quota = Number(res.data.token_quota)
            this.isUpVip = res.data.isUpVip
            //计算比例
            if (this.token_quota !== 0) {
              this.usedPre = (this.use_token / this.token_quota) * 100
            }
            if(this.token_quota == 0){
              EventBus.$emit('noVip', true)
            }
          } else if (res.status == 10020) {
            this.isLogin = false
            EventBus.$emit('isLogin', false)
          }
        })
        .catch(error => {})
    },
    //处理数据
    groupDataByTag() {
      this.groupList = {}
      const grouped = {}
      this.chatList.forEach(item => {
        const tag = item.tag
        if (!grouped[tag]) {
          grouped[tag] = []
        }
        grouped[tag].push(item)
      })
      this.groupList = grouped
      //添加新对话
      if (this.idAdd) {
        this.groupList['今天'][0].isHover = true
        this.idAdd = false
        this.limit = 50
      }
      const chatId = localStorage.getItem('chat_id')
      if (chatId) {
        for (const key in this.groupList) {
          if (this.groupList.hasOwnProperty(key)) {
            this.groupList[key].forEach(item => {
              if (item.id == chatId) {
                item.isHover = true
              }
            })
          }
        }
      }
      //使用双层 nextTick 确保 DOM 更新
      this.$nextTick(() => {
        this.$nextTick(this.checkOverflow)
      })
      this.chatLoading = false
    },
    //判断出不出现遮罩伪类
    checkOverflow() {
      // 添加容错判断
      if (!this.$refs.textElement || !Array.isArray(this.$refs.textElement))
        return

      const overflowState = {}

      this.$refs.textElement.forEach(el => {
        const tag = el.dataset.date
        const idx = parseInt(el.dataset.idx, 10)

        // 动态初始化数据结构
        if (!overflowState[tag]) overflowState[tag] = []

        // 检测溢出并记录状态
        overflowState[tag][idx] = el.scrollWidth > el.clientWidth
      })

      // 使用 Vue.set 确保响应式更新
      Object.keys(overflowState).forEach(tag => {
        if (!this.isOverflowed[tag]) {
          this.$set(this.isOverflowed, tag, [])
        }
        overflowState[tag].forEach((value, idx) => {
          this.$set(this.isOverflowed[tag], idx, value)
        })
      })
    },
    //更多
    handleCommand: _.debounce(
      function (command, tag, item, idx) {
        if (this.checkPhoneEmpty()) {
          this.bindType = 1
          this.isShowBindPhone = true
          return
        }
        // if (this.isAnswer) {
        //   this.$toast('回答输出中，请稍后操作或点击停止生成', 2)
        //   return
        // }
        this.handleItem = item
        if (command == 'a') {
          // this.setFalse()
          this.groupList[tag][idx].isInput = true
          this.$nextTick(() => {
            this.$refs['oneInput' + item.id][0].focus()
          })
        } else {
          this.iShow = true
        }
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //输入框失焦事件
    inputBlur(event, tag, idx) {
      this.editChat(tag, idx)
    },
    //输入框回车事件
    handleEnter(tag, idx) {
      this.editChat(tag, idx)
    },
    editChat: _.debounce(
      function (tag, idx) {
        if (this.handleItem.title == '' || this.handleItem.title.trim().length == 0) {
          //没有title为空
          this.handleItem.title = this.handleItem.titleTwo
          this.groupList[tag][idx].isInput = false
          return
        }
        if (this.handleItem.titleTwo == this.handleItem.title) {
          //没有修改
          this.groupList[tag][idx].isInput = false
          return
        }
        editChat({
          id: this.handleItem.id,
          title: this.handleItem.title,
        })
          .then(res => {
            if (res.status == 1) {
              this.noMore = false
              this.page = 1
              this.chatList = []
              this.groupList = {}
              this.getList()
            } else if (res.status == 0) {
              this.$toast(res.msg, 2)
            }
          })
          .catch(error => {})
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //关闭删除点击确认事件
    onConfirm: _.debounce(
      function () {
        deleteChat({
          id: this.handleItem.id,
        })
          .then(res => {
            if (res.status == 1) {
              const chatId = localStorage.getItem('chat_id')
              this.page = 1
              this.noMore = false
              this.chatList = []
              this.hoverIndex = ''
              this.tagName = ''
              this.groupList = {}
              this.getList()
              this.onClose()
              if (chatId == this.handleItem.id) {
                EventBus.$emit('delChat')
              }
            } else if (res.status == 0) {
              this.$toast(res.msg, 2)
            }
          })
          .catch(error => {})
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //关闭删除弹窗
    onClose() {
      this.hoverIndex = ''
      this.tagName = ''
      this.iShow = false
    },
    //鼠标移入
    handleMouseenter(event, tag, index) {
      this.tagName = tag
      this.hoverIndex = index
      this.$nextTick(() => {
        this.$nextTick(this.checkOverflow)
        this.$refs['myDropdown' + index][0]?.hide()
      })
    },
    //鼠标移除
    handleMouseleave() {
      if (!this.isDropdownOpen) {
        this.hoverIndex = ''
        this.tagName = ''
        this.$nextTick(() => {
          this.$nextTick(this.checkOverflow)
        })
      }
    },
    handleMouseleave2(){
      this.isDropdownOpen = false
    },
    //下拉框出现/隐藏事件
    handleChange(visible) {
      this.isDropdownOpen = visible
    },
    //开启新对话
    addChat: _.debounce(
      function () {
        if (!this.isLogin) {
          this.$router.push({ path: '/login?path=ai' })
          return
        }
        if (this.checkPhoneEmpty()) {
          this.bindType = 1
          this.isShowBindPhone = true
          return
        }
        // if (this.isAnswer) {
        //   this.$toast('回答输出中，请稍后操作或点击停止生成', 2)
        //   return
        // }
        this.setFalse()
        this.hoverIndex = ''
        this.tagName = ''
        EventBus.$emit('addChat', true)
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //处理数字
    formatNumber(value) {
      return value.toLocaleString('en-US')
    },
    //升级会员
    openVip() {
      if (!this.isLogin) {
        this.$router.push({ path: '/login?path=ai' })
        return
      }
      if (this.checkPhoneEmpty()) {
        this.bindType = 1
        this.isShowBindPhone = true
        return
      }
      window.open('/vip', '_blank')
    },
    //把所有isHover 设为 false
    setFalse() {
      for (const key in this.groupList) {
        if (this.groupList.hasOwnProperty(key)) {
          this.groupList[key].forEach(item => {
            item.isHover = false
          })
        }
      }
    },
    //点击对话
    checkChat: _.debounce(
      function (tag, idx, item) {
        if (this.checkPhoneEmpty()) {
          this.bindType = 1
          this.isShowBindPhone = true
          return
        }
        // if (this.isAnswer) {
        //   this.$toast('回答输出中，请稍后操作或点击停止生成', 2)
        //   return
        // }
        this.setFalse()
        this.groupList[tag][idx].isHover = true
        localStorage.setItem('chat_id', item.id)
        EventBus.$emit('chatDetail', item.id)
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    async haveChat(id) {
      this.setFalse()
      let found = false
      // 遍历 groupList
      for (const [tag, items] of Object.entries(this.groupList)) {
        const idx = items.findIndex(item => item.id == id)
        if (idx !== -1) {
          this.groupList[tag][idx].isHover = true
          localStorage.setItem('chat_id', id)
          EventBus.$emit('chatDetail', id,1)
          found = true
          break
        }
      }

      // 如果没有找到，调用 getList 并继续查找
      if (!found) {
        this.noMore = false
        this.page = 1
        this.chatList = []
        this.groupList = {}
        await this.getList()
        for (const [tag, items] of Object.entries(this.groupList)) {
          const idx = items.findIndex(item => item.id == id)
          if (idx !== -1) {
            this.groupList[tag][idx].isHover = true
            localStorage.setItem('chat_id', id)
            EventBus.$emit('chatDetail', id,1)
            break
          }
        }
      }
    },
    //判断手机号是否为空
    checkPhoneEmpty() {
      // 从 store 中获取 userInfo 对象
      const userInfo = this.$store.state.userInfo
      // 检查 phone 属性是否为空字符串
      if (userInfo && userInfo.phone == '') {
        return true
      }
      return false
    },
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      }
      this.getPhoneAly()
    },
    getPhoneAly(captcha_verify) {
      return new Promise(resolve => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || '',
        }).then(res => {
          if (res.status == '1') {
            resolve({
              result: true,
              validate: true,
            })
            this.$toast(res.msg, 1)
            this.msgid = res.data.msgid
            this.bindType = 3
            this.timerA = setInterval(() => {
              this.minute()
              this.$refs.bindPhoneCom?.onFocus()
            }, 1000)
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            })
            this.$toast(res.msg, 2)
            this.$AliCode.handleOpen(this.getPhoneAly)
          } else {
            resolve({
              result: true,
              validate: true,
            })
            this.$toast(res.msg, 2)
          }
        })
      })
    },
    minute() {
      this.time = this.time - 1
      if (this.time == 0) {
        this.time = 60
        clearInterval(this.timerA)
      }
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data }
      bindPhoneAPI(params).then(res => {
        if (res.status == '1') {
          this.isShowBindPhone = false
          this.$store.commit('getuserVuex')
          this.$toast(res.msg, 1)

          if (this.$store.state.bindPhoneCallback) {
            this.$store.state.bindPhoneCallback()
          }
        } else {
          this.$toast(res.msg, 2)
        }
      })
    },
    realNameAuth(data) {
      realNameAuth(data).then(res => {
        if (res.status == '1') {
          this.isShowBindPhone = false
          this.$store.commit('getuserVuex')
          this.$toast(res.msg, 1)
        } else {
          this.$toast(res.msg, 2)
        }
      })
    },
    changeTheme(item) {
      this.$store.commit('setAttr', {
        name: 'themeBtn',
        val: item,
      })
      if (item == 'light' || item == 'dark') {
        this.$utils.changeTheme(item)
      } else {
        this.listenTheme(1)
      }
    },
    listenTheme(index = 0) {
      const darkModeMediaQuery = window.matchMedia(
        '(prefers-color-scheme: dark)'
      )
      if (this.$store.state.themeBtn == 'system' && index == 1) {
        if (darkModeMediaQuery.matches) {
          this.$utils.changeTheme('dark')
        } else {
          this.$utils.changeTheme('light')
        }
      }
      const darkModeChanged = e => {
        // 当深色模式状态发生变化时，执行相应的操作
        if (this.$store.state.themeBtn == 'system') {
          if (darkModeMediaQuery.matches) {
            this.$utils.changeTheme('dark')
          } else {
            this.$utils.changeTheme('light')
          }
        }
      }
      darkModeMediaQuery.addListener(darkModeChanged)
    },
  },
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
<style lang="scss">
.deepseek-left {
  max-width: 500px;
  background: #2c2c2c;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 5px 8px;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  line-height: 20px;
  margin-top: 15px !important;
  .popper__arrow {
    display: none;
  }
}
</style>
