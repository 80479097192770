<template>
  <div
    class="box"
    v-loading="true"
    element-loading-text="登录中..."
    element-loading-background="rgba(255, 255, 255, 255)"
  ></div>
</template>

<script>
import { getUrl, getSyncLogin } from "@/utils/apiList/login";
export default {
  name: "Jmdyh",
  data() {
    return {
      platformname: "",
      platfromUrl: "",
      platfromUrl2: "",
      platfro: "",
      token: ""
    };
  },
  created() {
    this.token = this.$route.query.token || ''
    this.platformname = localStorage.getItem("platformname2") || "飞猫盘海外版";
    this.platfromUrl = localStorage.getItem("platfromUrl2") || "";
    this.platfromUrl2 = localStorage.getItem("platfropath") || "drive";
    this.getSyncLoginFn();
  },
  computed: {},
  mounted() {},
  beforeDestroy() {},
  watch: {},
  methods: {
    getSyncLoginFn() {
      getSyncLogin({token:this.token})
        .then(async (res) => {
          localStorage.removeItem("platformname2");
          localStorage.removeItem("platfromUrl2");
          localStorage.removeItem("platfropath");
          if (res.status == 1) {
            if (res.data.sso && res.data.sso.length > 0) {
              await Promise.all(
                res.data.sso.map((item) => {
                  this.platfro = item.token;
                  localStorage.setItem("platfro", item.token);
                  getUrl(item.url, { token: item.token }).then((res) => {});
                })
              );
            }

            // if(res.data.auth_sso && res.data.auth_sso.length > 0){
            //     localStorage.setItem('auth_sso', JSON.stringify(res.data.auth_sso))
            //     res.data.auth_sso.forEach(item => {
            //       localStorage.setItem("platfro", item.token);
            //       this.platfro = item.plat_token
            //     })
            //   }
            localStorage.setItem('loginflag',1)

            setTimeout(() => {
              if ( this.platformname != "飞猫盘海外版" ) {
                window.location.href =
                  this.platfromUrl + `?platfro=${this.platfro}`;
              } else {
                this.$router.push({ path: `/${this.platfromUrl2}` });
              }
            }, 500);
          } else {
            // 同步登录失败就调到主站列表页，如果未登录的情况下，又会跳往登录页
            this.$router.push({ path: "/drive" });
          }
        })
        .catch((err) => {});
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100vh;
}
::v-deep .el-loading-spinner {
  .el-loading-text {
    color: #f67942;
  }
  .circular {
    width: 28px;
    height: 28px;
  }
}
</style>
