<template>
  <div class="electron-box">
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      top="0vh"
      :show-close="false"
      :visible.sync="isElectron"
      width="480px"
    >
      <div class="d-header">
        <div class="leftOne">
          <img class="d-icon" :src="$utils.getPng('web4/icon-circle-fill')" />
          <div class="d-tit">客户端下载</div>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="cen1" style="margin-bottom: 24px" v-if="type == 1">
        <div style="font-size: 16px;">一键唤起客户端，感受全新下载体验。</div>
        <div style="margin-top: 12px">
          若未安装客户端，<a :href="exe" @click="downloadLink"
            >请点击下载客户端。</a
          >
        </div>
      </div>
      <div class="cen2" v-if="type == 2">
        <div class="huanqi">
          <svg-btn class="icon-elec" icon-class="icon-electron" />
          正在唤起客户端…
        </div>
      </div>
      <div class="cen1" v-if="type == 3">
        <div>客户端唤起失败，请再次点击打开客户端或检查是否安装</div>
        <div>
          若未下载，<a :href="exe" @click="downloadLink">请点击下载客户端。</a>
        </div>
      </div>
      <div
        class="btns btn-box"
        style="display: flex; justify-content: flex-end; margin-top: 24px"
      >
        <fm-button
          size="medium"
          :class="type == 2 ? 'opacity background' : ''"
          @click="handleOK()"
          >{{
            type == 1 ? "打开客户端下载" : type == 2 ? "正在唤起" : "打开客户端"
          }}</fm-button
        >

        <fm-button
          size="medium"
          type="info"
          plain
          style="margin-left: 16px"
          :class="type == 2 ? 'opacity bg1' : ''"
          @click="handleClose"
          >取 消</fm-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { clientDownUrlAPI } from "@/utils/apiList/home";
import { getWebarouseAPI, webarouseAPI } from "@/utils/apiList/downpage";

export default {
  props: {
    isElectron: {
      type: Boolean,
      default: false,
    },
    code: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      type: 1,
      intervalId: null,
      currentTime: 0,
      timeCode: "",
      exe: "",
    };
  },
  // mounted() {
  //   this.Dow();
  // },
  watch:{
    isElectron(newVal, oldVal){
      if(newVal && !this.exe){
       this.Dow();
      }
    }
  },
  methods: {
    downloadLink() {
      setTimeout(() => {
        // this.$utils.newReported("C/1-5-10-019");
      }, 300);
    },
    handleOK() {
      this.timeCode = this.generateRandomNumber();
      try {
        if (this.type == 2) {
          return;
        }
        this.type = 2;
        window.location.href = `feemooApp://open?code=${this.code}#code2=${this.timeCode}`;

        getWebarouseAPI({ code: this.timeCode }).then((res) => {
          if (res.status == 1) {
            if (res.data.status == 2) {
              clearInterval(this.intervalId); // 销毁之前清除定时器
              this.currentTime = 0;
              this.$emit("Visible", false);
              this.type = 1;
            } else {
            }
          }
        });

        webarouseAPI({
          code: this.timeCode,
          status: 1,
        }).then((res) => {
          if (res.status == 1) {
            if (res.data.status == 1) {
              this.intervalId = setInterval(() => {
                this.getCurrentTime();
              }, 1000); // 每隔5秒钟调用一次getCurrentTime方法
            }
          }
        });

      } catch (error) {
        // 处理异常
        console.error(error);
      }
    },
    Dow() {
      try {
        clientDownUrlAPI().then((res) => {
          // this.exe = res?.data;
          let isMac = /macintosh|mac os x/i.test(navigator.userAgent); //苹果
          if (isMac) {
            this.exe = res?.data.mac_url;
          } else {
            this.exe = res?.data.win_url;
          }
        });
      } catch (error) {
        // 处理异常
        console.error(error);
      }
    },
    // 随机时间戳参数
    generateRandomNumber() {
      const currentMilliTime = new Date().getTime();
      const randomNum = Math.floor(Math.random() * 1000000);
      const combinedString = `${currentMilliTime}${randomNum}`;
      return combinedString;
    },
    getCurrentTime() {
      if (this.currentTime > 9) {
        clearInterval(this.intervalId); // 销毁之前清除定时器
        this.currentTime = 0;
        this.type = 3;
      } else {
        getWebarouseAPI({ code: this.timeCode }).then((res) => {
          if (res.status == 1) {
            if (res.data.status == 2) {
              clearInterval(this.intervalId); // 销毁之前清除定时器
              this.currentTime = 0;
              this.$emit("Visible", false);
              this.type = 1;
            } else {
            }
          }
        });

        this.currentTime++;
      }
    },
    handleClose() {
      if (this.type == 2) {
        return;
      }
      this.$emit("Visible", false);
      setTimeout(() => {
        this.type = 1;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  height: auto;
}
::v-deep .el-dialog__body {
  display: flex;
  flex-direction: column;
}
.opacity {
  opacity: 0.3 !important;
}
.background {
  background-color: #f67942 !important;
  color: #ffffff !important;
  cursor: default;
}
.bg1 {
  cursor: default;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.leftOne {
  display: flex;
  align-items: center;
  .d-tit {
    font-size: 16px;
    font-weight: 500;
    @include useTheme {
      color: getVar("text-color1-2c2c2c");
    }
  }

  .d-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}

.cen2 {
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #f67942;
  line-height: 22px;
  margin-top: 32px;

  .huanqi {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    img {
      width: 16px;
      height: 16px;
      margin-right: 8px;
      animation: spin 2s linear infinite;
    }
    .icon-elec {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}
.cen1 {
  text-align: center;
  height: 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 32px;

  @include useTheme {
    color: getVar("text-color1-2c2c2c");
  }
  a {
    // text-decoration: underline;
    color: #f67942;
  }
}

.btn1 {
  width: 120px;
  height: 36px;
  line-height: 36px;
  margin-right: 16px;
  text-align: center;
}
.btn2 {
  width: 110px;
  height: 36px;
  line-height: 36px;
  text-align: center;
}
</style>
