<!--
 * @FilePath: /www.feimaoyun.com_html_source/src/views/DownPage/compoents/pageMode1.vue
 * @Description: 无收益模式
-->

<template>
  <div
    class="downPage pageMode1"
    v-loading="showCheckPucode == -1"
    ref="scrollBox"
    @scroll="scrollBoxFun"
    @mousemove.prevent="selectItems"
    @contextmenu.prevent
  >
    <div class="pageBox1">
      <div v-show="showCheckPucode == 1" class="down-content">
        <div class="fileBox">
          <div class="fileList">
            <div class="fileInfoBox" @contextmenu.prevent="showMenu($event)" style="padding-bottom: 20px">
              <div class="topBox" v-if="skeletonLoading">
                <el-skeleton-item class="icon" variant="p" />
                <div class="infoTop" style="width: 400px">
                  <el-skeleton-item variant="p" style="width: 40%" />
                  <el-skeleton-item
                    variant="p"
                    style="width: 100%; margin-top: 10px"
                  />
                </div>

                <div class="btnBox">
                  <div
                    class="btnTop1"
                    @click="openMemPopUp(1)"
                    style="margin-right: 11px"
                  >
                    <fm-button
                      icon="icon-down-mode2"
                      type="info"
                      plain
                      round
                      size="medium"
                      >{{ $t('downPage.download') }}</fm-button
                    >
                  </div>
                  <div class="btnTop1" @click="openMemPopUp(0)">
                    <fm-button icon="icon-pagea3" round size="medium"
                      >{{ $t('downPage.saveFile') }}</fm-button
                    >
                  </div>
                </div>
              </div>
              <div class="topBox" v-else>
                <img
                  class="icon"
                  v-if="fileInfo.ext_icon"
                  :src="fileInfo.ext_icon"
                />
                <div class="infoTop">
                  <div>
                    <div class="collectBox">
                      <!-- 文件名和后缀名 -->
                      <span class="name">
                        {{ fileInfo.name }}{{ fileInfo.extension }}
                      </span>
                      <!-- 收藏图标 -->
                      <svg-btn @click="collect" class="collect" :icon-class="collectShow == 1?'icon-collect-two':'icon-collect-one'"></svg-btn>
                    </div>
                  </div>

                  <div class="descBox">
                    <span>{{ $t('downPage.createTime') }}：{{ fileInfo.intime }}</span>
                    <span class="desc">{{ $t('downPage.descPrefix') + fileInfo.nickname + $t('downPage.descSuffix') }}</span>
                    <!-- <span class="desc"
                      >
                      {{ $store.state.language == 'en' ? `File uploaded by netizen: ${
                        fileInfo.username
                      }, does not represent the position of this site` :`本文件由网友：${
                        fileInfo.username
                      }
                      自行上传，并不代表本站立场` }}
                      </span
                    > -->
                    <span class="report" @click="goReport">{{ $t('downPage.report') }}</span>
                  </div>
                </div>

                <div class="btnBox">
                  <div
                    class="btnTop1"
                    @click="openMemPopUp(1)"
                    style="margin-right: 11px"
                  >
                    <fm-button
                      icon="icon-down-mode2"
                      type="info"
                      plain
                      round
                      size="medium"
                      >{{ $t('downPage.download') }}</fm-button
                    >
                  </div>
                  <div class="btnTop1" @click="openMemPopUp(0)">
                    <fm-button icon="icon-pagea3" round size="medium"
                      >{{ $t('downPage.saveFile') }}</fm-button
                    >
                  </div>
                </div>
              </div>
              <div class="centerBox">
                <div class="all">{{ $t('downPage.allFiles') }}</div>
                <div class="num">1</div>
              </div>
              <div class="fileTable">
                <FileTable
                  ref="fileTable"
                  :skeletonLoading="skeletonLoading"
                  @goDownload="goDownload"
                  @openMemPopUp="openMemPopUp"
                  @collect="collect"
                ></FileTable>
              </div>
            </div>
          </div>
          <div class="adBox" v-if="skeletonLoading">
            <el-skeleton-item
              class="ad1 pointer"
              style="background: #fff; height: 232px; border-radius: 12px"
              variant="p"
            />
            <el-skeleton-item
              class="ad1 pointer"
              style="background: #fff; height: 108px; border-radius: 12px"
              variant="p"
            />
          </div>
          <div class="adBox" v-else>
            <img
              class="ad1 pointer"
              v-for="(item, index) in rebate_model_ads"
              :key="index"
              :src="item.cover"
              @click="toAdLink(item)"
            />
          </div>
        </div>
        <div class="fmpMore">
          <div class="moreTop">
            <div class="fmTit">
            <svg-btn v-if="$store.state.language == 'en'" style="width: 616px;height: 40px;" icon-class="downtit0-1" ></svg-btn>
            <svg-btn v-else-if="$store.state.language == 'hk'" style="width: 616px;height: 40px;" icon-class="downtit0-2" ></svg-btn>
            <svg-btn v-else style="width: 222px;height: 40px;" icon-class="downtit0-0" ></svg-btn>
            </div>
            <svg-btn class="icon-svg" icon-class="icon-more-fmp"></svg-btn>
          </div>
          <div class="moreList">
            <div
              class="item"
              :style="`backgroundImage:url(${$utils.getPng('web4/fmbg1')})`"
            >
              <div class="itemIntr">
                <svg-btn v-if="$store.state.language == 'en'" style="width: 448px;height: 132px;margin-left: -6px;" icon-class="downtit1-1" ></svg-btn>
                <svg-btn v-else-if="$store.state.language == 'hk'" style="width: 448px;height: 132px;margin-left: -6px;" icon-class="downtit1-2" ></svg-btn>
                <svg-btn v-else style="width: 436px;height: 132px;" icon-class="downtit1-0" ></svg-btn>
                <div class="intrTit2">
                  {{ $t('downPage.intrOne0') }}<br />
                  {{ $t('downPage.intrOne1') }}<br />
                  {{ $t('downPage.intrOne2') }}
                </div>

                <fm-button
                  iconLast="icon-down-jt"
                  round
                  size="big"
                  class="tyBtn"
                  :style="$store.state.language == 'en'?'width:200px':''" 
                  @click="toLink(0)"
                  >{{ $t('downPage.experienceNow') }}</fm-button
                >
              </div>
            </div>
            <div
              class="item item2"
              :style="`backgroundImage:url(${$utils.getPng($store.state.language == 'cn'?'web4/fmbg2':'web4/fmbg2-' + $store.state.language)})`"
            >
              <div class="itemIntr">
                <svg-btn v-if="$store.state.language == 'en'"  style="width: 436px;height: 132px;" icon-class="downtit2-1" ></svg-btn>
                <svg-btn v-else-if="$store.state.language == 'hk'"  style="width: 436px;height: 132px;" icon-class="downtit2-2" ></svg-btn>
                <svg-btn v-else style="width: 436px;height:88px;" icon-class="downtit2-0" ></svg-btn>
                <div class="intrTit2">
                  {{ $t('downPage.intrTwo0') }}<br />
                  {{ $t('downPage.intrTwo1') }}<br />
                  {{ $t('downPage.intrTwo2') }}
                </div>

                <fm-button
                  iconLast="icon-down-jt"
                  round
                  size="big"
                  class="tyBtn"
                  :style="$store.state.language == 'en'?'width:200px':''" 
                  @click="toLink(1)"
                  >{{ $t('downPage.experienceNow') }}</fm-button
                >
              </div>
            </div>
            <div
              class="item"
              :style="`backgroundImage:url(${$utils.getPng($store.state.language == 'cn'?'web4/fmbg3':'web4/fmbg3-' + $store.state.language)})`"
            >
              <div class="itemIntr">
                <svg-btn v-if="$store.state.language == 'en'" style="width: 400px;height: 88px;" icon-class="downtit3-1" ></svg-btn>
                <svg-btn v-else-if="$store.state.language == 'hk'" style="width: 400px;height: 88px;" icon-class="downtit3-2" ></svg-btn>
                <svg-btn v-else style="width: 400px;height: 88px;" icon-class="downtit3-0" ></svg-btn>
                <div class="intrTit2">
                  {{ $t('downPage.intrThree0') }}<br />
                  {{ $t('downPage.intrThree1') }}
                </div>

                <fm-button
                  iconLast="icon-down-jt"
                  round
                  size="big"
                  class="tyBtn"
                  :style="$store.state.language == 'en'?'width:200px':''" 
                  @click="toLink(2)"
                  >{{ $t('downPage.experienceNow') }}</fm-button
                >
              </div>
            </div>
            <div
              class="item item2"
              :style="`backgroundImage:url(${$utils.getPng('web4/fmbg4')})`"
            >
              <div class="itemIntr">
                <svg-btn v-if="$store.state.language == 'en'" style="width: 400px;height: 133px;" icon-class="downtit4-1" ></svg-btn>
                <svg-btn v-else-if="$store.state.language == 'hk'" style="width: 400px;height: 133px;" icon-class="downtit4-2" ></svg-btn>
                <svg-btn v-else style="width: 400px;height: 88px;" icon-class="downtit4-0" ></svg-btn>
                <div class="intrTit2">
                  {{ $t('downPage.intrFour0') }}<br />
                  {{ $t('downPage.intrFour1') }}
                </div>

                <fm-button
                  iconLast="icon-down-jt"
                  round
                  size="big"
                  class="tyBtn"
                  :style="$store.state.language == 'en'?'width:200px':''" 
                  @click="toLink(2)"
                  >{{ $t('downPage.experienceNow') }}</fm-button
                >
              </div>
            </div>
          </div>
          <div class="pageLast">
            <!-- <fm-button
              icon="icon-electron1"
              round
              size="big"
              class="electronBtn"
              @click="toApp"
              >安装下载飞猫盘客户端</fm-button
            > -->
            <div class="electronBtn"  @click="toApp">
             <svg-btn class="icon-svg" style="margin-right: 8px;width: 24px;height: 24px" icon-class="icon-electron1"></svg-btn>
             <span>{{ $t('downPage.installPC') }}</span>
            </div> 
           
            <div class="scrolTopBtn" :style="$store.state.language == 'en'?'width:300px':''" @click="scrollToTop">
              <div class="scrolTit">{{ $t('downPage.toTop') }}</div>
              <div class="circle">
                <svg-btn icon-class="icon-toTop" ></svg-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 取件码 -->
      <CheckPucode
        v-show="showCheckPucode == 2"
        @handlePucode="handlePucode"
        :pucode="pucode"
        :adData="adData"
        :auth_logo="auth_logo"
        :auth_name="auth_name"
      >
      </CheckPucode>
      <!-- 拦截弹框 -->
      <!-- type 1：会员  2：转存-->
      <PromoteDialog
        @handleClose="isShowPromote = false"
        @handleClose2="ShowPromoteFn"
        :isShow="isShowPromote"
        :msg="promoteMsg"
        :vip_update="vip_update"
        :icon="icon"
        :type="promoteType"
      >
      </PromoteDialog>
      <!-- type 1：会员  2：转存-->
      <PromoteDialog2
        @handleClose="isShowPromote2 = false"
        @handleClose2="handleClose2"
        :isShow="isShowPromote2"
        :msg="promoteMsg2"
        :type="promoteType2"
        icon="icon_diamond"
      >
      </PromoteDialog2>
      <!-- 消耗弹框 -->
      <!-- <ConsumeBox
        @confirm="confirmConsume"
        :showContent="showContent"
        :isShow="showConsumeBox"
        :consumeInfo="consumeInfo"
        :checkPopupType="checkPopupType"
        :popup_type="popup_type"
        :vipLimitInfo="vipLimitInfo"
        @handleClose="showConsumeBox = false"
      >
      </ConsumeBox> -->
      <ConsumeBox
        @confirm="confirmConsume"
        @routeMode="routeModeFn"
        :showContent="showContent"
        :isShow="showConsumeBox"
        :consumeInfo="consumeInfo"
        @handleClose="showConsumeBox = false"
      >
      </ConsumeBox>
      <!-- 转存成功 -->
      <SuccessBox
        :isShow="showSuccessBox"
        :successBoxMsg="successBoxMsg"
        ref="saveSuccDia"
        @handleClose="showSuccessBox = false"
      >
      </SuccessBox>
      <!-- 唤起客户端 -->
      <ClientDownloadBox :isShow="showClientDownloadBox"></ClientDownloadBox>
      <!-- 绑定手机1  实名认证2 验证码3-->
      <BindPhone
        :isShow="isShowBindPhone"
        :type="bindType"
        :time="time"
        @getPhoneCode="getPhoneCode"
        @bindPhone="bindPhone"
        @handleClose="isShowBindPhone = false"
      ></BindPhone>
      <!-- 客户端唤起 -->
      <Electronpop
        :code="$route.path.match(/s(\S*)/)[1].slice(1)"
        :isElectron="isElectron"
        @Visible="isElectron = false"
      ></Electronpop>
      
      <!-- 文件过大客户端唤起下载 -->
      <Electronpop2
        :code="$route.path.match(/s(\S*)/)[1].slice(1)"
        :isElectron="isElectron2"
        @Visible="isElectron2 = false"
      ></Electronpop2>

      <!-- 3元一天 -->
      <LimitedTimeVip
        ref="showLimitedTime"
        :isShowModel="showLimitedTime"
        :activity_vip_id="activity_vip_id"
        @handleClose="showLimitedTime = false"
        @openSuccess="openSuccess"
      ></LimitedTimeVip>

      <div
        v-if="pay_activities == 1 && !showLimitedTime && !showSuccessfullyModel"
        class="threeBtn"
        @click="threeBtnFn"
      >
        <img :src="$utils.getPng('threeBtn')" alt="" />
        <div class="threeyin"></div>
      </div>

      <!-- 支付弹框 -->
      <PayOrder
        ref="PayOrder"
        :isShow="isShowPayOrder"
        :payData="payData"
        :showBankInfo="showBankInfo"
        :allMember="allMember"
        :superSlideData="superSlideData"
        :upvipinfo="upvipinfo"
        :routeIds="$store.state.route_ids.join('-')"
        :voucher="fileInfo.voucher"
        @clearCoupon="clearCoupon"
        @handleClosePayDia="handleClosePayDia"
        @openSuccess="openSuccess"
      ></PayOrder>
      <!-- 优惠码 -->
      <DiscountCodeBox
        ref="DiscountCodeBox"
        :isShow="showDiscountCode"
        @handleClose="handleCloseDis"
        @checkCoupe="couponValidate"
      ></DiscountCodeBox>
      <!-- 补差价升级说明 -->
      <Upgradeexplain
        ref="Upgradeexplain"
        :isShow="showUpgradeexplain"
        :upvipinfo="upvipinfo"
        @handleClose="handleCloseUpgrade"
      ></Upgradeexplain>
      <SuccessfullyModel
        :payToast="payToast"
        :isShowModel="showSuccessfullyModel"
        @successRefresh="successRefresh"
      ></SuccessfullyModel>
      <Riskverification
        ref="Riskver"
        @handleClose="handleCloseRisk"
        @riskveAgain="riskveAgain"
      ></Riskverification>
      <MemberPopUpVue
        :isShow="memPopUpFlag"
        :downFileFlag="downFileFlag"
        :rebate_model_popup_img="rebate_model_popup_img"
        @handleClose="memPopUpFlag = false"
        @toTurnFile="toTurnFile"
        @todown="todown"
      ></MemberPopUpVue>
      <!-- 代金券弹窗 -->
      <CashCoupon 
        ref="popup" 
        :cashShow="cashShow" 
        :cashType="cashType" 
        :cashImg="cashImg"
        @handleClose="cashClose" 
        @confirm="cashConfirm"  />
      <!-- 引导登录弹窗 -->
      <LeadLoginBox
        :isShow="leadShow"
        @handleClose="leadShow = false"
      >
      </LeadLoginBox>
      <!-- 取消收藏提示框 -->
      <TitleDialog
        ref="TitleDialogRef"
        :is-show="cloCollectShow"
        :title="collectObj.title"
        :text="collectObj.text"
        @handleClose="onCollectClose"
        @handleConfirm="onCollectConfirm"
      >
      </TitleDialog>
      <!-- 举报 -->
      <reportDialog
        ref="reportRef"
        :isShow="showReport"
        :fshort="fileInfo.fshort"
        @handleClose="showReport = false"
      ></reportDialog>
      <!-- 自定义右键菜单 -->
      <CustomContextMenu
        ref="customMenu"
        :visible="showContextMenu"
        :x="menuPosition.x"
        :y="menuPosition.y"
        :selected-item="selectedItem"
        @action="handleMenuAction"
        @close="closeMenu"
      />
    </div>
    <div class="toTop-div" v-show="showTop" @click="scrollToTop">
      <!-- <img :src="$utils.getPng('web4/gotop')" alt="" /> -->
      <svg-btn icon-class="icon-toTop" style="width: 24px;height: 24px;"></svg-btn>
    </div>
  </div>
</template>

<script>
import CashCoupon from "@/components/CashCoupon/index.vue";
import VipBenefits from "@/components/VipBenefits/index";
import DownloadBox from "@/components/DownloadBox/index";
import PromoteDialog from "@/components/Dialog/PromoteDialog";
import PromoteDialog2 from "@/components/Dialog/PromoteDialog2";
import ConsumeBox from "@/components/DownPage/ConsumeBox";
import SuccessBox from "@/components/DownPage/SuccessBox";
import ClientDownloadBox from "@/components/DownPage/ClientDownloadBox";
import CheckPucode from "@/components/DownPage/CheckPucode";
import OtherStatus from "@/components/DownPage/OtherStatus";
import BindPhone from "@/components/Cloud/BindPhone";
import Electronpop from "@/components/DownPage/Electronpop";
import Electronpop2 from "@/components/DownPage/Electronpop2";
import PayOrder from "@/components/PayOrder/index";
import DiscountCodeBox from "@/components/Member/DiscountCodeBox.vue";
import SuccessfullyModel from "@/components/Member/SuccessfullyModel.vue";
import Upgradeexplain from "@/components/Member/Upgradeexplain.vue";
import LimitedTimeVip from "@/components/DownPage/LimitedTimeVip";
import VIPPAYMIXIN from "@/mixin/vip.vue";
import {
  checkFilePucode,
  getDownFileInfo,
  getNewSecurl,
  activePopupStatAPI,
  turnSaveFile,
  getCashList,
  checkCashList,
  addCollect,
  delCollect,
} from "@/utils/apiList/downpage";
import { bindPhoneSendCode, bindPhoneAPI } from "@/utils/apiList/account";
import { downloadFile } from "@/utils/apiList/cloud";
import Riskverification from "@/components/Dialog/Riskverification.vue";
import MemberPopUpVue from "./memberPopUp.vue";
import FileTable from "@/components/FileTable/index.vue";
import TitleDialog from "@/components/Dialog/TitleDialog.vue";
import LeadLoginBox from "@/components/DownPage/LeadLoginBox";
import reportDialog from "@/components/Dialog/reportDialog";
import CustomContextMenu from "@/components/CustomContextMenu/index.vue";
export default {
  components: {
    VipBenefits,
    DownloadBox,
    PromoteDialog,
    PromoteDialog2,
    ConsumeBox,
    SuccessBox,
    ClientDownloadBox,
    CheckPucode,
    OtherStatus,
    BindPhone,
    Electronpop,
    Electronpop2,
    PayOrder,
    LimitedTimeVip,
    DiscountCodeBox,
    SuccessfullyModel,
    Riskverification,
    Upgradeexplain,
    MemberPopUpVue,
    FileTable,
    CashCoupon,
    TitleDialog,
    LeadLoginBox,
    reportDialog,
    CustomContextMenu,
  },
  mixins: [VIPPAYMIXIN],
  data() {
    return {
      skeletonItem: [1, 2, 3],
      getFileInfoGlag: true,
      isShowPromote: false,
      isShowPromote2: false,
      icon: "",
      showUpgradeBox: false,
      showConsumeBox: false,
      showSuccessBox: false,
      showClientDownloadBox: false,
      adData: {},
      auth_logo: "",
      auth_name: "",
      pucode: "", //取件码
      code: "", //文件链接码
      fileInfo: {}, //文件信息
      promoteMsg: "",
      promoteMsg: "",
      promoteType2: 2,
      promoteType: 2, //2会员 3转存
      showCheckPucode: -1, //1文件信息 2取件码
      consumeInfo: {
        title: "",
        content: "",
        vip_update: 1, //是否可以升级会员1是0否
      }, //消耗信息
      checkPopupType: 1, //1会员 2 转存
      popup_type: "",
      showContent: 2,
      vipLimitInfo: {},
      fileStatus: 1, //2删除、锁定、不存在 3私有
      isShowBindPhone: false, //
      bindType: 1, //
      timerA: null,
      time: 60,
      msgid: "",
      vip_update: "",
      successBoxMsg: "",
      cLoading: false,
      sc: "", //屏幕点击位置
      ms: "", //屏幕点击位置
      isElectron: false,
      isElectron2: false,
      showLimitedTime: false,
      prompt_activities: 0, //三元一天支付弹窗
      activity_vip_id: "1055", //三元一天会员Id
      pay_activities: 0, //三元一天右下角图片点击出现三元一天支付弹窗的按钮
      phone_item: {
        phone: "",
        pcode: "",
      },
      savefileParams: {},
      showRisk: -1, //0 是转存，1下载
      accessid: "",
      statusNum: "",
      showTop: false,
      rebate_model_ads: [],
      rebate_model_popup_img: "",
      memPopUpFlag: false,
      downFileFlag: 0,
      downType: 0,
      downItem: {},
      skeletonLoading: false,
      networkType: '4g',
      resCaptcha_verify:'',
      // 代金券弹窗
      cashShow:false,
      cashImg:'',
      cashList:'',
      cashType:'',
      receive:1,
      // 代金券弹窗
      authCode:'',
      collectShow:0, // 判断收藏图标状态
      cloCollectShow:false, //取消收藏弹窗是否显示
      collectObj: { //取消收藏弹窗文字内容
        title: "提示",
        text: "确定取消收藏该文件吗？",
      },
      leadShow:false,
      showReport:false,
      showContextMenu: false,
      menuPosition: { x: 0, y: 0 },
      selectedItem: [
        {
          id:2,
          name:'cloud.Refresh'
        },
      ]
    };
  },
  watch: {
    showConsumeBox(val) {
      //关闭弹窗清空accessid ，有福利点消耗弹窗 先出现风险验证弹窗在出现福利点消耗 出现福利点再次去请求接口的时候需要保留accessid 所以在福利点消耗弹窗关闭时清空accessid 再次出现风险验证
      if (!val) {
        this.accessid = "";
      }
    },
    isShowPayOrder(val) {
      // 是私有文件和被删除的文件，打开收银台的时候要埋来路模式id
      if ((val && this.statusNum == "60001") || this.statusNum == "60004") {
        this.$utils.routeModeReported("C2", this.fileInfo.voucher);
      }
    },
  },
  computed: {
    isCollect: function () {
      return Number(this.fileInfo.is_collect) === 1;
    },
  },
  created() {
    document.addEventListener("visibilitychange", this.handleVisiable);

    this.getFileInfoGlag = true;
    this.code = this.$route.params.id;
    if (sessionStorage.getItem("isFromShareList") == "1") {
      sessionStorage.removeItem("isFromShareList");
      this.handlePucode(this.$route.query.pucode);
    } else {
      this.checkPucode();
    }
    this.networkType = this.$utils.getConnection()?.effectiveType || '4g';
    if(this.$route.query?.authCode){
      this.authCode = this.$route.query?.authCode
    }
  },
  destroyed() {
    document.removeEventListener("visibilitychange", this.handleVisiable);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // if (from.name == "fmDown") {
      // }else{
      //    this.pucode = this.$route.query.pucode
      //    this.getDownFileInfo()
      // }
    });
  },
  methods: {
    showMenu(event){
      event.preventDefault(); // 阻止默认行为
      event.stopPropagation(); // 阻止事件冒泡
      
      const forbiddenSelectors = [
        '.file-item-hover',
        '.ant-dropdown',
        '.ant-dropdown-menu',
        '.ant-menu'
      ];
      const isForbidden = event.target.closest(forbiddenSelectors.join(','));
      if (isForbidden) {
        this.closeMenu();
        return;
      }
      // 获取菜单的实际尺寸
      const menuSize = this.$refs.customMenu.getMenuSize();
      const menuWidth = menuSize.width;
      const menuHeight = menuSize.height;

      // 获取视口尺寸
      const fileListElement = document.querySelector('.fileInfoBox');
      if (!fileListElement) return;
      
      const boxRect = fileListElement.getBoundingClientRect();
      const boxWidth = boxRect.width;
      const boxHeight = boxRect.height;

      const mouseX = event.clientX - boxRect.left; // 鼠标在盒子内的X坐标
      const mouseY = event.clientY - boxRect.top;  // 鼠标在盒子内的Y坐标

      // 动态调整菜单位置
      let x = mouseX;
      let y = mouseY - 50;
      // 判断是否靠近窗口右侧
      if (x + menuWidth > boxWidth) {
        x = mouseX - menuWidth;
      }
      // 判断是否靠近窗口底部
      if (y + menuHeight > boxHeight - 100) {
        y = mouseY - 100
      }
      // 判断是否靠近窗口顶部
      if (y < 0) {
        y = mouseY - 50;
      }
      // 设置菜单位置
      this.menuPosition = {
        x: x + boxRect.left, // 转回视口坐标
        y: y + boxRect.top   // 转回视口坐标
      };

      // 显示菜单
      this.showContextMenu = true;
    },
    closeMenu() {
      this.showContextMenu = false
    },
    handleMenuAction(action) {
      switch(action) {
        case 2:
          this.getDownFileInfo('pull')
          break
      }
    },
     //点击收藏图标
     collect: _.debounce(
        function () {
          if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
          this.goLogin();
          return;
        }
        if(this.collectShow == 0){
           //如果此时是关闭状态
           addCollect({
            file_id:this.fileInfo.id,
          }).then(res =>{
            if(res.status == 1){
              this.collectShow = 1
              this.$refs.fileTable.collectShow = 1
              this.$toast("收藏文件成功", 1);
              // 为了体验，不及时更新页面
              // this.getDownFileInfo('pull')
            }else if(res.status == 10020 || res.status == 5000){
            }else{
              this.$toast(res.msg, 2);
            }
          })
        }else{
          //如果此时是打开状态
          this.collectObj.text = '确定取消收藏该文件吗？'
          this.cloCollectShow = true;
        }
      },
      500,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //确定取消收藏弹窗
    onCollectConfirm(){
      delCollect({
        file_id:this.fileInfo.id,
      }).then(res =>{
        if(res.status == 1){
          this.collectShow = 0
          this.$refs.fileTable.collectShow = 0
          this.onCollectClose()
          // 为了体验，不及时更新页面
          // this.getDownFileInfo('pull')
        }else{
          this.$toast(res.msg, 2);
        }
      })
    },
    //关闭取消收藏弹窗
    onCollectClose() {
      this.cloCollectShow = false;
    },
    // 获取代金券信息
    getCashInfo() {
      getCashList({down_page:2}).then(res =>{
        if(res.status == 1){
          if(res.data.type != 0){
            this.cashImg = res.data.popup_img
            this.cashList = res.data.coupons.map(item => item.id).join(',');
            this.cashType = res.data.type
            this.receive = res.data.receive
            this.cashShow = true
          }
        }
      })
    },
    //点击立即收下代金券
    cashConfirm(){
      if(this.receive == 0){
        this.cashShow = false
        setTimeout(() => {
            window.open("/vip", "_blank");
          }, 500); 
        return
      }
      checkCashList({ids:this.cashList}).then(res =>{
        if(res.status == 1){
          this.cashShow = false
          setTimeout(() => {
            window.open("/vip", "_blank");
          }, 500); 
          this.getDownFileInfo('pull')
        }
      })
    },
    //关闭弹窗
    cashClose(){
      if(this.receive == 0){
        this.cashShow = false
        return
      }
      checkCashList({ids:this.cashList}).then(res =>{
        if(res.status == 1){
          this.cashShow = false
          this.getDownFileInfo('pull')
        }
      })
    },
    handleVisiable(e) {
      switch (e.target.visibilityState) {
        case "visible":
          this.$store.commit("setAttr", {
            name: "route_ids",
            val: ["A1"],
          });
          if (this.statusNum == "60001" || this.statusNum == "60004") {
            this.$utils.routeModeReported("B33", this.fileInfo.voucher);
          } else {
            this.$utils.routeModeReported("B33", this.fileInfo.voucher);
          }

          break;
        case "hidden":
          break;
      }
    },
    handleClose2(type) {
      if (type == 2) {
        this.$utils.routeModeReported("C32", this.fileInfo.voucher);
      }
    },
    routeModeFn() {
      if (this.showContent == 1) {
        this.$utils.routeModeReported("C4", this.fileInfo.voucher);
      } else {
        this.$utils.routeModeReported("C5", this.fileInfo.voucher);
      }
    },
    ShowPromoteFn() {
      if (this.promoteType == 3) {
        this.$utils.routeModeReported("C6", this.fileInfo.voucher);
      } else {
        this.$utils.routeModeReported("C3", this.fileInfo.voucher);
      }
      this.isShowPromote = false;
    },
    async hCollect() {
      if (this.cLoading) return;
      this.cLoading = true;
      let act, id;
      if (this.isCollect) {
        act = "0";
        id = this.fileInfo.collect_id;
      } else {
        act = "1";
        id = this.fileInfo.id;
      }
      let res = await handleCollect({
        action: act,
        type: "file",
        id,
      });
      if (res.state) {
        this.fileInfo.is_collect = act;
      } else if (res.msg) {
        this.$toast(res.msg, 2);
      }
      this.cLoading = false;
    },
    //检测取件码
    checkPucode() {
      checkFilePucode({ code: this.code }).then((res) => {
        if (res.status == "1") {
          if (res.data.if_check_pucode == "1") {
            this.showCheckPucode = 2;
            this.adData = res.data.ad_list[0];
            this.auth_name = res.data.auth_name;
            this.auth_logo = res.data.auth_logo;
            //是否自动填充
            if (this.$route.query.pucode) {
              this.pucode = this.$route.query.pucode;
            }
          } else {
            this.showCheckPucode = 1;
            this.getDownFileInfo();
          }
        } else {
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 2;
          this.$toast(res.msg, 2);
        }
      });
    },
    handlePucode(pucode) {
      this.pucode = pucode;
      this.getDownFileInfo();
    },
    //获取文件信息
    getDownFileInfo(row) {
      this.getFileInfoGlag = true;
      this.skeletonLoading = true;
      this.showLimitedTimeFun(false);
      getDownFileInfo({ code: this.code, pucode: this.pucode }).then((res) => {
        this.statusNum = res.status || "";
        this.$store.commit("setAttr", {
          name: "statusNum",
          val: res.status || "",
        });
        this.skeletonLoading = false;
        if (res.status == "1") {
          if(!this.authCode && !row){
            //获取代金券信息
            this.getCashInfo()
          }
          //如果没登录,则提示引导登录弹窗
          if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
              this.leadShow = true
          }
          this.$utils.routeModeReported("B33", res.data.voucher);
          this.showCheckPucode = 1;
          this.fileInfo = res.data;
          this.collectShow = res.data.is_collect
          this.$refs.fileTable.collectShow = res.data.is_collect
          this.prompt_activities = res.data.prompt_activities;
          this.activity_vip_id = res.data.activity_vip_id;
          this.pay_activities = res.data.pay_activities;
          this.getFileInfoGlag = false;
          this.rebate_model_popup_img = res.data.rebate_model_popup_img;
          this.rebate_model_ads = res.data.rebate_model_ads;
          this.$refs.fileTable.getTableList(res.data.detail_list);
          if (this.prompt_activities == 1) {
            this.showLimitedTimeFun(true);
            activePopupStatAPI({})
              .then((result) => {})
              .catch((err) => {}); //该接口只有在进下载a页面才需要给后端计数
          }
        } else if (res.status == "61001") {
          this.$toast(res.msg, 2);
        } else if (res.status == "60001") {
          this.$utils.routeModeReported("B33", this.fileInfo.voucher);
          //文件不存在或者被删除
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 2;
        } else if (res.status == "50006") {
          //锁定
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 2;
        } else if (res.status == "60004") {
          this.$utils.routeModeReported("B33", this.fileInfo.voucher);
          //私有文件拒绝访问
          this.showCheckPucode = 1;
          this.getFileInfoGlag = false;
          this.fileStatus = 3;
        } else {
          this.showCheckPucode = 1;
          this.$toast(res.msg, 2);
        }
      });
    },
    goReport() {
      // window.open("https://jinshuju.net/f/fKYT3q");
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      if (this.$store.state.userInfo.phone == "") {
        this.bindType = 1;
        this.isShowBindPhone = true;
        return;
      }
      this.showReport = true
    },
    //普通下载 跳转去下载b
    goDownPageb() {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      if (!this.fileInfo.has_down) {
        this.$toast("尊贵的会员，请点击“网页端 极速下载”进行高速下载", 4);
        return;
      }
      getNewSecurl({ code: this.code }).then((res) => {
        if (res.status == "1") {
          this.$router.push({
            path: "/fmDown/" + res.data.url,
          });
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //去下载
    goVipDownload(e, accessid = "") {
      this.checkPopupType = 1;
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      this.accessid = accessid;
      if (e) {
        e = e || window.event;
        this.ms = e.clientX + "*" + e.clientY;
        this.sc = window.screen.width + "*" + window.screen.height;
      }
      this.svipDown();
    },
    //会员下载
    svipDown: _.debounce(
      function (captcha_verify,confirm = 0) {
        return new Promise((resolve) => {
          let params = {
            type: 3, // 1:列表下载，2:福利点下载，3:vip下载，4：重试
            code: this.code,
            confirm: confirm,
            ms: this.ms,
            sc: this.sc,
            captcha_verify: captcha_verify || this.resCaptcha_verify || "",
            accessid: this.accessid,
            rebate_model_voucher: this.fileInfo.rebate_model_voucher,
            network: this.networkType,
          };
          downloadFile(params).then((res) => {
            this.resCaptcha_verify = '';
            this.accessid = '';
            if (res.data.accessid) { 
              this.accessid = res.data.accessid
            }
            if (res.data.captcha_verify) { 
              this.resCaptcha_verify = res.data.captcha_verify
            }
            if (res.status == "1") {
              if (this.fileInfo.is_record_error_msg == "1") {
                //是否录制视频上报
                this.$store.commit("onStartRecord");
              }
              resolve({
                result: true,
                validate: true,
              });
              let fileName = `${res.data.file.name}${res.data.file.extension}`;
              let obj = {
                fid: res.data.file.id,
                url: res.data.url,
                fileName: fileName,
                fileSize: res.data.file.size, //文件大小
                fileType: res.data.file.extension,
                ext_icon: res.data.file.ext_icon,
                title: this.$t('downPage.memberDownTitStr'),
                down_id: res.data.down_id, //下载id，重试时需要用到
                down_label: res.data.down_label,
                is_vip_down:res.data.is_vip_down,
                down_token: res.data.down_token,
                is_remote_record: res.data.is_remote_record || '',
              };
              this.$store.commit("setAttr", { name: "downloadFile", val: obj });
              this.$router.push({
                path: "/tNewDown/" + res.data.jump_url,
              });
            } else if (res.status == "51001") {
              resolve({
                result: true,
                validate: true,
              });
              //额度用尽
              this.showContent = 1;
              this.consumeInfo = res.data;
              this.showConsumeBox = true;
            } else if (res.status == "42003") {
              //三元一天弹
              resolve({
                result: true,
                validate: true,
              });
              this.showLimitedTimeFun(true);
              this.$toast(res.msg, 4);
            } else if (res.status == "63100") {
              resolve({
                result: true,
                validate: true,
              });
              this.promoteMsg = res.msg;
              this.promoteType = 2;
              this.vip_update = "1";
              this.icon = "icon_diamond";
              this.isShowPromote = true;
            } else if (res.status == "90001") {
              resolve({
                result: false,
                validate: false,
              });
              this.$toast(res.msg, 2);
              this.$AliCode.handleOpen(this.svipDown);
            } else if (res.status == "90101") {
              resolve({
                result: true,
                validate: true,
              });
              this.showRisk = 1;
              // this.$initRiskve.open();
              this.$refs.Riskver.open();
            } else if (res.status == "68001" || res.status == "68002") {
              resolve({
                result: true,
                validate: true,
              });
              this.showContent = 3;
              this.consumeInfo = res.data;
              this.showConsumeBox = true;
            } else if (res.status == "83001") {
              window.location.reload();
            } else if (res.status == "60020") {
              this.electronFn2()
            } else {
              //60001无法下载，文件不存在或已被删除 //50006文件已被锁定,暂时无法访问
              resolve({
                result: true,
                validate: false,
              });
              this.$toast(res.msg, 2);
            }
          });
        });
      },
      1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    //去转存
    goRestore(e) {
      this.checkPopupType = 2;
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }
      if (e) {
        e = e || window.event;
        this.ms = e.clientX + "*" + e.clientY;
        this.sc = window.screen.width + "*" + window.screen.height;
      }
      this.savefile(0);
    },
    //文件转存
    savefile:_.debounce(
      function (confirm, accessid = "") {
      this.savefileParams = confirm;

      this.accessid = accessid;

      this.savefileALY();
    },
      1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),
    savefileALY(captcha_verify) {
      return new Promise((resolve) => {
        let confirm = this.savefileParams;
        let params = {
          code: this.code,
          confirm: confirm,
          captcha_verify: captcha_verify || this.resCaptcha_verify || "",
          accessid: this.accessid,
          ms: this.ms,
          sc: this.sc,
          rebate_model_voucher: this.fileInfo.rebate_model_voucher,
          // folder_id:0 //扩展参数允许转存的目录，目前不用传
        };
        turnSaveFile(params).then((res) => {
          this.resCaptcha_verify = '';
            this.accessid = '';
            if (res.data.accessid) { 
              this.accessid = res.data.accessid
            }
          if (res.data.captcha_verify) { 
            this.resCaptcha_verify = res.data.captcha_verify
          }
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.showConsumeBox = false;
            this.showSuccessBox = true;
            this.$refs.saveSuccDia.saveFileFolderId = res.data.folder_id;
            this.$refs.saveSuccDia.saveFileFolderName = res.data.folder_name;
            this.successBoxMsg = "转存成功";
            this.memPopUpFlag = false;
            if (this.downType == 1) {
              this.todown(res.data.code, true);
            }
            // this.getDownFileInfo();
          } else if (res.status == "68001" || res.status == "68002") {
            resolve({
              result: true,
              validate: true,
            });
            this.showContent = 3;
            this.consumeInfo = res.data;
            this.showConsumeBox = true;
          } else if (res.status == "81001") {
            //福利点不足
            resolve({
              result: true,
              validate: true,
            });
            this.showContent = 2;
            this.consumeInfo = res.data;
            this.showConsumeBox = true;
          } else if (res.status == "40001") {
            //未绑定手机号
            resolve({
              result: true,
              validate: true,
            });
            this.bindType = 1;
            this.isShowBindPhone = true;
          } else if (res.status == "52001") {
            //空间已满
            resolve({
              result: true,
              validate: true,
            });
            this.icon = "icon_space";
            this.promoteMsg = res.data.content;
            this.vip_update = res.data.vip_update;
            this.promoteType = 3;
            this.isShowPromote = true;
          } else if (res.status == "62001") {
            //已转存过 (62001)
            resolve({
              result: true,
              validate: true,
            });
            this.showSuccessBox = true;
            this.successBoxMsg = res.msg;
          } else if (res.status == "42003") {
            //一元三天
            resolve({
              result: true,
              validate: true,
            });
            this.showLimitedTimeFun(true);
            this.$toast(res.msg, 4);
          } else if (res.status == "90001") {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.savefileALY);
          } else if (res.status == "90101") {
            resolve({
              result: true,
              validate: true,
            });
            // this.$initRiskve.open();
            this.showRisk = 0;
            this.$refs.Riskver.open();
          } else if (res.status == "60013") {
            this.promoteMsg2 = "开通会员立即转存文件";
            this.promoteType2 = 2;
            this.isShowPromote2 = true;
          } else if (res.status == "83001") {
            window.location.reload();
          } else {
            resolve({
              result: true,
              validate: false,
            });
            //60013 文件已过期" 50006 锁定
            this.$toast(res.msg, 2);
          }
        });
      });
    },

    //确认消耗
    confirmConsume() {
      this.checkPopupType == 1
        ? this.svipDown('',1, this.accessid)
        : this.savefile(1, this.accessid);
    },
    //发送验证码
    getPhoneAly(captcha_verify) {
      return new Promise((resolve) => {
        bindPhoneSendCode({
          phone: this.phone_item.phone,
          pcode: this.phone_item.pcode,
          captcha_verify: captcha_verify || "",
        }).then((res) => {
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 1);
            this.msgid = res.data.msgid;
            this.bindType = 3;
            this.timerA = setInterval(() => {
              this.minute();
              this.$refs.bindPhoneCom?.onFocus();
            }, 1000);
          } else if (res.status == "90001") {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.$AliCode.handleOpen(this.getPhoneAly);
          } else {
            resolve({
              result: true,
              validate: true,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    getPhoneCode(phone, pcode) {
      this.phone_item = {
        phone,
        pcode,
      };
      // var reg_tel =
      //   /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      // if (!reg_tel.test(phone)) {
      //   this.$toast("请正确输入手机号", 3);
      //   return true;
      // }

      this.getPhoneAly();
    },

    minute() {
      this.time = this.time - 1;
      if (this.time == 0) {
        this.time = 60;
        clearInterval(this.timerA);
      }
    },
    bindPhone(data) {
      let params = { msgid: this.msgid, ...data };
      bindPhoneAPI(params).then((res) => {
        if (res.status == "1") {
          this.isShowBindPhone = false;
          this.$toast(res.msg, 1);
          this.$store.commit("getuserVuex");
          if (this.$store.state.bindPhoneCallback) {
            this.$store.state.bindPhoneCallback()
          }
        } else {
          this.$toast(res.msg, 2);
        }
      });
    },
    //去登录页
    goLogin() {
      let path = this.$route.fullPath.toString().slice(1);
      this.$router.push({ path: "/login", query: { path: path } });
      return;
    },
    electronFn() {
      this.isElectron = true;
    },
    electronFn2() {
      this.isElectron2 = true;
    },
    refreshPage() {
      this.$store.commit("getuserVuex");
      this.getDownFileInfo();
    },
    showLimitedTimeFun(newval) {
      this.showLimitedTime = newval;
    },
    gopageA() {},
    threeBtnFn() {
      this.showLimitedTimeFun(true);
    },
    riskveAgain(accessid) {
      if (this.showRisk == 1) {
        this.downItem.accessid = accessid;
        this.todown("", false);
      } else {
        this.savefile(this.savefileParams, accessid);
      }
    },
    handleCloseRisk() {
      this.showConsumeBox = false;
    },
    openVip() {
      if (this.$refs.VipBenefits?.memberIntr.slice(1)[0]) {
        this.showPayDiaFun(this.$refs.VipBenefits.memberIntr.slice(1)[0], 0);
      }
    },
    scrollBoxFun: _.debounce(
      function (e) {
        {
          let scrollTop = e.target.scrollTop;
          this.showTop = scrollTop >= 50;
          this.$nextTick(() => {
            this.closeMenu()
          })
        }
      },
      50,
      {
        leading: true, //指定在延迟开始前调用
        trailing: true, //指定在延迟结束后调用
      }
    ),
    scrollToTop() {
      let scrollEl = this.$refs.scrollBox;
      scrollEl.scrollTop = 0;
    },
    toApp() {
      // this.$router.push({ name: "app" });
      const routeUrl = this.$router.resolve({
        path: "/app",
      });
      window.open(routeUrl.href, "_blank");
    },
    toAdLink(item) {
      this.$utils.routeModeReported("C33", this.fileInfo.voucher);
      window.open(item.link);
    },
    openMemPopUp(index) {
      if (this.$utils.getCookie(this.$glb.fmCookieName) == null) {
        this.goLogin();
        return;
      }

      if (!this.$refs.fileTable.allCheck) {
        this.$toast("请先选择文件", 3);
        return;
      }
      this.memPopUpFlag = true;
      this.downFileFlag = index;
    },
    toTurnFile(flag) {
      var e = window.event;

      this.downType = flag; // 调起右下角的列表下载 为0 时是去c页面下载
      this.goRestore(e);
    },
    todown(code = "", isRestore = false) {
      var e = window.event;
      if (!isRestore) {
        if (this.$store.state.userInfo.is_vip == 1) {
          this.downType = 3;
        } else {
          this.downType = 2;
        }
      } else {
        this.downType = 1;
      }
      let downInfo = this.fileInfo;
      if (code) {
        downInfo.fcode = code;
      }
      this.goDownload(e, downInfo);
    },
    //列表下载
    goDownloadAlagain(captcha_verify) {
      return new Promise((resolve) => {
        let params = {
          type: this.downType,
          code: this.downItem.fcode || this.downItem.fshort,
          captcha_verify: captcha_verify || "",
          accessid: this.downItem.accessid,
          ms: this.ms,
          sc: this.sc,
          rebate_model_voucher: this.fileInfo.rebate_model_voucher,
        };
        let item = this.downItem;
        downloadFile(params).then((res) => {
          this.downItem.fcode = ''
          if (res.status == "1") {
            resolve({
              result: true,
              validate: true,
            });
            this.memPopUpFlag = false;
            // this.showSuccessBox = false;
            let fileName = `${item.name}${item.extension}`;
            this.$store.commit("getDownloadFile", {
              name: "query",
              value: {
                fid: item.id,
                url: res.data.url,
                down_token: res.data.down_token,
                is_remote_record: res.data.is_remote_record || '',
                fileName: fileName,
                fileSize: item.size, //文件大小
                fileType: item.extension,
                down_id: res.data.down_id,
                title:
                  this.$store.state.userInfo.is_vip == "1"
                    ? "会员极速下载通道"
                    : "普通下载通道",
                isClickFlag: res.data.is_up,
                down_label: res.data.down_label,
                is_vip_down:res.data.is_vip_down,
              },
            });
            this.$store.commit("setAttr", {
              name: "showUpDownBox",
              val: 1,
            });
            this.$store.commit("setAttr", {
              name: "navAct",
              val: 1,
            });
          } else if (res.status == "40001") {
            //未绑定手机号
            this.bindType = 1;
            this.isShowBindPhone = true;
          } else if (res.status == "50006") {
            //被锁定
            this.$toast(res.msg, 2);
          } else if (res.status == 90001) {
            resolve({
              result: false,
              validate: false,
            });
            this.$toast(res.msg, 2);
            this.downItem.fcode = res.data.code
            this.$AliCode.handleOpen(this.goDownloadAlagain);
          } else if (res.status == "90101") {
            this.showRisk = 1;
            this.$refs.Riskver.open();
          } else if (res.status == "83001") {
              window.location.reload();
          } else if (res.status == "60020") {
            this.electronFn2()
          } else {
            resolve({
              result: true,
              validate: false,
            });
            this.$toast(res.msg, 2);
          }
        });
      });
    },
    goDownload(e, item, accessid = "") {
      e = e || window.event;
      if(e.clientX && e.clientY ){
        this.ms = e.clientX + "*" + e.clientY;
      }
      this.sc = window.screen.width + "*" + window.screen.height;
      this.downItem = item;
      if (accessid) {
        this.downItem = { ...this.downItem, accessid };
      }
      this.goDownloadAlagain();
    },
    toLink(index) {
      if (index == 0) {
        window.open(`${this.$glb.goLLZ}`, "_blank");
      } else if (index == 1) {
        const routeUrl = this.$router.resolve({
          path: "/vip",
        });
        window.open(routeUrl.href, "_blank");
      } else if (index == 2) {
        const routeUrl = this.$router.resolve({
          path: "/app",
        });
        window.open(routeUrl.href, "_blank");
      }
    },
    selectItems() {
      this.$refs.FileTable?.selectItems();
    },
  },
};
</script>

<style lang="scss">
@import "../index.scss";
</style>
